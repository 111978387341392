import { Component, OnInit, ViewChild, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from 'src/app/utils/services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Event } from 'src/app/models/event';
import * as moment from 'moment';
import { Place } from 'src/app/models/place';
import { AppConstants } from 'src/app/utils/constants';
import { Manager } from 'src/app/models/manager';
import { AuthenticationService } from 'src/app/utils/services/authentication.service';
import { Admin } from 'src/app/models/admin';
import { forkJoin, Subject } from 'rxjs';
import { User } from 'src/app/models/user';
import { DataTableDirective } from 'angular-datatables';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { Admonition } from 'src/app/models/admonition';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAdmonitionComponent } from 'src/app/modals/modal-admonition/modal-admonition.component';
import { ModalTalentStarsComponent } from 'src/app/modals/modal-talent-stars/modal-talent-stars.component';
declare var $: any;

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css']
})
export class EventDetailComponent implements OnDestroy, OnInit {
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;

  id: number;
  admin: Admin;
  isAdmin: boolean = false; // role_id 0, 1

  isLoading: boolean = true;
  isLoadingUsers: boolean = true;
  isLoadingTalents: boolean = true;
  isLoadingTalentsModal: boolean = true;

  form: FormGroup; formTalentsAvailable: FormGroup; formTalentsRate: FormGroup;
  submitted = false; submittedInvite = false;
  loading = false; loadingRate = false; sendingInvite = false;
  hidden_sclak = true;

  event: Event;
  places: Place[] = [];
  managers: Manager[] = [];
  types: Array<{ type_id: number, type_name: string }>;
  users: User[] = [];
  talents: User[] = [];
  talentsAvailable: User[] = [];

  dtOptionsUsers: DataTables.Settings = {};
  dtTriggerUsers: Subject<any> = new Subject();
  dtOptionsTalents: DataTables.Settings = {};
  dtTriggerTalents: Subject<any> = new Subject();
  dtOptionsFeedbacks: DataTables.Settings = {};
  dtTriggerFeedbacks: Subject<any> = new Subject();
  isUpdate: boolean;

  ratingTechnical: number;
  ratingPopular: number;

  eventExpired: boolean = false;

  fileCover: File = null;
  deleteCover: Boolean = false;
  selectedOrg: User;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private rest: RestService,
    private toastr: ToastrService,
    private appConstants: AppConstants,
    private auth: AuthenticationService,
    private modalService: NgbModal
  ) {

    this.id = this.route.snapshot.params['id'];
    this.admin = auth.currentUserValue;

    this.isAdmin = (this.admin.role_id != 2);
  }

  get selectedOrgMod() {
    return this.selectedOrg;
  }
  set selectedOrgMod(value) {
    this.selectedOrg = value;
  }

  ngOnInit(): void {
    $('.slider').bootstrapSlider()

    this.dtOptionsUsers = {
      columnDefs: [{ orderable: false, targets: [4] }],
      order: [0, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      },
      destroy: true,
      // responsive: true
    };
    this.dtOptionsTalents = {
      columnDefs: [
        { type: "date-euro", targets: 0 },
        { orderable: false, targets: [4, 5, 6, 7, 8] }],
      order: [0, "desc"],
      language: {
        url: "./assets/datatable_ITA.json"
      },
      destroy: true,
      // responsive: true
    };
    this.dtOptionsFeedbacks = {
      columnDefs: [
        { type: "date-euro", targets: 0 },
        { orderable: false, targets: [1, 2, 3, 4, 5] }
      ],
      order: [0, "desc"],
      language: {
        url: "./assets/datatable_ITA.json"
      },
      destroy: true,
    };

    this.form = new FormGroup({
      enabled: new FormControl(this.id), //di default è false se sto creando l'evento
      event_date: new FormControl(null, Validators.required),
      end_date: new FormControl(null, Validators.required),
      place_id: new FormControl(null, Validators.required),
      type_id: new FormControl(null, Validators.required),
      title: new FormControl(null, Validators.required),
      description: new FormControl(null),
      manager_id: new FormControl(this.admin.role_id == 0 ? null : this.admin.manager_id),
      manager_phone: new FormControl(null),
      max_users: new FormControl(0, Validators.required),
      max_talents: new FormControl(0, Validators.required),
      min_score: new FormControl(0, Validators.required),
      points: new FormControl(0, Validators.required),
      sclak_event: new FormControl(false),
      cover_url: new FormControl(null),
      tint_color: new FormControl(null, CustomValidators.exadecimalColor())
    });

    this.formTalentsAvailable = new FormGroup({
      talentsAvailable: new FormControl(null, Validators.required)
    })
    this.formTalentsRate = new FormGroup({
      user_id: new FormControl(null)
    })

    // tipologie di eventi
    this.types = [
      { type_id: 3, type_name: "Open Stage" },
      { type_id: 4, type_name: "Altro" }
    ]

    this.getEeventDetail();
  }

  /**
   * Dettaglio evento se sono in EDIT
   */
  getEeventDetail() {
    // lista dei luoghi
    // lista dei manager
    let sources = [];
    sources.push(this.rest.placesList());
    sources.push(this.rest.managersList());

    // dettaglio evento
    if (this.id) {
      sources.push(this.rest.eventDetail(this.id));
    }

    forkJoin(...sources).subscribe(results => {
      this.isLoading = false;

      this.places = results[0].data.places;

      this.managers = results[1].data.managers;
      // se sono organizzatore posso scegliere solo il mio utente
      if (this.admin.role_id == 1) this.managers = this.managers.filter(m => m.manager_id === this.admin.manager_id);

      if (results[2])
        this.eventDetail(results[2].data.event);
    });
  }


  get f() {
    return this.form.controls;
  }
  get ft() {
    return this.formTalentsAvailable.controls;
  }
  get fr() {
    return this.formTalentsRate.controls;
  }

  /**
   * Dettaglio evento se sono in EDIT
   */
  eventDetail(event) {
    this.event = event;

    this.eventExpired = (event.end_date * 1000) < Date.now();

    this.form.setValue({
      enabled: event.enabled == 1,
      event_date: new Date(event.event_date * 1000),
      end_date: new Date(event.end_date * 1000),
      place_id: event.place_id,
      type_id: event.type_id,
      title: event.title,
      description: event.description,
      manager_id: event.manager_id,
      manager_phone: event.manager_phone,
      max_users: event.max_users,
      max_talents: event.max_talents,
      min_score: event.min_score,
      points: event.points,
      sclak_event: event.sclak_event == 1,
      cover_url: event.cover_url,
      tint_color: event.tint_color
    })

    this.dtTriggerFeedbacks.next();

    // refresh switch sclak      
    this.onChangePlace();

    // users list
    this.usersList();
    // talents list
    this.talentsList();
  }

  /**
   * Lista degli utenti partecipanti
   */
  usersList() {
    this.isLoadingUsers = true;

    this.rest.eventsUsersList(this.id).subscribe(res => {
      this.isLoadingUsers = false;
      this.users = res.data.users;

      if (this.isUpdate) {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
          dtElement.dtInstance.then((dtInstance: any) => {
            if (dtInstance.table().node().id == "table_users") {
              this.isUpdate = false;
              dtInstance.destroy();
              this.dtTriggerUsers.next();
            }
          });
        });
      } else {
        this.dtTriggerUsers.next();
      }

    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
 * Lista dei talents partecipanti
 */
  talentsList() {
    this.isLoadingTalents = true;

    this.rest.eventsTalentsList(this.id).subscribe(res => {
      this.isLoadingTalents = false;
      this.talents = res.data.users;

      if (this.isUpdate) {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
          dtElement.dtInstance.then((dtInstance: any) => {
            if (dtInstance.table().node().id == "table_talents") {
              this.isUpdate = false;
              dtInstance.destroy();
              this.dtTriggerTalents.next();
            }
          });
        });
      } else {
        this.dtTriggerTalents.next();
      }

    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  handleFileInputCover(files: FileList) {
    if (files && files.length > 0) {
      this.fileCover = files[0];

      var reader = new FileReader();
      reader.onload = (event) => {
        this.f.cover_url.setValue(event.target.result);
      };

      reader.readAsDataURL(this.fileCover);
    }
  }

  removeCover() {
    this.fileCover = null;
    this.f.cover_url.setValue(null);
    this.deleteCover = true;
  }

  onChangeType() {
    // var type_id = this.f.type_id.value;
    // if (type_id != 4) {
    //   this.f.title.setValue('');
    // }

    //this.f.title.setErrors(null)
  }

  onChangePlace() {
    var place_id = this.f.place_id.value;

    for (let place of this.places) {
      if (place.place_id == place_id) {
        this.hidden_sclak = (place.type == 0); // se è 0, nascondo palco sclak
        if (place.type == 0)
          this.f.sclak_event.patchValue(false);
        break;
      }
    }
  }

  /**
   * CAMBIO STATUS DI UN UTENTE CHE PARTECIPA ALL'EVENTO
   * per segnare se presente o assente
   */
  changeUserStatus(user_id, status) {
    this.rest.eventsUserStatusSet(this.id, user_id, status).subscribe(res => {
      this.isUpdate = true;
      this.usersList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
   * Popup con scelta stelle prima dell'accettazione dell'invito di un talent
   */
  openDialogChoiceStars(item, status) {
    const modalRef = this.modalService.open(ModalTalentStarsComponent);
    modalRef.componentInstance.user_stars = item.stars;

    modalRef.result.then((result) => {
      this.changeTalentStatus(item.user_id, status, result);
    }, (reason) => { });
  }

  /**
 * CAMBIO STATUS DI UN TALENT CHE PARTECIPA ALL'EVENTO
 */
  changeTalentStatus(user_id, status, stars) {
    this.rest.eventsTalentStatusSet(this.id, user_id, status, stars).subscribe(res => {
      this.isUpdate = true;
      this.talentsList();
      this.router.navigate(['/events']);
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
   * Modale con la lista dei talenti che il manager puo invitare
   */
  openDialogTalentInvite() {
    this.isLoadingTalentsModal = true;
    this.formTalentsAvailable.reset();
    this.submittedInvite = false;
    $("#modal-talent-invite").modal('show');

    this.rest.eventsTalentsAvailableList(this.id).subscribe(res => {
      // this.talentsAvailable = res.data.users.sort((a, b) => (a.surname < b.surname ? -1 : 1));
      this.talentsAvailable = res.data.users;
      this.isLoadingTalentsModal = false;
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
   * Esporta xlsx con lista talents dell'evento
   */
  exportTalentsCSV() {
    this.rest.eventsTalentsExportCSV(this.id).subscribe(res => {
      let filename = "event_talents" + ((this.event.title != null && this.event.title.length > 0) ? ("_" + this.event.title) : "");
      this.rest.downloadFile(res, filename, "csv");
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
   * Invita un talento
   */
  sendInvite() {
    this.submittedInvite = true;

    if (this.formTalentsAvailable.invalid) {
      return;
    }

    this.sendingInvite = true;

    this.rest.eventsTalentInvite(this.id, this.ft.talentsAvailable.value).subscribe(res => {
      this.sendingInvite = false;

      $("#modal-talent-invite").modal('hide');
      this.toastr.success('Talent invitato con successo!');
      this.isUpdate = true;
      this.talentsList();
    }, error => {
      this.sendingInvite = false;

      if (error.code == 8) {
        this.toastr.error('Questo artista ha già 3 prenotazioni attive!');
      } else {
        this.toastr.error('Si è verificato un errore, riprova!');
      }
    });
  }

  /**
 * Modale per valutare talent
 */
  openDialogTalentRate(user_id, rating_technical, rating_popular) {
    this.ratingTechnical = rating_technical ? rating_technical : 5;
    this.ratingPopular = rating_popular ? rating_popular : 5;

    this.formTalentsRate.patchValue({
      user_id: user_id
    })

    $("#modal-talent-rate").modal('show');
  }

  /**
   * Invia valutazione
   */
  sendRate() {
    this.loadingRate = true;

    var bodyData = {
      event_id: this.id,
      user_id: this.fr.user_id.value,
      rating_technical: this.ratingTechnical,
      rating_popular: this.ratingPopular
    }

    this.rest.eventsTalentRate(bodyData).subscribe(res => {
      $("#modal-talent-rate").modal('hide');

      this.loadingRate = false;
      this.toastr.success('Votazione aggiunta con successo!');
      this.isUpdate = true;
      this.talentsList();
    }, error => {
      this.loadingRate = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });

  }

  /**
   * Elimina un talent dall'evento
   */
  deleteTalent(user_id) {
    var bodyData = {
      event_id: this.id,
      user_id: user_id
    }

    this.rest.eventsTalentDelete(bodyData).subscribe(res => {
      this.toastr.success('Talent eliminato con successo!');
      this.isUpdate = true;
      this.talentsList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
   * Ammonizione del talent
   */
  admonition(user_id: number, admonition: Admonition) {
    const modalRef = this.modalService.open(ModalAdmonitionComponent);
    modalRef.componentInstance.admonition_id = admonition.admonition_id;
    modalRef.componentInstance.user_id = user_id;
    modalRef.componentInstance.event_id = this.id;
    modalRef.componentInstance.notes = admonition.notes;
    modalRef.result.then((result) => {
      this.isUpdate = true;
      this.talentsList();
    }, (reason) => { });
  }

  /**
 * Elimina uno user dall'evento
 */
  deleteUser(user_id) {
    var bodyData = {
      event_id: this.id,
      user_id: user_id
    }

    this.rest.eventsUserDelete(bodyData).subscribe(res => {
      this.toastr.success('Utente eliminato con successo!');
      this.isUpdate = true;
      this.usersList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
   * SALVATAGGIO EVENTO
   */
  save() {
    this.submitted = true;

    /*if (this.f.type_id.value == 4 && !this.f.title.value) {
      this.f.title.setErrors({ 'required': true })
    } else {
      this.f.title.setErrors(null)
    }*/

    if (this.form.invalid) {
      return;
    }

    if (this.fileCover?.size / 1024 / 1024 > 2) {
      this.toastr.error('Immagine troppo grande!');
      return;
    }

    const formData = new FormData();
    formData.append('enabled', this.f.enabled.value ? '1' : '0');
    formData.append('event_date', moment(this.f.event_date.value).unix().toString());
    formData.append('end_date', moment(this.f.end_date.value).unix().toString());
    formData.append('place_id', this.f.place_id.value.toString());
    formData.append('type_id', this.f.type_id.value.toString());
    if (this.f.title.value) formData.append('title', this.f.title.value);
    formData.append('description', this.f.description.value ? this.f.description.value : '');
    formData.append('sclak_event', this.f.sclak_event.value ? '1' : '0');
    if (this.f.manager_id.value) formData.append('manager_id', this.f.manager_id.value);
    if (this.f.manager_phone.value) formData.append('manager_phone', this.f.manager_phone.value);
    formData.append('max_users', this.f.max_users.value.toString());
    formData.append('max_talents', this.f.max_talents.value.toString());
    formData.append('min_score', this.f.min_score.value.toString());
    formData.append('points', this.f.points.value.toString());
    if (this.f.tint_color.value) formData.append('tint_color', this.f.tint_color.value.toString());

    if (this.fileCover) formData.append("file", this.fileCover);
    formData.append("delete_cover", this.deleteCover ? '1' : '0');

    this.loading = true;

    if (this.id) {
      formData.append('event_id', this.id.toString());

      this.rest.eventEdit(formData).subscribe(res => {
        this.loading = false;
        this.toastr.success('Evento aggiornato con successo!');
        this.router.navigate(['/events']);
      }, error => {
        this.loading = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    } else {
      this.rest.eventAdd(formData).subscribe(res => {
        this.loading = false;
        this.toastr.success('Evento creato con successo!');
        this.router.navigate(['/events/detail/' + res.data.event_id.toString()]);
      }, error => {
        this.loading = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }
  }

  deleteEvent() {
    this.router.navigate(['/events']);

    this.rest.eventDelete(this.id).subscribe(res => {
      this.toastr.success('Evento eliminato con successo!');
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  ngOnDestroy(): void {
    this.dtTriggerUsers.unsubscribe();
    this.dtTriggerTalents.unsubscribe();
    this.dtTriggerFeedbacks.unsubscribe();
  }
}
