<form [formGroup]="form">
    <div class="modal-body">
        <div *ngIf="removeMode" class="alert alert-warning">
            <h5><i class="icon fas fa-info"></i> Attenzione!</h5>
            Verranno eliminati solo gli slots non occupati!
        </div>

        <div *ngIf="editMode" class="text-center">
            <h3>{{date?.date | date :'EEEE dd/MM/yyyy'}}</h3>
        </div>

        <div class="post">
            <div class="row">
                <div *ngIf="!editMode" class="form-group col-12">

                    <label>Palchi</label>

                    <div *ngIf="this.showPlaces" class="row">
                        <div class="select2-info col-12">
                            <div class="select2-info">
                                <ng-select formControlName="places" [items]="places" bindLabel="place_name"
                                    bindValue="place_id" [clearable]="false" [multiple]="true"
                                    [ngClass]="{ 'is-invalid': submitted && f.places.errors }"
                                    [placeholder]="'Scegli uno o più palchi'">
                                </ng-select>
                                <div *ngIf="f.places.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                            </div>
                        </div>
                    </div>

                    <label class="mt-3">Data di inizio e Data fine</label>

                    <div class="row">
                        <div class="col-5">
                            <select formControlName="date_type" (change)="dateTypeChange()" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.date_type.errors }">
                                <option [ngValue]="0">Range di date</option>
                                <option [ngValue]="1">Intero anno</option>
                            </select>
                        </div>

                        <div class="col-7">
                            <input [selectMode]="'range'" [owlDateTime]="dt_start_end_date"
                                [owlDateTimeTrigger]="dt_start_end_date" type="text" class="form-control"
                                id="start_end_date" formControlName="start_end_date" readonly="readonly"
                                [ngClass]="{ 'is-invalid': submitted && f.start_end_date.errors }">
                            <owl-date-time [pickerType]="'calendar'" [startView]="calendarStartView"
                                (yearSelected)="chosenYearHandler($event, dt_start_end_date)"
                                (afterPickerClosed)="afterPickerClosed()" #dt_start_end_date></owl-date-time>
                            <div *ngIf="f.start_end_date.errors?.required" class="invalid-feedback">Campo obbligatorio
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="row">
                <div *ngIf="!editMode" class="form-group col-12">
                    <label>Giorni della settimana</label>

                    <div>
                        <button class="btn btn-info mb-3" (click)="selectAllWeekdays()">Intera settimana</button>
                    </div>

                    <div class="row" [ngClass]="{ 'is-invalid': submitted && f.weekdays.errors }">
                        <div formArrayName="weekdays" *ngFor="let w of weekdaysFormArray.controls; let i = index"
                            class="col-4">
                            <div class="checkbox icheck-info">
                                <input type="checkbox" [formControlName]="i" id="{{weekdaysData[i].id}}">
                                <label for="{{weekdaysData[i].id}}">{{weekdaysData[i].text}}</label>
                            </div>
                        </div>

                    </div>
                    <div class="invalid-feedback">Scegli almeno 1 giorno della settimana</div>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-12">
                    <label>Slot</label>

                    <div class="table-responsive">
                        <button class="btn btn-info mb-3" (click)="showSlotModal()"><i class="fas fa-plus mr-2"></i>
                            Aggiungi slot</button>

                        <div>
                            <ng-container *ngFor="let slot of slots">
                                <a type="button" class="btn btn-outline-info btn-sm mr-2 mb-1"
                                    (click)="updateSlot(slot)">

                                    <span *ngIf="slot.whole_day == 0">{{slot.start_time.slice(0, 5)}} -
                                        {{slot.end_time.slice(0, 5)}}</span>
                                    <span *ngIf="slot.whole_day == 1">Giorno intero</span>
                                </a>
                            </ng-container>

                            <div *ngIf="showDefaultSlotsInfo">
                                <small>Non hai ancora inserito degli slots di default. Puoi aggiungerli nella sezione
                                    "Slots"
                                    nella scheda della città.
                                    <button class="btn btn-xs btn-outline-info"
                                        [routerLink]="['/cities/detail', cityID]" (click)="dismiss()">Vai
                                        alla pagina</button>
                                </small>
                            </div>
                        </div>

                        <table *ngIf=" date?.slots" class="table table-striped dtr-inline mt-2">
                            <thead>
                                <tr>
                                    <th>Orario di inizio</th>
                                    <th>Orario di fine</th>
                                    <th>Giornata intera</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of date?.slots">
                                    <td>{{getSlot(item.start_time)}}</td>
                                    <td>{{getSlot(item.end_time)}}</td>
                                    <td>
                                        <span *ngIf="item.whole_day == 1">Si</span>
                                    </td>
                                    <td class="text-right">
                                        <button *ngIf="item.status == 1" class="btn btn-danger btn-sm"
                                            [disabled]="item.status == 0" [swal]="deleteSwal"><i
                                                class="fas fa-trash"></i> </button>

                                        <swal #deleteSwal title="Elimina" text="Vuoi eliminare questo slot ?"
                                            icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                            (confirm)="deleteSlot(item)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-default" (click)="dismiss()">Chiudi</button>
        <button *ngIf="!date?.date" type="submit" class="btn" [ngClass]="removeMode ? 'btn-danger' : 'btn-info'"
            (click)="save()" [innerHTML]="removeMode ? 'Elimina' : 'Salva'">
        </button>
    </div>
</form>