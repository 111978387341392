<section class="content-header">
    <div class="container-fluid">
        <h1>Notifiche</h1>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">

                <div class="card">
                    <div class="card-body">
                        <form [formGroup]="form">
                            <div class="form-group">
                                <label>Tipologia Notifica</label>

                                <ng-select formControlName="type" [items]="types" bindLabel="title" bindValue="id"
                                    [searchable]="false" [multiple]="false" [clearable]="false">
                                </ng-select>

                                <!-- <select formControlName="type" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.type.errors }">
                                    <option [ngValue]="null" disabled>Scegli una Tipologia</option>
                                    <option *ngFor="let d of types; let i = index;" [ngValue]="d.id">
                                        {{d.title}}
                                    </option>
                                </select> -->

                                <!-- <div *ngIf="f.type.errors?.required" class="invalid-feedback">Campo obbligatorio</div> -->
                            </div>
                            <div class="form-group">
                                <label>Tipologia Utente</label>

                                <ng-select formControlName="users_types" [items]="users_types" bindLabel="title"
                                    bindValue="id" [searchable]="false" [multiple]="true"
                                    [ngClass]="{ 'is-invalid': submitted && f.users_types.errors }"
                                    [placeholder]="'Scegli uno o più ....'">
                                </ng-select>

                                <div *ngIf="f.users_types.errors?.required" class="invalid-feedback">Campo obbligatorio
                                </div>


                                <!-- <div class="select2-info">
                                    <select id="users_types" formControlName="users_types" class="form-control"
                                        data-dropdown-css-class="select2-info" data-placeholder="Scegli uno o più ...."
                                        multiple style="width: 100%;"
                                        [ngClass]="{ 'is-invalid': submitted && f.users_types.errors }">
                                    </select>
                                    <div *ngIf="f.users_types.errors?.required" class="invalid-feedback">Campo
                                        obbligatorio</div>
                                </div> -->
                            </div>

                            <div class="form-group">
                                <label>Città</label>

                                <ng-select formControlName="city_id" [items]="cities" bindLabel="city_name"
                                    bindValue="city_id" [searchable]="false" [multiple]="false"
                                    [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }"
                                    [placeholder]="'Scegli una Città'">
                                </ng-select>

                                <div *ngIf="f.city_id.errors?.required" class="invalid-feedback">Campo
                                    obbligatorio</div>

                                <!-- <select formControlName="city_id" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }">
                                    <option [ngValue]="null">Tutte le città</option>
                                    <option *ngFor="let d of cities; let i = index;" [ngValue]="d.city_id">
                                        {{d.city_name}}
                                    </option>
                                </select>
                                <div *ngIf="f.city_id.errors?.required" class="invalid-feedback">Campo obbligatorio
                                </div> -->
                            </div>
                            <div class="form-group">
                                <label for="title">Titolo</label>
                                <input class="form-control" id="title" formControlName="title"
                                    [ngClass]="{ 'is-invalid': submitted && f.title.errors }" />
                                <div *ngIf="f.title.errors?.required" class="invalid-feedback">Campo
                                    obbligatorio</div>
                            </div>
                            <div class="form-group">
                                <label for="message">Messaggio</label>
                                <textarea class="form-control" id="message" formControlName="message"
                                    [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                                <div *ngIf="f.message.errors?.required" class="invalid-feedback">Campo
                                    obbligatorio</div>
                            </div>
                            <button [disabled]="loading" type="submit" class="btn btn-info" (click)="send()">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span><i
                                    class="fas fa-paper-plane mr-1"></i>
                                Invia</button>
                        </form>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body table-responsive">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th style="width: 10%">Data di invio</th>
                                    <th style="width: 10%">Tipologia</th>
                                    <th style="width: 10%">Città</th>
                                    <th style="width: 10%">Tipologia Utenti</th>
                                    <th style="width: 20%">Titolo</th>
                                    <th style="width: 40%">Messaggio</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of notifications">
                                    <td>{{item.creation_date*1000 | date :'yyyy/MM/dd HH:mm'}}</td>
                                    <td *ngIf="item.type == 0">Generale</td>
                                    <td *ngIf="item.type == 1">Aggiorna profilo</td>
                                    <td>{{item.city_name}}</td>
                                    <td>{{item.users_types}}</td>
                                    <td>{{item.title}}</td>
                                    <td>{{item.message}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>