<section class="content-header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-6">
                <h1>Dashboard</h1>
            </div>
            <div class="col-6">
                <div *ngIf="this.auth.isAdmin() || this.auth.isMunicipality()" class="float-sm-right">
                    <button type="button" class="btn btn-block btn-outline-danger export-button" (click)="exportLogs()">
                        <span style="display:none" id="export-spinner"
                            class="spinner-border spinner-border-sm mr-1"></span>Esporta
                        LOG</button>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">
                <div class="card card-gray">
                    <div class="card-header">
                        <h5 class="text-uppercase mb-0">Totale</h5>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 col-xl-2">
                                <div class="small-box bg-info elevation-1">
                                    <div class="inner">
                                        <h3>{{users_count}}</h3>
                                        <p>Utenti</p>
                                    </div>
                                    <div class="icon">
                                        <i class="fas fa-user"></i>
                                    </div>
                                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-xl-2">
                                <div class="small-box bg-danger elevation-1">
                                    <div class="inner">
                                        <h3>{{talents_count}}</h3>
                                        <p>Talenti</p>
                                    </div>
                                    <div class="icon">
                                        <i class="fas fa-user-friends"></i>
                                    </div>
                                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-xl-2">
                                <div class="small-box bg-warning elevation-1">
                                    <div class="inner">
                                        <h3>{{talents_with_event_count}}</h3>
                                        <p>Talenti con almeno un evento</p>
                                    </div>
                                    <div class="icon">
                                        <i class="fas fa-calendar-day"></i>
                                    </div>
                                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-xl-2">
                                <div class="small-box bg-success elevation-1">
                                    <div class="inner">
                                        <h3>{{events_count}}</h3>
                                        <p>Eventi totali</p>
                                    </div>
                                    <div class="icon">
                                        <i class="fas fa-calendar-alt"></i>
                                    </div>
                                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-xl-2">
                                <div class="small-box bg-indigo elevation-1">
                                    <div class="inner">
                                        <h3>{{events_annual_count}}</h3>
                                        <p>Eventi {{ currentYear }}</p>
                                    </div>
                                    <div class="icon">
                                        <i class="fas fa-calendar-alt"></i>
                                    </div>
                                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <div class="card card-info card-outline">
                                    <div class="card-header border-0">
                                        <h3 class="card-title">Palchi TOP 10</h3>
                                    </div>
                                    <div class="card-body table-responsive p-0">
                                        <table class="table table-striped table-valign-middle">
                                            <thead>
                                                <tr>
                                                    <th>Palco</th>
                                                    <th class="text-center" style="width: 10%">Eventi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of places">
                                                    <td>{{item.place_name}}</td>
                                                    <td class="text-center">{{item.events_count}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="card card-info card-outline">
                                    <div class="card-header border-0">
                                        <h3 class="card-title">Generi</h3>
                                    </div>
                                    <div class="card-body table-responsive p-0">
                                        <table class="table table-striped table-valign-middle">
                                            <thead>
                                                <tr>
                                                    <th>Genere</th>
                                                    <th class="text-center" style="width: 10%">Talenti</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of genres">
                                                    <td>{{item.name}}</td>
                                                    <td class="text-center">{{item.talents_count}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<section *ngIf="this.auth.isAdmin()" class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">
                <div class="card card-gray">
                    <div class="card-header">
                        <h5 class="text-uppercase mb-0">Comune di Milano</h5>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 col-xl-2">
                                <div class="small-box bg-info elevation-1">
                                    <div class="inner">
                                        <h3>{{municipality_users_count}}</h3>
                                        <p>Utenti</p>
                                    </div>
                                    <div class="icon">
                                        <i class="fas fa-user"></i>
                                    </div>
                                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-xl-2">
                                <div class="small-box bg-danger elevation-1">
                                    <div class="inner">
                                        <h3>{{municipality_talents_count}}</h3>
                                        <p>Talenti</p>
                                    </div>
                                    <div class="icon">
                                        <i class="fas fa-user-friends"></i>
                                    </div>
                                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-xl-2">
                                <div class="small-box bg-warning elevation-1">
                                    <div class="inner">
                                        <h3>{{municipality_talents_with_event_count}}</h3>
                                        <p>Talenti con almeno un evento</p>
                                    </div>
                                    <div class="icon">
                                        <i class="fas fa-calendar-day"></i>
                                    </div>
                                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-xl-2">
                                <div class="small-box bg-success elevation-1">
                                    <div class="inner">
                                        <h3>{{municipality_events_count}}</h3>
                                        <p>Eventi</p>
                                    </div>
                                    <div class="icon">
                                        <i class="fas fa-calendar-alt"></i>
                                    </div>
                                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-xl-2">
                                <div class="small-box bg-indigo elevation-1">
                                    <div class="inner">
                                        <h3>{{municipality_events_annual_count}}</h3>
                                        <p>Eventi {{ currentYear }}</p>
                                    </div>
                                    <div class="icon">
                                        <i class="fas fa-calendar-alt"></i>
                                    </div>
                                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <div class="card card-info card-outline">
                                    <div class="card-header border-0">
                                        <h3 class="card-title">Palchi TOP 10</h3>
                                    </div>
                                    <div class="card-body table-responsive p-0">
                                        <table class="table table-striped table-valign-middle">
                                            <thead>
                                                <tr>
                                                    <th>Palco</th>
                                                    <th class="text-center" style="width: 10%">Eventi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of municipality_places">
                                                    <td>{{item.place_name}}</td>
                                                    <td class="text-center">{{item.events_count}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="card card-info card-outline">
                                    <div class="card-header border-0">
                                        <h3 class="card-title">Generi</h3>
                                    </div>
                                    <div class="card-body table-responsive p-0">
                                        <table class="table table-striped table-valign-middle">
                                            <thead>
                                                <tr>
                                                    <th>Genere</th>
                                                    <th class="text-center" style="width: 10%">Talenti</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of municipality_genres">
                                                    <td>{{item.name}}</td>
                                                    <td class="text-center">{{item.talents_count}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>