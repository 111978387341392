import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/utils/constants';
import { RestService } from 'src/app/utils/services/rest.service';

@Component({
  selector: 'app-modal-export-city',
  templateUrl: './modal-export-city.component.html',
  styleUrls: ['./modal-export-city.component.css']
})
export class ModalExportCityComponent implements OnInit {
  @Input() city_id: number;

  form: FormGroup;
  months: any;
  isLoadingReport: boolean = false;
  submitted = false;

  constructor(
    public activeModal: NgbActiveModal,
    private rest: RestService,
    private appConstants: AppConstants,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.months = this.appConstants.MONTHS;

    this.form = new FormGroup({
      month: new FormControl(moment().month() + 1, Validators.required),
      year: new FormControl(moment().year(), Validators.required)
    });
  }

  get f() {
    return this.form.controls;
  }

  generateReport() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.submitted = false;

    var month = this.f.month.value.toString();
    var year = this.f.year.value.toString();

    this.isLoadingReport = true;

    this.rest.cityReport(this.city_id, month, year).subscribe(res => {
      this.isLoadingReport = false;
      this.toastr.success('Report generato con successo e inviato per email!');
      this.activeModal.close()
    }, error => {
      this.isLoadingReport = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
