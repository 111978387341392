import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ModalAdmonitionComponent } from 'src/app/modals/modal-admonition/modal-admonition.component';
import { ModalGroupUsersComponent } from 'src/app/modals/modal-group-users/modal-group-users.component';
import { Absence } from 'src/app/models/absence';
import { AdmonitionEvent } from 'src/app/models/admonition-event';
import { City } from 'src/app/models/city';
import { Concession } from 'src/app/models/concession';
import { DocumentCity } from 'src/app/models/document_city';
import { Municipality } from 'src/app/models/municipality';
import { Nation } from 'src/app/models/nation';
import { TalentPayment } from 'src/app/models/talent-payment';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/utils/services/authentication.service';
import { RestService } from 'src/app/utils/services/rest.service';
import { Utils } from 'src/app/utils/utils';
declare var $: any;

@Component({
  selector: 'app-group-detail',
  templateUrl: './group-detail.component.html',
  styleUrls: ['./group-detail.component.css']
})
export class GroupDetailComponent implements OnInit {
  isLoading: boolean = false;

  id: number;

  form: FormGroup;
  form_access: FormGroup;
  form_additional_info: FormGroup;
  form_payment: FormGroup;
  form_info_payment: FormGroup;

  submitted = false;
  submitted_access = false;
  loading = false;
  loading_access = false;

  user: User;
  cities: City[] = [];
  municipalities: Municipality[] = [];
  nations: Nation[] = [];
  concessions: Concession[] = [];
  documents: DocumentCity[] = [];
  documentCities: DocumentCity[];
  selectedDocumentCity: number;
  payments: TalentPayment[] = [];

  stars = [
    { id: 0, name: "0 stelle" },
    { id: 1, name: "1 stella" },
    { id: 2, name: "2 stelle" }
  ];

  admonitions: AdmonitionEvent[];
  absences: Absence[];

  @ViewChild(DataTableDirective, { static: true }) dtElementAdmonitions: DataTableDirective;
  dtOptionsAdmonitions: any = {};
  dtTriggerAdmonitions: Subject<any> = new Subject();
  dtUpdateAdmonitions: boolean;
  isLoadingAdmonitions: boolean = false;

  @ViewChild(DataTableDirective, { static: true }) dtElementAbsences: DataTableDirective;
  dtOptionsAbsences: any = {};
  dtTriggerAbsences: Subject<any> = new Subject();
  dtUpdateAbsences: boolean;
  isLoadingAbsences: boolean = false;

  @ViewChild(DataTableDirective, { static: true }) dtElementMembers: DataTableDirective;
  dtOptionsMembers: DataTables.Settings = {};
  dtTriggerMembers: Subject<any> = new Subject();
  dtUpdateMembers: boolean;
  isLoadingMembers: boolean = false;

  @ViewChild(DataTableDirective, { static: true }) dtElementDocuments: DataTableDirective;
  dtOptionsDocuments: DataTables.Settings = {};
  dtTriggerDocuments: Subject<any> = new Subject();
  dtUpdateDocuments: boolean;
  isLoadingDocuments: boolean = false;

  @ViewChild(DataTableDirective, { static: true }) dtElementPayments: DataTableDirective;
  dtOptionsPayments: DataTables.Settings = {};
  dtTriggerPayments: Subject<any> = new Subject();
  dtUpdatePayments: boolean;
  isLoadingPayments: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private rest: RestService,
    private toastr: ToastrService,
    public auth: AuthenticationService,
    private modalService: NgbModal
  ) {
    this.id = this.route.snapshot.params['id'];
  }

  ngOnInit(): void {
    this.dtOptionsAdmonitions = {
      columnDefs: [{ orderable: false, targets: [4] }],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.dtOptionsAbsences = {
      columnDefs: [{ orderable: false, targets: [3] }],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.dtOptionsMembers = {
      columnDefs: [
        { orderable: false, targets: [0, 3] }
      ],
      order: [1, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.dtOptionsDocuments = {
      columnDefs: [
        { orderable: false, targets: [2] }
      ],
      order: [0, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      },
      paging: false,
      searching: false
    };

    this.dtOptionsPayments = {
      columnDefs: [
        { orderable: false, targets: [3] }
      ],
      order: [0, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      },
      destroy: true,
      paging: false,
      searching: false
    };

    this.form = new FormGroup({
      email: new FormControl(null, Validators.required),
      phone: new FormControl(null, Validators.required),
      username: new FormControl(null, Validators.required),
      enabled: new FormControl(true),
      city_id: new FormControl(null, Validators.required),
      score: new FormControl(null, Validators.required),
      spotify_url: new FormControl(null),
      soundcloud_url: new FormControl(null),
      instagram_url: new FormControl(null),
      x_url: new FormControl(null),
      tiktok_url: new FormControl(null),
      youtube_url: new FormControl(null),
      facebook_url: new FormControl(null),
      link_url: new FormControl(null),
      donation_url: new FormControl(null),
      bio: new FormControl(null),
      stars: new FormControl(null, Validators.required),
      premium: new FormControl(0, Validators.required),
      home_banner_visible: new FormControl(1, Validators.required),
      public_profile: new FormControl(1, Validators.required),
      email_verified: new FormControl(0, Validators.required),
      language: new FormControl(0, Validators.required),
      is_available_private_event: new FormControl(null),
      songs_types: new FormControl(null),
    });

    this.form_access = new FormGroup({
      email: new FormControl(null, Validators.required),
    });

    this.form_additional_info = new FormGroup({
      notes: new FormControl(null)
    });

    this.form_payment = new FormGroup({
      date: new FormControl(null, Validators.required),
      amount: new FormControl(null, Validators.required),
      description: new FormControl(null, Validators.required)
    });

    this.form_info_payment = new FormGroup({
      calls_count: new FormControl(null, Validators.required),
      events_count: new FormControl(null, Validators.required),
      total_amount: new FormControl(null, Validators.required)
    });

    this.citiesList();

    if (this.id) {
      this.groupDetail();
      this.getDocumentCities();
      this.admonitionsList();
      this.absencesList();
      this.paymentsList();
    }

    if (!this.auth.isAdmin() && !this.auth.isMunicipality()) {
      this.form.disable();
    }
  }

  get f() {
    return this.form.controls;
  }

  get fa() {
    return this.form_access.controls;
  }

  get f_additional_info() {
    return this.form_additional_info.controls;
  }

  get f_payment() {
    return this.form_payment.controls;
  }

  get f_info_payment() {
    return this.form_info_payment.controls;
  }

  // Lista delle città
  citiesList() {
    this.rest.citiesList().subscribe(res => {
      this.cities = res.data.cities;
    });
  }

  getDocumentCities() {
    this.rest.documentCitiesList(this.id).subscribe(res => {
      this.documentCities = res.data.cities;
    });
  }

  groupDetail() {
    this.isLoading = true;
    this.isLoadingDocuments = true;

    this.rest.groupDetail(this.id).subscribe(res => {
      this.isLoading = false;
      this.isLoadingDocuments = false;

      this.user = res.data.user;

      var lang = 0; // italiano
      switch (this.user.language) {
        case "en": lang = 1; break;
        case "pt": lang = 2; break;
        default: lang = 0; break;
      }

      this.form.setValue({
        email: this.user.email,
        phone: this.user.phone,
        username: this.user.username,
        enabled: this.user.enabled == 1,
        score: this.user.score,
        city_id: this.user.preference_city_id,
        spotify_url: this.user.spotify_url,
        soundcloud_url: this.user.soundcloud_url,
        instagram_url: this.user.instagram_url,
        x_url: this.user.x_url,
        tiktok_url: this.user.tiktok_url,
        youtube_url: this.user.youtube_url,
        facebook_url: this.user.facebook_url,
        link_url: this.user.link_url,
        donation_url: this.user.donation_url,
        bio: this.user.bio,
        stars: this.user.stars ?? 0,
        premium: this.user.premium,
        home_banner_visible: this.user.home_banner_visible,
        public_profile: this.user.public_profile,
        email_verified: this.user.email_verified,
        language: lang,
        is_available_private_event: this.user.is_available_private_event,
        songs_types: this.user.songs_types
      });

      this.form_access.setValue({
        email: this.user.email,
      });

      this.form_additional_info.setValue({
        notes: this.user.notes
      });

      //this.admonitions = res.data.user.admonitions;
      this.documents = res.data.user.documents;
      this.dtUpdateDocuments = Utils.updateDataTable(this.dtUpdateDocuments, this.dtElementDocuments, this.dtTriggerDocuments);
      this.dtUpdateMembers = Utils.updateDataTable(this.dtUpdateMembers, this.dtElementMembers, this.dtTriggerMembers);
    });
  }

  addDocumentCity() {
    if (this.user.members.length == 0) {
      this.toastr.error("Devi aggiungere almeno un membro nel gruppo!");
      return
    }

    this.selectedDocumentCity = null;
    $("#modal-default").modal('show');
  }

  saveAccessData() {
    this.submitted_access = true;

    if (this.form_access.invalid) {
      return;
    }

    var bodyData = {
      user_id: this.user.user_id,
      email: this.fa.email.value
    };

    this.loading_access = true;

    this.rest.userEmailEdit(bodyData).subscribe(res => {
      this.loading_access = false;
      this.toastr.success('Email aggiornata con successo!');
      this.groupDetail();
    }, error => {
      this.loading_access = false;

      if (error && error.code == 3) {
        this.toastr.error('Email già presente nel sistema!');
      } else {
        this.toastr.error('Si è verificato un errore, riprova!');
      }
    });

  }

  save() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    var lang = "it"; // italiano
    switch (this.f.language.value) {
      case 1: lang = "en"; break;
      case 2: lang = "pt"; break;
      default: lang = "it"; break;
    }

    var bodyData = {
      phone: this.f.phone.value,
      username: this.f.username.value,
      spotify_url: this.f.spotify_url.value,
      soundcloud_url: this.f.soundcloud_url.value,
      instagram_url: this.f.instagram_url.value,
      x_url: this.f.x_url.value,
      tiktok_url: this.f.tiktok_url.value,
      youtube_url: this.f.youtube_url.value,
      facebook_url: this.f.facebook_url.value,
      link_url: this.f.link_url.value,
      donation_url: this.f.donation_url.value,
      bio: this.f.bio.value,
      preference_city_id: this.f.city_id.value,
      score: this.f.score.value,
      enabled: this.f.enabled.value ? 1 : 0,
      stars: this.f.stars.value,
      premium: this.f.premium.value,
      home_banner_visible: this.f.home_banner_visible.value,
      public_profile: this.f.public_profile.value,
      email_verified: this.f.email_verified.value,
      language: lang,
      is_available_private_event: this.f.is_available_private_event.value,
      songs_types: this.f.songs_types.value.toString()
    };

    this.loading = true;

    if (this.id) {
      bodyData["group_id"] = this.user.user_id;

      this.rest.groupEdit(bodyData).subscribe(res => {
        this.loading = false;
        this.toastr.success('Profilo aggiornato con successo!');
      }, error => {
        this.loading = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });

    }
    // else {

    //   this.rest.talentAdd(bodyData).subscribe(res => {
    //     this.loading = false;
    //     this.router.navigate(['/talents']);
    //   }, error => {
    //     this.loading = false;
    //     this.toastr.error('Si è verificato un errore, riprova!');
    //   });
    // }
  }

  // Admonitions

  admonitionsList() {
    this.isLoadingAdmonitions = true;

    this.rest.admonistionsList(this.id).subscribe(res => {
      this.isLoadingAdmonitions = false;
      this.admonitions = res.data.admonitions;
      this.dtUpdateAdmonitions = Utils.updateDataTable(this.dtUpdateAdmonitions, this.dtElementAdmonitions, this.dtTriggerAdmonitions);
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  addAdmonition() {
    const modalRef = this.modalService.open(ModalAdmonitionComponent);
    modalRef.componentInstance.user_id = this.id;
    modalRef.result.then((result) => {
      this.admonitionsList();
    }, (reason) => { });
  }

  // Absences
  absencesList() {
    this.isLoadingAbsences = true;

    this.rest.absencesList(this.id).subscribe(res => {
      this.isLoadingAbsences = false;
      this.absences = res.data.absences;
      this.dtUpdateAbsences = Utils.updateDataTable(this.dtUpdateAbsences, this.dtElementAbsences, this.dtTriggerAbsences);
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  absenceJustify(event_id, justified_absence) {
    this.isLoadingAbsences = true;

    this.rest.absencesJustifySet(this.id, event_id, justified_absence).subscribe(res => {
      this.isLoadingAbsences = false;
      this.absencesList();
      this.admonitionsList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  absenceAdmonition(event_id) {
    this.isLoadingAbsences = true;

    this.rest.absencesAdmonitionSet(this.id, event_id).subscribe(res => {
      this.isLoadingAbsences = false;
      this.absencesList();
      this.admonitionsList();
    }, error => {
      this.isLoadingAbsences = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  // Delete
  deleteTalent() {
    this.isLoading = true;

    this.rest.userDelete(this.id).subscribe(res => {
      this.isLoading = false;
      this.toastr.success('Gruppo eliminato con successo!');
      this.router.navigate(['/groups']);
    }, error => {
      this.isLoading = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  // info
  public saveAdditionalInfo() {
    var user_id = this.user.user_id.toString();
    var notes = this.f_additional_info.notes.value;

    this.rest.userNotesEdit(user_id, notes).subscribe(res => {
      this.toastr.success('Profilo aggiornato con successo!');
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  public openDocumentCity() {
    var city = this.documentCities.find((item) => {
      return item.city_id == this.selectedDocumentCity
    })

    this.router.navigate(['/groups/detail', this.id, 'city', this.selectedDocumentCity]);
  }

  public deleteDocumentCity(city_id) {
    this.rest.documentDelete(this.id, city_id).subscribe(res => {
      this.toastr.success('Città eliminata con successo!');

      this.groupDetail();
      this.getDocumentCities();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  //************ Membri del gruppo */
  public addGroupMember() {
    const modalRef = this.modalService.open(ModalGroupUsersComponent);
    modalRef.componentInstance.user_id = this.id;

    modalRef.result.then((result) => {
      if (result) {
        this.rest.groupMemberAdd(this.id, result).subscribe({
          next: (res) => {
            this.toastr.success("Membro aggiunto con successo!");
            this.groupDetail();
          },
          error: (e) => {
            this.toastr.error("Errore interno, riprova!");
          }
        });
      }
    }, (reason) => {
    });
  }

  public removeGroupMember(user_id) {
    if (this.user.members.length == 1) {
      this.toastr.error("Il gruppo deve avere almeno un membro!");
      return;
    }

    this.rest.groupMemberDelete(this.id, user_id).subscribe(res => {
      this.toastr.success('Membro eliminato con successo!');
      this.groupDetail();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  // Payments

  paymentsList() {
    this.isLoadingPayments = true;

    this.rest.talentPaymentsList(this.id).subscribe(res => {
      this.isLoadingPayments = false;
      this.payments = res.data.payments;
      this.f_info_payment.events_count.setValue(res.data.events_count);
      this.f_info_payment.total_amount.setValue((parseFloat(res.data.total_amount.toString()) / 100).toFixed(2).toString());
      this.f_info_payment.calls_count.setValue(res.data.callforartists_count);

      this.dtUpdatePayments = Utils.updateDataTable(this.dtUpdatePayments, this.dtElementPayments, this.dtTriggerPayments);
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  addPayment() {
    $("#modal-payment").modal('show');
  }

  deletePayment(payment_id) {
    this.rest.talentPaymentsDelete(payment_id).subscribe(res => {
      this.toastr.success('Elemento eliminato con successo!');
      this.paymentsList();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  savePayment() {
    this.submitted = true;

    if (this.form_payment.invalid) {
      return;
    }

    var date = moment(this.f_payment.date.value).unix();
    var amount = parseInt((parseFloat(this.f_payment.amount.value.replace(",", ".")) * 100).toString());
    var description = this.f_payment.description.value;

    this.isLoadingPayments = true;

    this.rest.talentPaymentsAdd(this.id, date, amount, description).subscribe(res => {
      this.isLoadingPayments = false;
      this.paymentsList();
      this.form_payment.reset();
    }, error => {
      this.isLoadingPayments = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });

  }

  savePaymentDetail() {
    this.submitted = true;

    if (this.form_info_payment.invalid) {
      return;
    }

    var calls_count = this.f_info_payment.calls_count.value;

    this.isLoadingPayments = true;

    this.rest.talentPaymentCallsEdit(this.id, calls_count).subscribe(res => {
      this.isLoadingPayments = false;
      this.toastr.success('Profilo aggiornato con successo!');
    }, error => {
      this.isLoadingPayments = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  ngOnDestroy(): void {
    this.dtTriggerAdmonitions.unsubscribe();
    this.dtTriggerAbsences.unsubscribe();
    this.dtTriggerMembers.unsubscribe();
    this.dtTriggerDocuments.unsubscribe();
    this.dtTriggerPayments.unsubscribe();
  }
}
