<section class="content-header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-6">
                <h1>Scheda Città</h1>
            </div>
            <div *ngIf="this.id" class="col-6 text-right">
                <ng-container *ngIf="this.auth.isAdmin()">
                    <button class="btn btn-success" (click)="generateReport()">
                        <i class="fas fa-clipboard mr-2"></i>Genera report
                    </button>
                </ng-container>

                <a class="btn btn-warning" [href]="'https://web.theopenstage.it/places-open-stage?city-id=' + this.id"
                    target="_blank"><i class="fas fa-eye mr-2"></i>Visualizza
                    palchi</a>

                <button [disabled]="this.auth.isManager()" class="btn btn-info" (click)="openModalAddDate()"><i
                        class="fas fa-plus mr-2"></i>Aggiungi
                    date</button>
            </div>
        </div>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">

                <form [formGroup]="form">
                    <div class="card card-info">
                        <div class="card-header text-uppercase">
                            Informazioni
                        </div>

                        <div class="card-body">
                            <div class="post">
                                <div class="row m-0">
                                    <div class="form-group col-4 text-center border p-2">
                                        <h4>Logo email</h4>

                                        <div class="text-center mb-3">
                                            <img class="image-fit" [style.background-color]="email_logo_hex_color.value"
                                                src="{{f.email_logo_url.value ? f.email_logo_url.value :'/assets/img/no-image.png'}}"
                                                alt="Cover">
                                            <div>
                                                <div *ngIf="fileEmailLogo"><small>Dimensione: {{fileEmailLogo?.size
                                                        /
                                                        1024 /
                                                        1024 | number : '1.2-2'}} MB</small></div>
                                                <small>L'immagine non deve superare 2MB</small>
                                            </div>
                                        </div>

                                        <div>
                                            <input #img_email_logo hidden="true" type="file" onclick="this.value=null"
                                                (change)="handleFileInput($event.target.files, 1)"
                                                accept=".jpg, .png" />
                                            <button type="button" class="btn btn-info"
                                                (click)="img_email_logo.click()">Scegli
                                                un logo</button>
                                            <button *ngIf="f.email_logo_url.value" type="button" class="btn btn-danger"
                                                (click)="removeImage(1)">Elimina</button>
                                        </div>

                                        <div class="row mt-2">
                                            <div class="form-group col-6">
                                                <label>Colore sfondo logo</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"
                                                            [style.background-color]="email_logo_hex_color.value"></span>
                                                    </div>
                                                    <input #email_logo_hex_color formControlName="email_logo_hex_color"
                                                        type="text" class="form-control"
                                                        placeholder="Inserisci un codice"
                                                        [ngClass]="{ 'is-invalid': submitted && f.email_logo_hex_color.errors }"
                                                        (input)="email_logo_hex_color.value = email_logo_hex_color.value.toUpperCase()" />
                                                    <div *ngIf="f.email_logo_hex_color.errors?.pattern"
                                                        class="invalid-feedback">Codice colore non
                                                        valido</div>
                                                </div>
                                                <small>es. #FFFFFF</small>
                                            </div>

                                            <div class="form-group col-6">
                                                <label>Colore testo header</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"
                                                            [style.background-color]="email_header_text_hex_color.value"></span>
                                                    </div>
                                                    <input #email_header_text_hex_color
                                                        formControlName="email_header_text_hex_color" type="text"
                                                        class="form-control" placeholder="Inserisci un codice"
                                                        [ngClass]="{ 'is-invalid': submitted && f.email_header_text_hex_color.errors }"
                                                        (input)="email_header_text_hex_color.value = email_header_text_hex_color.value.toUpperCase()" />
                                                    <div *ngIf="f.email_header_text_hex_color.errors?.pattern"
                                                        class="invalid-feedback">Codice colore non
                                                        valido</div>
                                                </div>
                                                <small>es. #000000</small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-4 text-center border p-2">
                                        <h4>Logo app/webapp</h4>

                                        <div class="text-center mb-3">
                                            <img class="image-fit"
                                                src="{{f.app_logo_url.value ? f.app_logo_url.value :'/assets/img/no-image.png'}}"
                                                alt="Cover">
                                            <div>
                                                <div *ngIf="fileAppLogo"><small>Dimensione: {{fileAppLogo?.size /
                                                        1024 /
                                                        1024 | number : '1.2-2'}} MB</small></div>
                                                <small>L'immagine non deve superare 2MB</small>
                                            </div>
                                        </div>

                                        <div>
                                            <input #img_app_logo hidden="true" type="file" onclick="this.value=null"
                                                (change)="handleFileInput($event.target.files, 2)"
                                                accept=".jpg, .png" />
                                            <button type="button" class="btn btn-info"
                                                (click)="img_app_logo.click()">Scegli
                                                un logo</button>
                                            <button *ngIf="f.app_logo_url.value" type="button" class="btn btn-danger"
                                                (click)="removeImage(2)">Elimina</button>
                                        </div>
                                    </div>

                                    <div class="form-group col-4 text-center border p-2">
                                        <h4>Copertina</h4>

                                        <div class="text-center mb-3">
                                            <img class="image-fit"
                                                src="{{f.image_url.value ? f.image_url.value :'/assets/img/no-image.png'}}"
                                                alt="Cover">
                                            <div>
                                                <div *ngIf="fileImage"><small>Dimensione: {{fileImage?.size / 1024 /
                                                        1024 | number : '1.2-2'}} MB</small></div>
                                                <small>L'immagine non deve superare 2MB</small><br>
                                            </div>
                                        </div>

                                        <div>
                                            <input #img_cover hidden="true" type="file" onclick="this.value=null"
                                                (change)="handleFileInput($event.target.files, 0)"
                                                accept=".jpg, .png" />
                                            <button type="button" class="btn btn-info"
                                                (click)="img_cover.click()">Scegli
                                                copertina</button>
                                            <button *ngIf="f.image_url.value" type="button" class="btn btn-danger"
                                                (click)="removeImage(0)">Elimina</button>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-12">
                                    <label>Nome</label>
                                    <input type="text" class="form-control" id="city_name" formControlName="city_name"
                                        [ngClass]="{ 'is-invalid': submitted && f.city_name.errors }">
                                    <div *ngIf="f.city_name.errors?.required" class="invalid-feedback">Campo
                                        obbligatorio</div>
                                </div>

                                <div class="row m-0">
                                    <div class="form-group col-6">
                                        <label>Nazione</label>
                                        <select formControlName="country_id" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.country_id.errors }">
                                            <option [ngValue]="1">Italia</option>
                                            <option [ngValue]="2">Spagna</option>
                                            <option [ngValue]="3">Portogallo</option>
                                        </select>
                                        <div *ngIf="f.country_id.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                    <div class="form-group col-6">
                                        <label>Macro Area</label>
                                        <select formControlName="macro_area" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.macro_area.errors }">
                                            <option [ngValue]="0">Nord</option>
                                            <option [ngValue]="1">Centro</option>
                                            <option [ngValue]="2">Sud</option>
                                            <option [ngValue]="3">Estero</option>
                                        </select>
                                        <div *ngIf="f.macro_area.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                    <div class="form-group col-6">
                                        <label>Link emissioni</label>
                                        <input type="text" class="form-control" id="emission_url"
                                            formControlName="emission_url"
                                            [ngClass]="{ 'is-invalid': submitted && f.emission_url.errors }">
                                        <div *ngIf="f.emission_url.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                    <div class="form-group col-6">
                                        <label>Conferma presenza con geolocalizzazione</label>
                                        <select formControlName="geolocation_required" class="form-control">
                                            <option [ngValue]="0">No</option>
                                            <option [ngValue]="1">Si</option>
                                        </select>
                                    </div>

                                    <div class="form-group col-6">
                                        <label>Obbliga ad abilitare la geolocalizzazione in fase di prenotazione
                                            palco</label>
                                        <select formControlName="booking_geolocation_required" class="form-control">
                                            <option [ngValue]="0">No</option>
                                            <option [ngValue]="1">Si</option>
                                        </select>
                                    </div>

                                    <div class="form-group col-6">
                                        <label>Possibilità di prenotare in maniera istantanea</label>
                                        <select formControlName="instant_booking_enabled" class="form-control">
                                            <option [ngValue]="0">No</option>
                                            <option [ngValue]="1">Si</option>
                                        </select>
                                        <small>Se abilitato, permette all'utente di tipo "Espressione" di prenotare una
                                            postazione per il giorno stesso.</small><br>
                                    </div>
                                </div>

                                <div class="row m-0 mt-2">
                                    <div class="col-6">
                                        <div class="card card-outline card-info">
                                            <div class="card-header text-uppercase">
                                                Espressioni
                                            </div>

                                            <div class="card-body">
                                                <div class="form-group">
                                                    <div class="col-12">
                                                        <label>Professioni disponibili per la tipologia
                                                            "Solista"</label>
                                                        <ng-select formControlName="espressioni" [items]="professions"
                                                            bindLabel="name" bindValue="profession_id"
                                                            [closeOnSelect]="false" [clearable]="false"
                                                            [multiple]="true" [placeholder]="'Scegli uno o più'">
                                                        </ng-select>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div class="col-12">
                                                        <label>Professioni disponibili per la tipologia "Gruppo"</label>
                                                        <ng-select formControlName="espressioni_group"
                                                            [items]="professions" bindLabel="name"
                                                            [closeOnSelect]="false" bindValue="profession_id"
                                                            [clearable]="false" [multiple]="true"
                                                            [placeholder]="'Scegli uno o più'">
                                                        </ng-select>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div class="col-12">
                                                        <label>Numero massimo eventi al mese</label>
                                                        <input type="text" class="form-control"
                                                            id="talent_month_events_limit"
                                                            formControlName="talent_month_events_limit"
                                                            [ngClass]="{ 'is-invalid': submitted && f.talent_month_events_limit.errors }">
                                                        <div *ngIf="f.talent_month_events_limit.errors?.required"
                                                            class="invalid-feedback">Campo obbligatorio</div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div class="col-6">
                                                        <label>Visibilità slot per talento</label>
                                                        <select formControlName="talent_slots_visibility_type"
                                                            class="form-control">
                                                            <option [ngValue]="0">Giorni</option>
                                                            <option [ngValue]="1">Mesi</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-6">
                                                        <label>Valore</label>
                                                        <input type="text" class="form-control"
                                                            id="talent_slots_visibility_value"
                                                            formControlName="talent_slots_visibility_value"
                                                            [ngClass]="{ 'is-invalid': submitted && f.talent_slots_visibility_value.errors }">
                                                        <div *ngIf="f.talent_slots_visibility_value.errors?.required"
                                                            class="invalid-feedback">Campo obbligatorio</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <div class="card card-outline card-info">
                                            <div class="card-header text-uppercase">
                                                Mestieri
                                            </div>

                                            <div class="card-body">
                                                <div class="form-group">
                                                    <div class="col-12">
                                                        <label>Professioni disponibili per la tipologia
                                                            "Solista"</label>
                                                        <ng-select formControlName="mestieri" [items]="professions"
                                                            bindLabel="name" bindValue="profession_id"
                                                            [clearable]="false" [multiple]="true"
                                                            [closeOnSelect]="false" [placeholder]="'Scegli uno o più'">
                                                        </ng-select>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div class="col-12">
                                                        <label>Professioni disponibili per la tipologia "Gruppo"</label>
                                                        <ng-select formControlName="mestieri_group"
                                                            [items]="professions" bindLabel="name"
                                                            [closeOnSelect]="false" bindValue="profession_id"
                                                            [clearable]="false" [multiple]="true"
                                                            [placeholder]="'Scegli uno o più'">
                                                        </ng-select>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div class="col-12">
                                                        <label>Numero massimo eventi al mese</label>
                                                        <input type="text" class="form-control"
                                                            id="profession_month_events_limit"
                                                            formControlName="profession_month_events_limit"
                                                            [ngClass]="{ 'is-invalid': submitted && f.profession_month_events_limit.errors }">
                                                        <div *ngIf="f.profession_month_events_limit.errors?.required"
                                                            class="invalid-feedback">Campo obbligatorio</div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div class="col-6">
                                                        <label>Visibilità slot per talento</label>
                                                        <select formControlName="profession_slots_visibility_type"
                                                            class="form-control">
                                                            <option [ngValue]="0">Giorni</option>
                                                            <option [ngValue]="1">Mesi</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-6">
                                                        <label>Valore</label>
                                                        <input type="text" class="form-control"
                                                            id="profession_slots_visibility_value"
                                                            formControlName="profession_slots_visibility_value"
                                                            [ngClass]="{ 'is-invalid': submitted && f.profession_slots_visibility_value.errors }">
                                                        <div *ngIf="f.profession_slots_visibility_value.errors?.required"
                                                            class="invalid-feedback">Campo obbligatorio</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="text-center">
                                    <ui-switch checkedLabel="ABILITATA" uncheckedLabel="NON ABILITATA"
                                        formControlName="enabled"></ui-switch>
                                </div>
                            </div>
                        </div>

                        <div class="card-footer text-center">
                            <button [disabled]="this.auth.currentUserValue.role_id == 1" type="submit"
                                class="btn btn-info" (click)="save()"> <span *ngIf="loading"
                                    class="spinner-border spinner-border-sm mr-2"></span>Salva</button>
                        </div>

                        <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i></div>
                    </div>
                </form>

                <div *ngIf="id" class="card card-info mt-5">
                    <div class="card-header text-uppercase">AREE</div>

                    <div class="card-body table-responsive">
                        <div>
                            <div class="text-center">
                                <button [disabled]="this.auth.currentUserValue.role_id == 1" class="btn btn-info"
                                    (click)="showAddAreaModal()"><i class="fas fa-plus mr-2"></i>
                                    Aggiungi area</button>
                            </div>
                        </div>

                        <table id="table_areas" datatable [dtOptions]="dtOptionsAreas" [dtTrigger]="dtTriggerAreas"
                            class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Colore</th>
                                    <th>Numero max eventi per giornata</th>
                                    <th>Numero max eventi per mese</th>
                                    <th>Premium</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of areas">
                                    <td>{{ item.name }}</td>
                                    <td>
                                        <span data-toggle="tooltip" title="" class="badge"
                                            [style.background-color]="item.hex_color">{{ item.hex_color }}</span>
                                    </td>
                                    <td>{{ item.events_per_day }}</td>
                                    <td>{{ item.events_per_month}}</td>
                                    <td [ngSwitch]="item.premium">
                                        <span *ngSwitchCase="0"
                                            class="badge badge-pill badge-success text-uppercase">NO</span>
                                        <span *ngSwitchCase="1"
                                            class="badge badge-pill badge-warning text-uppercase">SI</span>
                                    </td>
                                    <td class="text-right">
                                        <button class="btn btn-info btn-sm" (click)="showEditAreaModal(item)"><i
                                                class="fas fa-eye mr-2"></i> Visualizza</button>

                                        <button [disabled]="this.auth.currentUserValue.role_id == 1" [swal]="delete"
                                            class="btn btn-danger btn-sm"><i class="fas fa-trash"></i>
                                            Elimina</button>
                                        <swal #delete title="Elimina" text="Vuoi eliminare questa area ?" icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                            (confirm)="deleteArea(item.area_id)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoadingAreas" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>

                <div *ngIf="id" class="card card-info mt-5">
                    <div class="card-header text-uppercase">SLOTS</div>

                    <div class="card-body table-responsive">
                        <div>
                            <div class="text-center">
                                <button class="btn btn-info" (click)="addSlot()"><i class="fas fa-plus mr-2"></i>
                                    Aggiungi slot</button>
                            </div>
                        </div>

                        <table *ngIf="slots && slots.length > 0" id="table_slots" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Ora di inizio</th>
                                    <th>Ora di fine</th>
                                    <th>Intera giornata</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of slots">
                                    <td>{{ item.start_time.slice(0, 5) }}</td>
                                    <td>{{ item.end_time.slice(0, 5) }}</td>
                                    <td [ngSwitch]="item.whole_day">
                                        <span *ngSwitchCase="0"
                                            class="badge badge-pill badge-success text-uppercase">NO</span>
                                        <span *ngSwitchCase="1"
                                            class="badge badge-pill badge-warning text-uppercase">SI</span>
                                    </td>

                                    <td class="text-right">
                                        <button [swal]="deleteSlotSwal" class="btn btn-danger btn-sm"><i
                                                class="fas fa-trash"></i>
                                            Elimina</button>
                                        <swal #deleteSlotSwal title="Elimina" text="Vuoi eliminare questo slot ?"
                                            icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                            (confirm)="deleteSlot(item.slot_id)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoadingSlots" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>

                <div *ngIf="id" class="card card-info mt-5">
                    <div class="card-header text-uppercase">REGOLAMENTI</div>

                    <div class="card-body table-responsive">
                        <div>
                            <div class="text-center">
                                <button [disabled]="this.auth.currentUserValue.role_id == 1" class="btn btn-info"
                                    (click)="showAddPolicyModal()"><i class="fas fa-plus mr-2"></i>
                                    Aggiungi regolamento</button>
                            </div>
                        </div>

                        <table id="table_policies" datatable [dtOptions]="dtOptionsPolicies"
                            [dtTrigger]="dtTriggerPolicies" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Link</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of policies">
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.url }}</td>
                                    <td class="text-right">
                                        <button [disabled]="this.auth.currentUserValue.role_id == 1" [swal]="delete"
                                            class="btn btn-danger btn-sm"><i class="fas fa-trash"></i>
                                            Elimina</button>
                                        <swal #delete title="Elimina" text="Vuoi eliminare questo regolamento ?"
                                            icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                            (confirm)="deletePolicy(item.policy_id)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoadingPolicies" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Modal add/edit area -->
<div class="modal fade" id="modal-area" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <form [formGroup]="formArea">
                <div class="modal-body">
                    <div>
                        <input formControlName="area_id" type="hidden">

                        <div class="form-group col-12">
                            <label>Area</label>
                            <input formControlName="name" type="text" class="form-control"
                                placeholder="Inserisci il nome dell'area"
                                [ngClass]="{ 'is-invalid': submitted && fa.name.errors }" />
                            <div *ngIf="fa.name.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                        </div>

                        <div class="form-group col-12">
                            <label>Colore (es. #FFFFFF)</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" [style.background-color]="hex_color.value"></span>
                                </div>
                                <input #hex_color formControlName="hex_color" type="text" class="form-control"
                                    placeholder="Inserisci un codice"
                                    [ngClass]="{ 'is-invalid': submitted && fa.hex_color.errors }"
                                    (input)="hex_color.value = hex_color.value.toUpperCase()" />
                                <div *ngIf="fa.hex_color.errors?.pattern" class="invalid-feedback">Codice colore non
                                    valido</div>
                            </div>
                        </div>

                        <div class="form-group col-12">
                            <label>Numero massimo eventi per giornata</label>
                            <div class="input-group">
                                <input formControlName="events_per_day" type="number" class="form-control"
                                    placeholder="Inserisci un valore" min="0"
                                    [ngClass]="{ 'is-invalid': submitted && fa.events_per_day.errors }" />
                                <div class="input-group-append">
                                    <span class="input-group-text">eventi</span>
                                </div>
                                <div *ngIf="fa.events_per_day.errors?.required" class="invalid-feedback">Campo
                                    obbligatorio</div>
                                <div *ngIf="fa.events_per_day.errors?.min" class="invalid-feedback">Valore minimo 0
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-12">
                            <label>Numero massimo eventi per mese (solo Espressioni)</label>
                            <div class="input-group">
                                <input formControlName="events_per_month" type="number" class="form-control"
                                    placeholder="Inserisci un valore" min="0"
                                    [ngClass]="{ 'is-invalid': submitted && fa.events_per_month.errors }" />
                                <div class="input-group-append">
                                    <span class="input-group-text">eventi</span>
                                </div>
                                <div *ngIf="fa.events_per_month.errors?.required" class="invalid-feedback">Campo
                                    obbligatorio</div>
                                <div *ngIf="fa.events_per_month.errors?.min" class="invalid-feedback">Valore minimo 0
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-12">
                            <label>Premium</label>
                            <select formControlName="premium" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && fa.premium.errors }">
                                <option [ngValue]="0">No</option>
                                <option [ngValue]="1">Si</option>
                            </select>
                            <div *ngIf="fa.premium.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                        </div>

                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>
                    <button [disabled]="this.auth.currentUserValue.role_id == 1" type="submit" class="btn btn-info"
                        (click)="saveArea()">Salva</button>
                </div>
            </form>

        </div>
    </div>
</div>

<!-- Modal add/edit policy -->
<div class="modal fade" id="modal-policy" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <form [formGroup]="formPolicy">
                <div class="modal-body">
                    <div>
                        <input formControlName="policy_id" type="hidden">

                        <div class="form-group col-12">
                            <label>Nome</label>
                            <input formControlName="name" type="text" class="form-control"
                                placeholder="Inserisci il nome del regolamento"
                                [ngClass]="{ 'is-invalid': submitted && fp.name.errors }" />
                            <div *ngIf="fp.name.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                        </div>

                        <div class="form-group col-12">
                            <label>Url</label>
                            <input formControlName="url" type="text" class="form-control"
                                placeholder="Inserisci il link"
                                [ngClass]="{ 'is-invalid': submitted && fp.url.errors }" />
                            <div *ngIf="fp.url.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                        </div>

                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>
                    <button [disabled]="this.auth.currentUserValue.role_id == 1" type="submit" class="btn btn-info"
                        (click)="savePolicy()">Salva</button>
                </div>
            </form>

        </div>
    </div>
</div>