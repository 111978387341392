<form [formGroup]="form">
    <div class="modal-body">
        <div class="row">
            <div class="form-group col-12 col-lg-6">
                <label for="start_date">Data di inizio</label>
                <input [owlDateTime]="dt_start_date" [owlDateTimeTrigger]="dt_start_date" type="text"
                    class="form-control" id="start_date" formControlName="start_date" readonly>
                <owl-date-time [pickerType]="pickerType" #dt_start_date></owl-date-time>
            </div>

            <div class="form-group col-12 col-lg-6">
                <label for="end_date">Data di fine</label>
                <input [owlDateTime]="dt_end_date" [owlDateTimeTrigger]="dt_end_date" type="text" class="form-control"
                    id="end_date" formControlName="end_date" readonly>
                <owl-date-time [pickerType]="pickerType" #dt_end_date></owl-date-time>
            </div>

            <div class="form-group col-12">
                <label for="city">Città</label>
                <ng-select formControlName="city_id" [items]="cities" bindLabel="city_name" bindValue="city_id"
                    [clearable]="false" [searchable]="false" [placeholder]="'Scegli una città'"
                    (change)="onCityChange()">
                </ng-select>
            </div>

            <div *ngIf="showPlaces" class="form-group col-12">
                <label for="places">Postazioni</label>
                <ng-select formControlName="places" [items]="places" bindLabel="place_name" bindValue="place_id"
                    [clearable]="false" [multiple]="true" [placeholder]="'Scegli uno o più palchi'">
                </ng-select>
            </div>

            <div class="form-group col-12">
                <label>Emissioni</label>
                <select formControlName="emission" class="form-control">
                    <option [ngValue]="null">Tutte</option>
                    <option [ngValue]="3">Nessuna</option>
                    <option [ngValue]="4">Vocale</option>
                    <option [ngValue]="0">Bassa</option>
                    <option [ngValue]="1">Media</option>
                    <option [ngValue]="2">Alta</option>
                    <option [ngValue]="5">Acustica</option>
                </select>
            </div>
        </div>
    </div>
    <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-default" (click)="dismiss()">Chiudi</button>
        <button type="submit" class="btn btn-info" (click)="apply()">Applica</button>
    </div>
</form>