import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { RestService } from 'src/app/utils/services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Notification } from 'src/app/models/notification';
import { City } from 'src/app/models/city';
import { AuthenticationService } from 'src/app/utils/services/authentication.service';
declare var $: any;

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  isLoading: boolean = true;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  form: FormGroup;
  submitted = false;
  loading = false;

  types = [];
  cities: City[];
  users_types = [];

  notifications: Notification[];
  isUpdate: boolean;

  constructor(
    private rest: RestService,
    private toastr: ToastrService,
    public auth: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [
        { orderable: false, targets: [4, 5] },
        //{ type: "date-euro", targets: [0] }
      ],
      order: [0, "desc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    this.form = new FormGroup({
      title: new FormControl(null, Validators.required),
      message: new FormControl(null, Validators.required),
      type: new FormControl(0),
      users_types: new FormControl(null, Validators.required),
      city_id: new FormControl(null, Validators.required),
    });

    // creazione della lista delle tipologie di notifica
    this.types.push({ id: 0, title: 'Generale' });
    if (this.auth.isAdmin()) this.types.push({ id: 2, title: 'Aggiorna profilo' });

    // creazione della lista delle tipologie utente
    if (this.auth.isAdmin()) this.users_types.push({ id: 0, title: 'Utente' });
    this.users_types.push({ id: 1, title: 'Espressione' });
    this.users_types.push({ id: 2, title: 'Mestiere' });

    this.notificationsList();
    this.citiesList();
  }

  get f() {
    return this.form.controls;
  }

  /**
 * Lista delle città
 */
  citiesList() {
    this.isLoading = true;

    this.rest.citiesList().subscribe(res => {
      this.isLoading = false;
      this.cities = res.data.cities;

      // l'admin deve poter scegliere anche "Tutte le città"
      if (this.auth.isAdmin()) this.cities.unshift(new City(null, "Tutte le città"));
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /**
   * Lista delle notifiche
   */
  notificationsList() {
    this.isLoading = true;

    this.rest.notificationsList().subscribe(res => {
      this.isLoading = false;

      this.notifications = res.data.notifications;
      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  send() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    var title = this.f.title.value;
    var message = this.f.message.value;
    var type = this.f.type.value;
    var city_id = this.f.city_id.value;
    var users_types = this.f.users_types.value;
    
    this.rest.notificationSend(title, message, type, city_id, users_types).subscribe(res => {
      this.loading = false;
      this.submitted = false;
      this.f.message.setValue('');
      this.f.title.setValue('');
      this.toastr.success('Notifica inviata con successo!');

      setTimeout(() => {
        this.notificationsList();
      }, 2000);
    }, error => {
      this.loading = false;
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
