<section class="content-header">
    <div class="container-fluid">
        <h1>Presenze/Assenze Talenti</h1>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">
                <div class="card">
                    <div class="card-body table-responsive">
                        <div class="row justify-content-center mb-2">
                            <form [formGroup]="form">
                                <div class="input-group">
                                    <input type="date" class="form-control" formControlName="date"
                                        [ngClass]="{ 'is-invalid': submitted && f.date.errors }">

                                    <span class="input-group-append">
                                        <button type="button" class="btn btn-info btn-flat"
                                            (click)="eventsTalentsPerformList()">Cerca</button>
                                    </span>
                                    <div *ngIf="f.date.errors?.required" class="invalid-feedback">Campo
                                        obbligatorio</div>
                                </div>
                            </form>
                        </div>

                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Data</th>
                                    <th>Nome e Cognome</th>
                                    <th>Nome d'arte</th>
                                    <th>Evento</th>
                                    <th>Luogo</th>
                                    <th>Città</th>
                                    <th>Nota</th>
                                    <th>Stato</th>
                                    <th style="width: 25%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of events">
                                    <td>{{item.event_date * 1000 | date :'dd/MM/yyyy HH:mm'}}</td>
                                    <td>{{item.name}} {{item.surname}}</td>
                                    <td>{{item.username}}</td>
                                    <td>{{item.title}}</td>
                                    <td>{{item.place_name}}</td>
                                    <td>{{item.city_name}}</td>
                                    <td>{{item.perform_reason}}</td>
                                    <td>
                                        <span *ngIf="item.perform == 1"
                                            class="badge badge-pill badge-success text-uppercase">Presente</span>

                                        <span *ngIf="item.perform != 1 && item.justified_absence == 0"
                                            class="badge badge-pill badge-danger text-uppercase">Assente non
                                            giustificato</span>

                                        <span *ngIf="item.perform != 1 && item.justified_absence == 1"
                                            class="badge badge-pill badge-success text-uppercase">Assente
                                            giustificato</span>

                                        <span *ngIf="item.perform_admonition == 1"
                                            class="badge badge-pill badge-warning text-uppercase">Ammonito</span>
                                    </td>

                                    <td class="text-right">
                                        <button *ngIf="item.perform !=1 && item.justified_absence == null"
                                            class="btn btn-success btn-sm" (click)="absenceJustify(item, 1)"><i
                                                class="fas fa-thumbs-up mr-2"></i>
                                            Giustifica</button>

                                        <button *ngIf="item.perform !=1 && item.justified_absence == null"
                                            class="btn btn-danger btn-sm" (click)="absenceJustify(item, 0)"><i
                                                class="fas fa-thumbs-down mr-2"></i>
                                            Non
                                            giustificare</button>

                                        <button
                                            *ngIf="item.perform !=1 && item.perform_admonition != 1 && item.justified_absence !=1"
                                            class="btn btn-warning btn-sm" [swal]="admonitionSwal"><i
                                                class="fas fa-exclamation mr-2"></i>Ammonisci</button>
                                        <swal #admonitionSwal title="Ammonisci"
                                            text="Con l'ammonizione andrai ad impostare anche l'assenza NON giustificata"
                                            icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Ok, ammonisci!',  cancelButtonText: 'No' }"
                                            (confirm)="absenceAdmonition(item)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>