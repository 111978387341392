import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { RestService } from 'src/app/utils/services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';
import { Pagination } from 'src/app/models/pagination';
import * as moment from 'moment';
import { DataTableDirective } from 'angular-datatables';
import { AuthenticationService } from 'src/app/utils/services/authentication.service';

@Component({
  selector: 'app-talents',
  templateUrl: './talents.component.html',
  styleUrls: ['./talents.component.css']
})
export class TalentsComponent implements OnInit {
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;

  dtOptions: any;
  users: User[] = [];
  pagination: Pagination = new Pagination;
  globalListenFunc: Function;
  isAdmin = false;
  
  columns = {
    0: "u.creation_date",
    1: "u.name",
    2: "u.username",
    3: "u.identifier",
    4: "u.residence_city",
    5: "u.score",
    6: "u.stars",
    7: "u.notes",
    8: "u.enabled",
    9: "ud.document_status",
    10: "u.type",
    11: "pending_concessions",
    12: "u.email_verified",
    13: "admonitions_count",
    14: "absences_count"
  }

  constructor(
    private rest: RestService,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private router: Router,
    private auth: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      stateSave: true,
      columnDefs: [
        { targets: 0, type: 'date-euro' },
        { orderable: false, targets: [15] }
      ],
      order: [0, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      },
      dom: "<'mb-3 text-center'B><'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      buttons: [
        {
          text: 'Esporta Excel',
          className: 'export-excel',
          action: (e, dt, node, config) => {
            this.export();
          }
        }
      ],
      ajax: (dataTablesParameters: any, callback) => {
        this.pagination.search = dataTablesParameters.search.value;
        this.pagination.start = dataTablesParameters.start;
        this.pagination.length = dataTablesParameters.length;
        this.pagination.sort_field = this.columns[(dataTablesParameters.order[0].column)];
        this.pagination.sort_type = dataTablesParameters.order[0].dir;

        this.rest.talentsList(this.pagination).subscribe(res => {
          callback({
            recordsTotal: res.data.total,
            recordsFiltered: res.data.total,
            data: res.data.users
          });
        }, error => {
          this.toastr.error('Si è verificato un errore, riprova!');
        });
      },
      columns: [
        {
          title: 'Data iscrizione', data: null, render: function (data, type, full, meta) {
            return moment(data.creation_date * 1000).format('DD/MM/YYYY HH:mm');
          }
        },
        {
          title: 'Nome e Cognome', data: null, render: function (data, type, full, meta) {
            return data.name + " " + data.surname;
          }
        },
        { title: 'Nome d\'arte', data: 'username' },
        { title: 'Identificativo', data: 'identifier' },
        { title: 'Città Residenza', data: 'residence_city' },
        { title: 'Punti', data: 'score' },
        {
          title: 'Stelle', data: null, render: (data, type, full, meta) => {
            return this.auth.isAdmin() ? data.stars + " " + (data.stars == 1 ? 'stella' : 'stelle') : "/";
          }
        },
        {
          title: 'Note', data: null, render: (data, type, full, meta) => {
            return this.auth.isAdmin() || this.auth.isMunicipality() ? data.notes : "/";
          }
        },
        {
          title: 'Stato', data: null, render: function (data, type, full, meta) {
            switch (data.enabled) {
              case 0: return '<span class="badge badge-pill badge-danger text-uppercase">Non attivo</span>'
              case 1: return '<span class="badge badge-pill badge-success text-uppercase">Attivo</span>'
            }
          }
        },
        {
          title: 'Documentazione', data: null, render: function (data, type, full, meta) {
            switch (data.document_status) {
              default: return '';
              case 0: return '<span class="badge badge-pill badge-warning text-uppercase">In fase di revisione</span>'
              case 1: return '<span class="badge badge-pill badge-success text-uppercase">Approvata</span>'
              case 2: return '<span class="badge badge-pill badge-danger text-uppercase">Rifiutata</span>'
            }
          }
        },
        {
          title: 'Tipologia', data: null, render: function (data, type, full, meta) {
            switch (data.type) {
              case 1: return '<span class="badge badge-pill badge-warning text-uppercase">Espressioni</span>'
              case 2: return '<span class="badge badge-pill badge-danger text-uppercase">Mestieri</span>'
            }
          }
        },
        {
          title: 'Concessioni', data: null, render: function (data, type, full, meta) {
            switch (data.pending_concessions) {
              default: return '';
              case 1: return '<span class="badge badge-pill badge-warning text-uppercase">In attesa</span>'
            }
          }
        },
        {
          title: 'Verificato', data: null, render: function (data, type, full, meta) {
            switch (data.email_verified) {
              case 0: return '<span class="badge badge-pill badge-danger text-uppercase">No</span>'
              case 1: return '<span class="badge badge-pill badge-success text-uppercase">Si</span>'
            }
          }
        },
        { title: 'Ammonizioni', data: 'admonitions_count' },
        { title: 'Assenze 2024', data: 'absences_count' },
        {
          className: 'text-right',
          data: null,
          render: function (data, type, full, meta) {
            return '<a class="btn btn-info btn-sm" data-id="' + data.user_id + '"> <i class="fas fa-eye mr-2" data-id="' + data.user_id + '"> </i>Visualizza</a>';
          }
        }
      ],
      drawCallback: () => {
        $('.btn').on('click', (event) => {
          if (event.target.hasAttribute("data-id")) {
            event.preventDefault();
            this.router.navigate(['/talents/detail', event.target.getAttribute("data-id")]);
          }
        });
      }
    };
  }

  export() {
    this.datatableElement['dt'].button('.export-excel').disable();

    this.rest.talentsExport(this.pagination).subscribe(res => {
      this.rest.downloadFile(res, "Talenti", "xlsx");
      this.datatableElement['dt'].button('.export-excel').enable();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
      this.datatableElement['dt'].button('.export-excel').enable();
    });
  }
}
