<form [formGroup]="form">
    <div class="modal-body">
        <div class="row">
            <div class="form-group col-8">
                <label for="month">Mese</label>
                <ng-select formControlName="month" [items]="months" bindLabel="name" bindValue="id" [clearable]="false"
                    [searchable]="false" [placeholder]="'Scegli un mese'"
                    [ngClass]="{ 'is-invalid': submitted && f.month.errors }">
                </ng-select>
                <div *ngIf="f.month.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
            </div>

            <div class="form-group col-4">
                <label>Anno</label>
                <select formControlName="year" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.year.errors }">
                    <option [ngValue]="2024">2024</option>
                    <option [ngValue]="2025">2025</option>
                </select>
                <div *ngIf="f.year.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
            </div>
        </div>
    </div>
    <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-default" (click)="dismiss()">Chiudi</button>

        <button type="submit" [disabled]="isLoadingReport" class="btn btn-info" (click)="generateReport()">
            <span *ngIf="isLoadingReport" class="spinner-border spinner-border-sm mr-2"></span>Genera report
        </button>
    </div>
</form>