import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { HeaderComponent } from './main/header/header.component';
import { FooterComponent } from './main/footer/footer.component';
import { MenuSidebarComponent } from './main/menu-sidebar/menu-sidebar.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ManagersComponent } from './pages/managers/managers.component';
import { JwtInterceptor } from './utils/interceptors/jwt.interceptor';
import { CitiesComponent } from './pages/cities/cities.component';
import { CityDetailComponent } from './pages/cities/city-detail/city-detail.component';
import { DataTablesModule } from 'angular-datatables';
import { ErrorInterceptor } from './utils/interceptors/error.interceptor';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { PlacesComponent } from './pages/places/places.component';
import { AwardsComponent } from './pages/awards/awards.component';
import { UsersComponent } from './pages/users/users.component';
import { TalentsComponent } from './pages/talents/talents.component';
import { UserDetailComponent } from './pages/users/user-detail/user-detail.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { TalentDetailComponent } from './pages/talents/talent-detail/talent-detail.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { EventsComponent } from './pages/events/events.component';

import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { EventDetailComponent } from './pages/events/event-detail/event-detail.component';
import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider';
import { RankingComponent } from './pages/ranking/ranking.component';
import { VerifyRegistrationComponent } from './verify-registration/verify-registration.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PlaceDetailComponent } from './pages/places/place-detail/place-detail.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalPlaceDatesComponent } from './modals/modal-place-dates/modal-place-dates.component';
import { ModalPlaceSlotAddComponent } from './modals/modal-place-slot-add/modal-place-slot-add.component';
import { SponsorsComponent } from './pages/sponsors/sponsors.component';
import { ModalSponsorComponent } from './modals/modal-sponsor/modal-sponsor.component';
import { SponsorshipsComponent } from './pages/sponsorships/sponsorships.component';
import { ModalSponsorshipComponent } from './modals/modal-sponsorship/modal-sponsorship.component';
import { BannersTvComponent } from './pages/banners-tv/banners-tv.component';
import { ModalBannerTvComponent } from './modals/modal-banner-tv/modal-banner-tv.component';
import { ModalAdmonitionComponent } from './modals/modal-admonition/modal-admonition.component';
import { MusicalGenresComponent } from './pages/musical_genres/musical_genres.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { EventsPastComponent } from './pages/events_past/events_past.component';
import { NgxImgZoomModule } from 'ngx-img-zoom';
import { NgSelectModule } from '@ng-select/ng-select';
import { TalentDocumentComponent } from './pages/talents/talent-document/talent-document.component';
import { GroupsComponent } from './pages/groups/groups.component';
import { GroupDetailComponent } from './pages/groups/group-detail/group-detail.component';
import { ModalGroupUsersComponent } from './modals/modal-group-users/modal-group-users.component';
import { GroupDocumentComponent } from './pages/groups/group-document/group-document.component';
import { ModalCitiesComponent } from './modals/modal-cities/modal-cities.component';
import { ModalTalentStarsComponent } from './modals/modal-talent-stars/modal-talent-stars.component';
import { TalentAbsencesComponent } from './pages/talent-absences/talent-absences.component';
import { ModalExportLogComponent } from './modals/modal-export-log/modal-export-log.component';
import { RosterComponent } from './pages/roster/roster.component';
import { ModalFilterRosterComponent } from './modals/modal-filter-roster/modal-filter-roster.component';
import { ModalExportCityComponent } from './modals/modal-export-city/modal-export-city.component';

registerLocaleData(localeIt, 'it');

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    FooterComponent,
    MenuSidebarComponent,
    LoginComponent,
    DashboardComponent,
    ManagersComponent,
    CitiesComponent,
    CityDetailComponent,
    PlacesComponent,
    AwardsComponent,
    UsersComponent,
    TalentsComponent,
    UserDetailComponent,
    TalentDetailComponent,
    NotificationsComponent,
    EventsComponent,
    EventsPastComponent,
    EventDetailComponent,
    RankingComponent,
    VerifyRegistrationComponent,
    ChangePasswordComponent,
    PlaceDetailComponent,
    ModalPlaceDatesComponent,
    ModalPlaceSlotAddComponent,
    SponsorsComponent,
    ModalSponsorComponent,
    SponsorshipsComponent,
    ModalSponsorshipComponent,
    BannersTvComponent,
    ModalBannerTvComponent,
    ModalAdmonitionComponent,
    MusicalGenresComponent,
    TalentDocumentComponent,
    GroupsComponent,
    GroupDetailComponent,
    ModalGroupUsersComponent,
    GroupDocumentComponent,
    ModalCitiesComponent,
    ModalTalentStarsComponent,
    TalentAbsencesComponent,
    ModalExportLogComponent,
    RosterComponent,
    ModalFilterRosterComponent,
    ModalExportCityComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    DataTablesModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: true
    }),
    SweetAlert2Module.forRoot(),
    UiSwitchModule.forRoot({
      size: 'large',
      checkedTextColor: '#FFFFFF',
      checkedLabel: 'ATTIVO',
      uncheckedLabel: 'NON ATTIVO'
    }),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxBootstrapSliderModule,
    NgbModule,
    NgxImgZoomModule,
    NgSelectModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'it' },
    { provide: LOCALE_ID, useValue: 'it' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
