<section class="content-header">
    <div class="container-fluid">
        <h1>Banners Home</h1>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">
                <button class="btn btn-info mb-3" (click)="add()"><i class="fas fa-plus mr-2"></i>
                    Aggiungi</button>

                <div class="card">
                    <div class="card-body table-responsive">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Link</th>
                                    <th>Tipologia</th>
                                    <th>Lingue</th>
                                    <th>Visualizzazioni</th>
                                    <th style="width: 20%"></th>
                                </tr>
                            </thead>
                            <tbody> 
                                <tr *ngFor="let item of sponsors">
                                    <td>{{item.name}}</td>
                                    <td><a [href]="item.external_link" target="_blank">{{item.external_link}}</a></td>
                                    <td [ngSwitch]="item.type_id">
                                        <span *ngSwitchCase="0" class="badge badge-pill badge-secondary text-uppercase">Link</span>
                                        <span *ngSwitchCase="1" class="badge badge-pill badge-secondary text-uppercase">Evento</span>
                                        <span *ngSwitchCase="2" class="badge badge-pill badge-secondary text-uppercase">Città</span>
                                        <span *ngSwitchCase="3" class="badge badge-pill badge-secondary text-uppercase">Area</span>
                                    </td>
                                    <td>{{item.languages_string}}</td>
                                    <td>{{item.click_count}}</td>
                                    <td class="text-right">
                                        <button class="btn btn-info btn-sm" (click)="edit(item)"><i class="fas fa-eye mr-2"></i> Visualizza</button>

                                        <button class="btn btn-danger btn-sm" [swal]="deleteSwal"><i class="fas fa-trash mr-2"></i>
                                            Elimina</button>

                                        <swal #deleteSwal title="Elimina" text="Vuoi eliminare questo sponsor ?" icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }" (confirm)="delete(item)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>