<section class="content-header">
    <div class="container-fluid">
        <h1>Luoghi</h1>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">
                <button [disabled]="this.auth.currentUserValue.role_id == 1" class="btn btn-info mb-3"
                    [routerLink]="['/places/new']"><i class="fas fa-plus mr-1"></i>
                    Aggiungi</button>

                <div class="card">
                    <div class="card-body table-responsive">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nome</th>
                                    <th>Città</th>
                                    <th>Indirizzo</th>
                                    <th>Zona</th>
                                    <th>Tipologia palco</th>
                                    <th>Espressioni</th>
                                    <th>Stato</th>
                                    <th style="width: 20%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of places">
                                    <td>{{item.place_id}}</td>
                                    <td>{{item.place_name}}</td>
                                    <td>{{getCityName(item.city_id)}}</td>
                                    <td>{{item.address}}</td>
                                    <th>{{item.area_name}}</th>
                                    <td [ngSwitch]="item.type">
                                        <span *ngSwitchCase="0"
                                            class="badge badge-pill badge-secondary text-uppercase">Standard</span>
                                        <span *ngSwitchCase="1"
                                            class="badge badge-pill badge-danger text-uppercase">Sclak</span>
                                        <span *ngSwitchCase="2"
                                            class="badge badge-pill badge-warning text-uppercase">Stradarte</span>
                                        <span *ngSwitchCase="3"
                                            class="badge badge-pill badge-info text-uppercase">Palco libero</span>
                                    </td>
                                    <th [ngSwitch]="item.profession">
                                        <span *ngSwitchCase="0"
                                            class="badge badge-pill badge-danger text-uppercase">NO</span>
                                        <span *ngSwitchCase="1"
                                            class="badge badge-pill badge-success text-uppercase">SI</span>
                                    <th [ngSwitch]="item.enabled">
                                        <span *ngSwitchCase="0"
                                            class="badge badge-pill badge-danger text-uppercase">Disabilitato</span>
                                        <span *ngSwitchCase="1"
                                            class="badge badge-pill badge-success text-uppercase">Abilitato</span>
                                    </th>
                                    <td class="text-right">
                                        <button class="btn btn-info btn-sm"
                                            [routerLink]="['/places/detail', item.place_id]"><i
                                                class="fas fa-eye mr-1"></i> Visualizza</button>

                                        <button [disabled]="this.auth.currentUserValue.role_id == 1"
                                            class="btn btn-danger btn-sm" [swal]="deleteSwal"><i
                                                class="fas fa-trash mr-1"></i>
                                            Elimina</button>

                                        <swal #deleteSwal title="Elimina" text="Sei sicuro di volere cancellare il Luogo? Cancellandolo saranno eliminate anche tutte le prenotazioni effettuate in questa postazione."
                                            icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                            (confirm)="delete(item)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>