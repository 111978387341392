<section class="content-header">
    <div class="container-fluid">
        <h1>Eventi</h1>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">
                <button class="btn btn-info mb-3" [routerLink]="['/events/new']"><i class="fas fa-plus mr-2"></i>
                    Aggiungi</button>

                <div class="card">
                    <div class="card-body table-responsive">
                        <table id="table-events" datatable [dtOptions]="dtOptions" class="table table-striped ">
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>