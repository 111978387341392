import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { RestService } from 'src/app/utils/services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';
import { Pagination } from 'src/app/models/pagination';
import * as moment from 'moment';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalFilterRosterComponent } from 'src/app/modals/modal-filter-roster/modal-filter-roster.component';
import { hasClassName } from '@ng-bootstrap/ng-bootstrap/util/util';

@Component({
  selector: 'app-roster',
  templateUrl: './roster.component.html',
  styleUrls: ['./roster.component.css']
})
export class RosterComponent implements OnInit {
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;

  dtOptions: any;
  users: User[] = [];
  pagination: Pagination = new Pagination;
  globalListenFunc: Function;
  isAdmin = false;

  citiesSelected: [];
  starsSelected: [];

  columns = {
    0: "u.creation_date",
    1: "u.name",
    2: "u.username",
    3: "u.identifier",
    4: "u.residence_city",
    6: "u.stars",
    7: "u.instagram_url",
    8: "u.notes"
  }

  constructor(
    private rest: RestService,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      stateSave: true,
      columnDefs: [
        { targets: 0, type: 'date-euro' },
        { orderable: false, targets: [6, 7, 8] }
      ],
      order: [0, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      },
      dom: "<'mb-3 text-center'B><'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      buttons: [
        {
          text: 'Esporta Excel',
          className: 'export-excel',
          action: (e, dt, node, config) => {
            this.export();
          }
        },
        {
          extend: 'spacer'
        },
        {
          text: 'Filtra',
          action: () => {
            const modalRef = this.modalService.open(ModalFilterRosterComponent);
            modalRef.componentInstance.cities = this.citiesSelected;
            modalRef.componentInstance.stars = this.starsSelected;

            modalRef.result.then((result) => {
              this.citiesSelected = result.cities;
              this.starsSelected = result.stars;

              sessionStorage.setItem('filteredRosterCityIds', JSON.stringify(this.citiesSelected));
              sessionStorage.setItem('filteredRosterStarIds', JSON.stringify(this.starsSelected));

              $('#table-talents').DataTable().ajax.reload();
            }, (reason) => { });
          }
        }
      ],
      ajax: (dataTablesParameters: any, callback) => {
        this.pagination.search = dataTablesParameters.search.value;
        this.pagination.start = dataTablesParameters.start;
        this.pagination.length = dataTablesParameters.length;
        this.pagination.sort_field = this.columns[(dataTablesParameters.order[0].column)];
        this.pagination.sort_type = dataTablesParameters.order[0].dir;

        const savedCityIds = sessionStorage.getItem('filteredRosterCityIds');
        this.citiesSelected = savedCityIds ? JSON.parse(savedCityIds) : [];

        const savedStarIds = sessionStorage.getItem('filteredRosterStarIds');
        this.starsSelected = savedStarIds ? JSON.parse(savedStarIds) : [];

        this.rest.rosterList(this.pagination, this.citiesSelected, this.starsSelected).subscribe(res => {
          callback({
            recordsTotal: res.data.total,
            recordsFiltered: res.data.total,
            data: res.data.users
          });
        }, error => {
          this.toastr.error('Si è verificato un errore, riprova!');
        });
      },
      columns: [
        {
          title: 'Data iscrizione', data: null, render: function (data, type, full, meta) {
            return moment(data.creation_date * 1000).format('DD/MM/YYYY HH:mm');
          }
        },
        {
          title: 'Nome e Cognome', data: null, render: function (data, type, full, meta) {
            return data.name + " " + data.surname;
          }
        },
        { title: 'Nome d\'arte', data: 'username' },
        { title: 'Identificativo', data: 'identifier' },
        { title: 'Città Residenza', data: 'residence_city' },
        {
          title: 'Stelle', data: null, render: (data, type, full, meta) => {
            return data.stars + " " + (data.stars == 1 ? 'stella' : 'stelle');
          }
        },
        {
          title: 'Instagram', data: null, render: (data, type, full, meta) => {
            return data.instagram_url ? "<a href='" + data.instagram_url + "' target='_blank'>" + data.instagram_url + "</a>" : "";
          }
        },
        {
          title: 'Note', data: null, render: (data, type, full, meta) => {
            return data.notes;
          }
        },
        {
          className: 'text-right',
          data: null,
          render: function (data, type, full, meta) {
            return '<a class="btn btn-info btn-sm" data-id="' + data.user_id + '"> <i class="fas fa-eye mr-2" data-id="' + data.user_id + '"> </i>Visualizza</a>';
          }
        }
      ],
      drawCallback: () => {
        $('.btn').on('click', (event) => {
          if (event.target.hasAttribute("data-id")) {
            event.preventDefault();
            this.router.navigate(['/talents/detail', event.target.getAttribute("data-id")]);
          }
        });
      }
    };
  }

  export() {
    this.datatableElement['dt'].button('.export-excel').disable();

    this.rest.rosterExport(this.pagination, this.citiesSelected, this.starsSelected).subscribe(res => {
      this.rest.downloadFile(res, "Roster", "xlsx");
      this.datatableElement['dt'].button('.export-excel').enable();
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
      this.datatableElement['dt'].button('.export-excel').enable();
    });
  }
}
