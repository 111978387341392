<section class="content-header">
    <div class="container-fluid">
        <h1>Scheda Evento</h1>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">

                <form [formGroup]="form">

                    <div class="card card-info">
                        <div class="card-header text-uppercase">
                            Informazioni
                        </div>

                        <div class="card-body">
                            <div class="post">
                                <div class="row">
                                    <div class="form-group col-12 text-center">
                                        <div *ngIf="event?.wiseair_url" style="right:0"
                                            class="position-absolute">
                                            <div class="end-0">
                                                <div class="small-box">
                                                    <a [href]="event.wiseair_url" target="_blank"><img
                                                            src="/assets/img/btn_wiseair.png" height="65" /></a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="text-center mb-3">
                                            <img height="250"
                                                src="{{f.cover_url.value ? f.cover_url.value :'/assets/img/no-image.png'}}"
                                                alt="Cover">
                                            <div>
                                                <div *ngIf="fileCover"><small>Dimensione: {{fileCover?.size / 1024 /
                                                        1024 | number : '1.2-2'}} MB</small></div>
                                                <small>L'immagine non deve superare 2MB</small>
                                            </div>
                                        </div>

                                        <div>
                                            <input #img_cover hidden="true" type="file" onclick="this.value=null"
                                                (change)="handleFileInputCover($event.target.files)" accept=".jpg" />
                                            <button type="button" class="btn btn-info"
                                                (click)="img_cover.click()">Scegli immagine di copertina</button>
                                        </div>

                                        <div *ngIf="f.cover_url.value">
                                            <button type="button" class="btn btn-danger mt-2"
                                                (click)="removeCover()">Elimina</button>
                                        </div>
                                    </div>

                                    <div *ngIf="event?.sensors_gmove_value" class="col-12">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-3 col-sm-6 col-12">
                                                <div class="small-box dark-mode">
                                                    <div class="inner">
                                                        <h3>{{event.sensors_gmove_value?.todaypeople}}</h3>
                                                        <p>Persone sono state qui</p>
                                                    </div>
                                                    <div class="icon text-warning">
                                                        <i class="fas fa-users"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-6 col-12">
                                                <div class="small-box dark-mode">
                                                    <div class="inner">
                                                        <h3>{{event.sensors_gmove_value?.interessrate}}</h3>
                                                        <p>Persone hanno assistito all'esibizione</p>
                                                    </div>
                                                    <div class="icon text-warning">
                                                        <i class="fas fa-eye"></i>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-4">
                                        <label for="event_date">Data e ora di inizio</label>
                                        <input [owlDateTime]="dt_event_date" [owlDateTimeTrigger]="dt_event_date"
                                            type="text" class="form-control" id="event_date"
                                            formControlName="event_date" readonly="readonly"
                                            [ngClass]="{ 'is-invalid': submitted && f.event_date.errors }">
                                        <owl-date-time #dt_event_date></owl-date-time>
                                        <div *ngIf="f.event_date.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                    <div class="form-group col-12 col-lg-4">
                                        <label for="end_date">Data e ora di fine</label>
                                        <input [owlDateTime]="dt_end_date" [owlDateTimeTrigger]="dt_end_date"
                                            type="text" class="form-control" id="end_date" formControlName="end_date"
                                            readonly="readonly"
                                            [ngClass]="{ 'is-invalid': submitted && f.end_date.errors }">
                                        <owl-date-time #dt_end_date></owl-date-time>
                                        <div *ngIf="f.end_date.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                    <div class="form-group col-12 col-lg-4">
                                        <label>Luogo</label>
                                        <select formControlName="place_id"
                                            class="form-control"
                                            (change)="onChangePlace()"
                                            [ngClass]="{ 'is-invalid': submitted && f.place_id.errors }">
                                            <option [ngValue]="null" disabled>Scegli un luogo</option>
                                            <option *ngFor="let d of places; let i = index;" [attr.data-type]="d.type"
                                                [ngValue]="d.place_id">
                                                {{d.place_name}}
                                            </option>
                                        </select>
                                        <div *ngIf="f.place_id.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-4">
                                        <label>Titolo</label>
                                        <select formControlName="type_id" class="form-control" (change)="onChangeType()"
                                            [ngClass]="{ 'is-invalid': submitted && f.type_id.errors }">
                                            <option [ngValue]="null" disabled>Scegli un titolo</option>
                                            <option *ngFor="let d of types; let i = index;" [ngValue]="d.type_id">
                                                {{d.type_name}}
                                            </option>
                                        </select>
                                        <div *ngIf="f.type_id.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-8">
                                        <label>Titolo Personalizzato</label>
                                        <input type="text" class="form-control" id="title" (change)="onChangeType()"
                                            formControlName="title" [readonly]="!f.type_id.value"
                                            [ngClass]="{ 'is-invalid': submitted && f.title.errors }">
                                        <div *ngIf="f.title.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                    <div class="form-group col-12">
                                        <label>Descrizione <small>(opzionale)</small></label>
                                        <textarea formControlName="description"
                                            class="form-control" rows="10"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="post">
                                <h2 class="text-center mb-4"><span class="badge badge-secondary">Organizzatore</span>
                                </h2>

                                <div class="row">
                                    <div class="form-group col-12 col-lg-4">
                                        <label>Organizzatore</label>
                                        <select formControlName="manager_id" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.manager_id.errors }">
                                            <option [ngValue]="null">Scegli un organizzatore</option>
                                            <option *ngFor="let d of managers; let i = index;" [ngValue]="d.manager_id">
                                                {{d.surname}} {{d.name}}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="form-group col-12 col-lg-4">
                                        <label for="manager_phone">Numero di telefono <small>(es.
                                                +393331234567)</small></label>
                                        <input type="text" class="form-control" id="manager_phone"
                                            formControlName="manager_phone"
                                            [ngClass]="{ 'is-invalid': submitted && f.manager_phone.errors }">
                                        <div *ngIf="f.manager_phone.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>
                                </div>
                            </div>

                            <div class="post">
                                <h2 class="text-center mb-4"><span class="badge badge-secondary">Impostazioni
                                        Evento</span></h2>

                                <div class="row">
                                    <div class="form-group col-12 col-lg-4">
                                        <label for="max_users">Numero massimo UTENTI</label>
                                        <input type="number" class="form-control" id="max_users"
                                            formControlName="max_users"
                                            [ngClass]="{ 'is-invalid': submitted && f.max_users.errors }">
                                        <small>Numero massimo consentito di UTENTI per poter partecipare
                                            all'evento</small>
                                        <div *ngIf="f.max_users.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                    <div class="form-group col-12 col-lg-4">
                                        <label for="max_talents">Numero massimo TALENTI</label>
                                        <input type="number" class="form-control" id="max_talents"
                                            formControlName="max_talents"
                                            [ngClass]="{ 'is-invalid': submitted && f.max_talents.errors }">
                                        <small>Numero massimo consentito di TALENT per poter partecipare
                                            all'evento</small>
                                        <div *ngIf="f.max_talents.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                    <div class="form-group col-12 col-lg-4">
                                        <label for="min_score">Minimo PUNTI</label>
                                        <input type="number" class="form-control" id="min_score"
                                            formControlName="min_score"
                                            [ngClass]="{ 'is-invalid': submitted && f.min_score.errors }">
                                        <small>Punteggio minimo che il talent deve avere per poter partecipare
                                            all'evento</small>
                                        <div *ngIf="f.min_score.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                    <div class="form-group col-12 col-lg-4">
                                        <label for="points">Punti</label>
                                        <input type="number" class="form-control" id="points" formControlName="points"
                                            [ngClass]="{ 'is-invalid': submitted && f.points.errors }">
                                        <small>Valore, in punti, dell'evento</small>
                                        <div *ngIf="f.points.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                    <div class="form-group col-12 col-lg-4">
                                        <label>Colore (es. #FFFFFF)</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"
                                                    [style.background-color]="tint_color.value"></span>
                                            </div>
                                            <input #tint_color formControlName="tint_color" type="text"
                                                class="form-control" placeholder="Inserisci un codice"
                                                [ngClass]="{ 'is-invalid': submitted && f.tint_color.errors }"
                                                (input)="tint_color.value = tint_color.value.toUpperCase()" />
                                            <div *ngIf="f.tint_color.errors?.pattern" class="invalid-feedback">Codice
                                                colore non valido</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="text-center">
                                    <ui-switch checkedLabel="VISIBILE" uncheckedLabel="NON VISIBILE"
                                        formControlName="enabled"></ui-switch>
                                    <ui-switch [hidden]="hidden_sclak" checkedLabel="PALCO SCLAK"
                                        formControlName="sclak_event" uncheckedLabel="PALCO STANDARD">
                                    </ui-switch>
                                </div>
                            </div>

                        </div>

                        <div class="card-footer text-center">
                            <button type="submit" class="btn btn-info" (click)="save()"> <span *ngIf="loading"
                                    class="spinner-border spinner-border-sm mr-1"></span>
                                Salva</button>

                            <button *ngIf="id" class="btn btn-danger" [swal]="deleteEventSwal"> <span *ngIf="loading"
                                    class="spinner-border spinner-border-sm mr-1"></span>
                                Elimina</button>
                            <swal #deleteEventSwal title="Elimina" text="Vuoi eliminare questo evento ?" icon="warning"
                                [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                (confirm)="deleteEvent()"></swal>
                        </div>

                        <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>
                </form>

                <div *ngIf="id" class="card card-info mt-5">
                    <div class="card-header text-uppercase">
                        UTENTI partecipanti
                    </div>

                    <div class="card-body table-responsive">
                        <table id="table_users" datatable [dtOptions]="dtOptionsUsers" [dtTrigger]="dtTriggerUsers"
                            class="table table-striped dtr-inline">
                            <thead>
                                <tr>
                                    <th>Cognome Nome</th>
                                    <th>Username</th>
                                    <th>Email</th>
                                    <th>Presenza</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of users">
                                    <td>{{item.surname}} {{item.name}}</td>
                                    <td>{{item.username}}</td>
                                    <td>{{item.email}}</td>
                                    <td [ngSwitch]="item.status">
                                        <span *ngSwitchCase="1"
                                            class="badge badge-pill badge-success text-uppercase">Presente</span>
                                        <span *ngSwitchCase="2"
                                            class="badge badge-pill badge-danger text-uppercase">Assente</span>
                                    </td>
                                    <td class="text-right">
                                        <button *ngIf="item.status == 0 || item.status == 1"
                                            (click)="changeUserStatus(item.user_id, 2)" class="btn btn-danger btn-sm"><i
                                                class="fas fa-thumbs-down mr-1"></i> Assente</button>
                                        <button *ngIf="item.status == 0 || item.status == 2"
                                            (click)="changeUserStatus(item.user_id, 1)"
                                            class="btn btn-success btn-sm"><i class="fas fa-thumbs-up mr-1"></i>
                                            Presente</button>
                                        <button [swal]="delete" class="btn btn-danger btn-sm"><i
                                                class="fas fa-trash"></i></button>
                                        <swal #delete title="Elimina" text="Vuoi eliminare questo utente ?"
                                            icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                            (confirm)="deleteUser(item.user_id)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoadingUsers" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>

                <div *ngIf="event" class="card card-info mt-5">
                    <div class="card-header text-uppercase">
                        ARTISTI partecipanti
                    </div>

                    <div class="card-body table-responsive">
                        <div>
                            <div class="text-center">
                                <button *ngIf="!eventExpired" class="btn btn-info"
                                    (click)="openDialogTalentInvite()"><i class="far fa-paper-plane mr-1"></i>
                                    Invita un artista</button>
                                <button *ngIf="talents.length > 0" class="btn btn-info" (click)="exportTalentsCSV()"><i
                                        class="fa fa-download mr-1"></i>
                                    Esporta CSV artisti</button>
                            </div>
                        </div>

                        <table id="table_talents" datatable [dtOptions]="dtOptionsTalents"
                            [dtTrigger]="dtTriggerTalents" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Iscrizione</th>
                                    <th>Cognome Nome</th>
                                    <th>Nome d'arte</th>
                                    <th>Email</th>
                                    <th class="text-center">Ammonizioni</th>
                                    <th>Social</th>
                                    <th class="text-center">Voto<br><small>(tecnico/popolare)</small></th>
                                    <th>Stato</th>
                                    <th>Partecipazione</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of talents">
                                    <td>{{item.creation_date * 1000 | date :'dd/MM/yyyy HH:mm'}}</td>
                                    <td>{{item.surname}} {{item.name}}</td>
                                    <td>{{item.username}}</td>
                                    <td>{{item.email}}</td>
                                    <td class="text-center">{{item.admonition_count}}</td>
                                    <td>
                                        <a *ngIf="item.facebook_url" [href]="item.facebook_url" target="_blank"><img
                                                height="30" src="/assets/img/ic_facebook.png"></a>
                                        <a *ngIf="item.instagram_url" [href]="item.instagram_url" target="_blank"><img
                                                height="30" src="/assets/img/ic_instagram.png"></a>
                                        <a *ngIf="item.youtube_url" [href]="item.youtube_url" target="_blank"><img
                                                height="30" src="/assets/img/ic_youtube.png"></a>
                                    </td>
                                    <td>{{item.rating_technical}} / {{item.rating_popular}}</td>
                                    <td>
                                        <div *ngIf="item.type == 0" [ngSwitch]="item.status">
                                            <span *ngSwitchCase="0"
                                                class="badge badge-pill badge-warning text-uppercase">IN ATTESA DI
                                                CONFERMA</span>
                                            <span *ngSwitchCase="1"
                                                class="badge badge-pill badge-success text-uppercase">CONFERMATO</span>
                                            <span *ngSwitchCase="2"
                                                class="badge badge-pill badge-danger text-uppercase">RIFIUTATO</span>
                                        </div>
                                        <div *ngIf="item.type == 1" [ngSwitch]="item.status">
                                            <span *ngSwitchCase="0"
                                                class="badge badge-pill badge-warning text-uppercase">IN ATTESA DI
                                                CONFERMA DA PARTE DEL TALENT</span>
                                            <span *ngSwitchCase="1"
                                                class="badge badge-pill badge-success text-uppercase">CONFERMATO</span>
                                            <span *ngSwitchCase="2"
                                                class="badge badge-pill badge-danger text-uppercase">RIFIUTATO</span>
                                        </div>
                                    </td>
                                    <td [ngSwitch]="item.perform">
                                        <span *ngSwitchCase="null"
                                            class="badge badge-pill badge-warning text-uppercase">NON CONFERMATA</span>
                                        <span *ngSwitchCase="1"
                                            class="badge badge-pill badge-success text-uppercase">PRESENTE</span>
                                        <span *ngSwitchCase="0"
                                            class="badge badge-pill badge-danger text-uppercase">ASSENTE</span>
                                    </td>
                                    <td class="text-right">
                                        <button class="btn btn-info btn-sm"
                                            [routerLink]="[item.is_group == 0 ? '/talents/detail' : '/groups/detail', item.user_id]"><i
                                                class="fas fa-eye mr-1"></i> Visualizza</button>
                                        <button *ngIf="item.status == 1"
                                            (click)="admonition(item.user_id, item.admonition)"
                                            class="btn btn-warning btn-sm"><i class="fas fa-exclamation"></i></button>
                                        <button
                                            *ngIf="item.type == 0 && item.status == 0 && event.place_type != 2"
                                            (click)="openDialogChoiceStars(item, 1)" class="btn btn-success btn-sm"><i
                                                class="fas fa-thumbs-up mr-1"></i>
                                            Accetta</button>
                                        <button
                                            *ngIf="item.type == 0 && item.status == 0 && event.place_type != 2"
                                            (click)="changeTalentStatus(item.user_id, 2, item.stars)"
                                            class="btn btn-danger btn-sm"><i class="fas fa-thumbs-down mr-1"></i>
                                            Rifiuta</button>
                                        <button *ngIf="item.status == 1" class="btn btn-secondary btn-sm"
                                            (click)="openDialogTalentRate(item.user_id, item.rating_technical, item.rating_popular)"><i
                                                class="fas fa-clipboard mr-1"></i>
                                            Vota</button>
                                        <button [swal]="delete" class="btn btn-danger btn-sm"><i
                                                class="fas fa-trash"></i></button>
                                        <swal #delete title="Elimina" text="Vuoi eliminare questo talent ?"
                                            icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                            (confirm)="deleteTalent(item.user_id)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoadingTalents" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>

                <div *ngIf="id" class="card card-info mt-5">
                    <div class="card-header text-uppercase">
                        FEEDBACKS
                    </div>

                    <div class="card-body table-responsive">

                        <table id="table_feedbacks" datatable [dtOptions]="dtOptionsFeedbacks"
                            [dtTrigger]="dtTriggerFeedbacks" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Data</th>
                                    <th>Cognome Nome</th>
                                    <th>Nome d'arte</th>
                                    <th>Email</th>
                                    <th>Voto</th>
                                    <th>Note</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of event?.feedbacks">
                                    <td>{{item.creation_date * 1000 | date :'dd/MM/yyyy HH:mm'}}</td>
                                    <td>{{item.surname}} {{item.name}}</td>
                                    <td>{{item.username}}</td>
                                    <td>{{item.email}}</td>
                                    <td>
                                        <img *ngIf="item.rating==1" height="30" src="/assets/img/ic_like.png">
                                        <img *ngIf="item.rating==0" height="30" src="/assets/img/ic_dislike.png">
                                    </td>
                                    <td>{{item.notes}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>

        </div>
    </div>

</section>

<!-- Modal talent da invitare -->
<div class="modal fade" id="modal-talent-invite" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <form [formGroup]="formTalentsAvailable">
                <div class="modal-body">
                    <label>Artisti</label>

                    <div>
                        <ng-select formControlName="talentsAvailable" [items]="talentsAvailable" bindLabel="search_name"
                            [multiple]="true" bindValue="user_id" [virtualScroll]=true
                            placeholder="Scegli un artista da invitare" notFoundText="Nessun artista trovato"
                            [ngClass]="{ 'is-invalid': submittedInvite && ft.talentsAvailable.errors }">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                <span aria-hidden="true" class="ng-value-icon left" (click)="clear(item)"
                                    aria-hidden="true">×</span>
                                <span class="ng-value-label">{{item.search_name}}</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                <div><span>{{item.search_name}}</span></div>
                            </ng-template>
                        </ng-select>

                        <div *ngIf="ft.talentsAvailable.errors?.required" class="invalid-feedback">Campo obbligatorio
                        </div>
                    </div>
                    <div *ngIf="isLoadingTalentsModal" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>
                    <button type="submit" class="btn btn-info" (click)="sendInvite()">Invita</button>
                </div>
            </form>

        </div>
    </div>
</div>

<!-- Modal votazione -->
<div class="modal fade" id="modal-talent-rate" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <form [formGroup]="formTalentsRate">
                <input formControlName="user_id" type="hidden">

                <div class="modal-body">
                    <div class="mb-3">
                        <label class="mb-4">Valutazione Tecnica (0/10)</label>
                        <mv-slider [(value)]="ratingTechnical" [step]="0.5" [tooltip]="'always'" [min]="0" [max]="10">
                        </mv-slider>
                    </div>
                    <div>
                        <label class="mb-4">Valutazione Popolare (0/10)</label>
                        <mv-slider [(value)]="ratingPopular" [step]="0.5" [tooltip]="'always'" [min]="0" [max]="10">
                        </mv-slider>
                    </div>
                </div>

                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>
                    <button type="submit" class="btn btn-info" (click)="sendRate()"> <span *ngIf="loadingRate"
                            class="spinner-border spinner-border-sm mr-1"></span>
                        Invia</button>
                </div>
            </form>
        </div>
    </div>
</div>