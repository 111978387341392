import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { City } from 'src/app/models/city';
import { Place } from 'src/app/models/place';
import { RestService } from 'src/app/utils/services/rest.service';

@Component({
  selector: 'app-modal-export-log',
  templateUrl: './modal-export-log.component.html',
  styleUrls: ['./modal-export-log.component.css']
})
export class ModalExportLogComponent implements OnInit {

  form: FormGroup;
  pickerType: string = 'calendar';
  cities: City[];
  places: Place[] = [];
  showPlaces: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private rest: RestService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      city_id: new FormControl(null),
      places: new FormControl(null),
      emission: new FormControl(null)
    });

    this.rest.citiesList().subscribe(res => {
      this.cities = res.data.cities;
      this.cities.unshift(new City(null, "Tutte le città"));
    }, error => {
    });
  }

  get f() {
    return this.form.controls;
  }

  onCityChange() {
    this.f.places.setValue(null);
    var city_id = this.f.city_id.value;

    if (city_id) {
      this.showPlaces = true;
      this.rest.placesCitiesList(city_id).subscribe(res => {
        this.places = res.data.places;
      });
    } else {
      this.showPlaces = false;
      this.places = [];
    }
  }

  apply() {
    var start_date = moment(this.f.start_date.value).isValid() ? moment(this.f.start_date.value).unix() : null;
    var end_date = moment(this.f.end_date.value).isValid() ? moment(this.f.end_date.value).unix() : null;
    var emission = this.f.emission.value;
    var city_id = this.f.city_id.value;
    var places = this.f.places.value;

    this.activeModal.close({ start_date, end_date, city_id, places, emission })
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
