import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Base } from 'src/app/models/base';
import { ManagersResponse } from 'src/app/models/response/managers-response';
import { CitiesResponse } from 'src/app/models/response/cities-response';
import { PlacesResponse } from 'src/app/models/response/places-response';
import { AwardsResponse } from 'src/app/models/response/awards-response';
import { UsersResponse } from 'src/app/models/response/users-response';
import { UserResponse } from 'src/app/models/response/user-response';
import { NotificationsResponse } from 'src/app/models/response/notifications-response';
import { EventsResponse } from 'src/app/models/response/events-response';
import { EventResponse } from 'src/app/models/response/event-response';
import { PeripheralsResponse } from 'src/app/models/response/peripherals-response';
import { PlaceResponse } from 'src/app/models/response/place-response';
import { SponsorsResponse } from 'src/app/models/response/sponsors-response';
import { SponsorshipsResponse } from 'src/app/models/response/sponsorships-response';
import { SensorsResponse } from 'src/app/models/response/sensors-response';
import { BannersTvResponse } from 'src/app/models/response/banners-tv-response';
import * as moment from 'moment';
import { CityResponse } from 'src/app/models/response/city-response';
import { ConcessionsResponse } from 'src/app/models/response/concessions-response';
import { EventAddResponse } from 'src/app/models/response/event-add-response';
import { DashboardResponse } from 'src/app/models/response/dashboard-response';
import { MunicipalitiesResponse } from 'src/app/models/response/municipalities-response';
import { NationsResponse } from 'src/app/models/response/nations-response';
import { Pagination } from 'src/app/models/pagination';
import { DocumentResponse } from 'src/app/models/response/document-response';
import { DocumentCitiesResponse } from 'src/app/models/response/document-cities-response';
import { GroupsResponse } from 'src/app/models/response/groups-response';
import { GroupMembersResponse } from 'src/app/models/response/group-members-response';
import { LanguagesResponse } from 'src/app/models/response/languages-response';
import { TalentPaymentsResponse } from 'src/app/models/response/talent-payments-response';
import { AdmonitionsResponse } from 'src/app/models/response/admnitions-response';
import { AbsencesResponse } from 'src/app/models/response/absences-response';
import { EventsTalentsPerformResponse } from 'src/app/models/response/events-talents-perform-response';
import { CitySlot } from 'src/app/models/city';
import { CitySlotsResponse } from 'src/app/models/response/city-slots-response';
import { CitySlotResponse } from 'src/app/models/response/city-slot-response';
import { DocumentOperationsResponse } from 'src/app/models/response/document-operations-response';
import { ProfessionsResponse } from 'src/app/models/response/professions-response';

@Injectable({ providedIn: 'root' })
export class RestService {
  constructor(private http: HttpClient) { }

  //********************** COMUNI */
  municipalitiesList() {
    return this.http.post<MunicipalitiesResponse>(`${environment.APIweb}/municipalities/list`, {});
  }

  //********************** NAZIONI */
  nationsList() {
    return this.http.post<NationsResponse>(`${environment.APIweb}/nations/list`, {});
  }

  //********************** CITTA' */
  citiesList() {
    return this.http.post<CitiesResponse>(`${environment.APIweb}/cities/list`, {});
  }

  citiesMeList() {
    return this.http.post<CitiesResponse>(`${environment.APIweb}/cities/me/list`, {});
  }

  cityDetail(city_id) {
    return this.http.post<CityResponse>(`${environment.APIweb}/cities/detail`, { city_id });
  }

  cityAdd(bodyData) {
    return this.http.post<Base>(`${environment.APIweb}/cities/add`, bodyData);
  }

  cityEdit(bodyData) {
    return this.http.post<Base>(`${environment.APIweb}/cities/mod`, bodyData);
  }

  cityDelete(city_id) {
    return this.http.post<Base>(`${environment.APIweb}/cities/del`, { city_id });
  }

  cityReport(city_id, month, year) {
    return this.http.post<Base>(`${environment.APIweb}/cities/report/month`, { city_id, month, year });
  }

  //********************** POLICY */

  cityPolicyAdd(city_id, name, url) {
    return this.http.post<Base>(`${environment.APIweb}/cities/policies/add`, { city_id, name, url });
  }

  cityPolicyDelete(policy_id) {
    return this.http.post<Base>(`${environment.APIweb}/cities/policies/delete`, { policy_id });
  }

  //********************** AREA */

  cityAreaAdd(city_id, name, hex_color, events_per_day, events_per_month, premium) {
    return this.http.post<Base>(`${environment.APIweb}/cities/areas/add`, { city_id, name, hex_color, events_per_day, events_per_month, premium });
  }

  cityAreaEdit(area_id, name, hex_color, events_per_day, events_per_month, premium) {
    return this.http.post<Base>(`${environment.APIweb}/cities/areas/mod`, { area_id, name, hex_color, events_per_day, events_per_month, premium });
  }

  cityAreaDelete(area_id) {
    return this.http.post<Base>(`${environment.APIweb}/cities/areas/del`, { area_id });
  }

  //********************** SLOTS */

  citySlotsList(city_id) {
    return this.http.post<CitySlotsResponse>(`${environment.APIweb}/cities/slots/list`, { city_id });
  }

  citySlotAdd(city_id, start_time, end_time, whole_day) {
    return this.http.post<Base>(`${environment.APIweb}/cities/slots/add`, { city_id, start_time, end_time, whole_day });
  }

  citySlotDelete(slot_id) {
    return this.http.post<Base>(`${environment.APIweb}/cities/slots/delete`, { slot_id });
  }

  //********************** PROFESSIONS */
  professionsList(type, is_group, city_id) {
    return this.http.post<ProfessionsResponse>(`${environment.APIweb}/professions/list`, { type, is_group, city_id });
  }

  professionsAllList() {
    return this.http.post<ProfessionsResponse>(`${environment.APIweb}/professions/all/list`, {});
  }

  //********************** LUOGHI */
  placesList() {
    return this.http.post<PlacesResponse>(`${environment.APIweb}/places/list`, {});
  }

  placesCitiesList(city_id) {
    return this.http.post<PlacesResponse>(`${environment.APIweb}/places/cities/list`, { city_id });
  }

  placesLiteList() {
    return this.http.post<PlacesResponse>(`${environment.APIweb}/places/lite/list`, {});
  }

  placeAdd(bodyData) {
    return this.http.post<Base>(`${environment.APIweb}/places/add`, bodyData);
  }

  placeEdit(bodyData) {
    return this.http.post<Base>(`${environment.APIweb}/places/mod`, bodyData);
  }

  placeDelete(place_id) {
    return this.http.post<Base>(`${environment.APIweb}/places/del`, { place_id });
  }

  placeDetail(place_id) {
    return this.http.post<PlaceResponse>(`${environment.APIweb}/places/detail`, { place_id });
  }

  //********************** LUOGHI DATE E SLOT */

  placeDateAdd(places, start_date, end_date, times: CitySlot[], weekdays: []) {
    return this.http.post<Base>(`${environment.APIweb}/places/dates/add`, { places, start_date, end_date, times, weekdays });
  }

  placeDatesSlotsDelete(place_id, start_date, end_date, times: CitySlot[], weekdays: []) {
    return this.http.post<Base>(`${environment.APIweb}/places/dates/delete/custom`, { place_id, start_date, end_date, times, weekdays });
  }

  placeDateDelete(place_id, dates: string[]) {
    return this.http.post<Base>(`${environment.APIweb}/places/dates/delete`, { place_id, dates });
  }

  placeSlotAdd(place_id, date, start_time, end_time, whole_day) {
    return this.http.post<CitySlotResponse>(`${environment.APIweb}/places/slots/add`, { place_id, date, start_time, end_time, whole_day });
  }

  placeSlotDelete(slot_id) {
    return this.http.post<Base>(`${environment.APIweb}/places/slots/delete`, { slot_id });
  }

  //********************** MANAGERS */
  managersList() {
    return this.http.post<ManagersResponse>(`${environment.APIweb}/managers/list`, {});
  }

  managerAdd(name, surname, email, password, cities, places, role_id, notify_email_enabled) {
    return this.http.post<Base>(`${environment.APIweb}/managers/add`, { name, surname, email, password, cities, places, role_id, notify_email_enabled });
  }

  managerEdit(manager_id, name, surname, email, password, cities, places, role_id, notify_email_enabled) {
    return this.http.post<Base>(`${environment.APIweb}/managers/mod`, { manager_id, name, surname, email, password, cities, places, role_id, notify_email_enabled });
  }

  managerDelete(manager_id) {
    return this.http.post<Base>(`${environment.APIweb}/managers/del`, { manager_id });
  }

  //********************** AWARDS */
  awardsList() {
    return this.http.post<AwardsResponse>(`${environment.APIweb}/awards/list`, {});
  }

  awardAdd(bodyData) {
    return this.http.post<Base>(`${environment.APIweb}/awards/add`, bodyData);
  }

  awardEdit(bodyData) {
    return this.http.post<Base>(`${environment.APIweb}/awards/mod`, bodyData);
  }

  awardDelete(award_id) {
    return this.http.post<Base>(`${environment.APIweb}/awards/del`, { award_id });
  }

  //********************** USERS */
  usersList(pagination: Pagination) {
    return this.http.post<UsersResponse>(`${environment.APIweb}/users/list`, {
      search: pagination.search, start: pagination.start, length: pagination.length,
      sort_field: pagination.sort_field, sort_type: pagination.sort_type
    });
  }

  userDetail(user_id) {
    return this.http.post<UserResponse>(`${environment.APIweb}/users/detail`, { user_id });
  }

  userEdit(bodyData) {
    return this.http.post<Base>(`${environment.APIweb}/users/mod`, bodyData);
  }

  userEmailEdit(bodyData) {
    return this.http.post<Base>(`${environment.APIweb}/users/email/mod`, bodyData);
  }

  userNotesEdit(user_id, notes) {
    return this.http.post<Base>(`${environment.APIweb}/users/notes/mod`, { user_id, notes });
  }

  userDelete(user_id) {
    return this.http.post<Base>(`${environment.APIweb}/users/delete`, { user_id });
  }

  //********************** NOTIFICHE */
  notificationsList() {
    return this.http.post<NotificationsResponse>(`${environment.APIweb}/notifications/list`, {});
  }

  notificationSend(title, message, type, city_id, users_types) {
    return this.http.post<Base>(`${environment.APIweb}/notifications/add`, { title, message, type, city_id, users_types });
  }

  //********************** EVENTI */
  eventsList(pagination: Pagination, cities: []) {
    return this.http.post<EventsResponse>(`${environment.APIweb}/events/list`, {
      search: pagination.search, start: pagination.start, length: pagination.length, sort_field: pagination.sort_field, sort_type: pagination.sort_type, cities: cities
    });
  }

  eventsPastList(pagination: Pagination) {
    return this.http.post<EventsResponse>(`${environment.APIweb}/events/past/list`, {
      search: pagination.search, start: pagination.start, length: pagination.length, sort_field: pagination.sort_field, sort_type: pagination.sort_type
    });
  }

  eventDetail(event_id) {
    return this.http.post<EventResponse>(`${environment.APIweb}/events/detail`, { event_id });
  }

  eventAdd(bodyData) {
    return this.http.post<EventAddResponse>(`${environment.APIweb}/events/add`, bodyData);
  }

  eventEdit(bodyData) {
    return this.http.post<Base>(`${environment.APIweb}/events/mod`, bodyData);
  }

  eventDelete(event_id) {
    return this.http.post<EventResponse>(`${environment.APIweb}/events/del`, { event_id });
  }

  eventsUsersList(event_id) {
    return this.http.post<UsersResponse>(`${environment.APIweb}/events/users/list`, { event_id });
  }

  eventsUserStatusSet(event_id, user_id, status) {
    return this.http.post<Base>(`${environment.APIweb}/events/user/status/set`, { event_id, user_id, status });
  }

  eventsUserDelete(bodyData) {
    return this.http.post<Base>(`${environment.APIweb}/events/user/del`, bodyData);
  }

  eventsTalentsList(event_id) {
    return this.http.post<UsersResponse>(`${environment.APIweb}/events/talents/list`, { event_id });
  }

  eventsTalentStatusSet(event_id, user_id, status, stars) {
    return this.http.post<Base>(`${environment.APIweb}/events/talent/status/set`, { event_id, user_id, status, stars });
  }

  eventsTalentsAvailableList(event_id) {
    return this.http.post<UsersResponse>(`${environment.APIweb}/events/talents/available`, { event_id });
  }

  eventsTalentInvite(event_id, user_id) {
    return this.http.post<Base>(`${environment.APIweb}/events/talent/invite`, { event_id, user_id });
  }

  eventsTalentRate(bodyData) {
    return this.http.post<Base>(`${environment.APIweb}/events/talent/rate`, bodyData);
  }

  eventsTalentDelete(bodyData) {
    return this.http.post<Base>(`${environment.APIweb}/events/talent/del`, bodyData);
  }

  //********************** DOWNLOAD */
  downloadFile(res: any, filename: string, format: string) {
    var name = filename.replace(/[\W_]+/g, "_").toLowerCase();
    var date = moment().format("DDMMYYYY_HHmmss")
    var filename = name + "_" + date + "." + format;

    let dataType = res.type;
    let binaryData = [];
    binaryData.push(res);
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
    downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  //********************** CLASSIFICA */
  rankingsList() {
    return this.http.post<UsersResponse>(`${environment.APIweb}/ranking/list`, {});
  }

  //********************** PERIFERICHE SCLAK */
  peripheralsList() {
    return this.http.post<PeripheralsResponse>(`${environment.APIweb}/sclak/peripherals/list`, {});
  }

  //********************** SPONSORS */
  sponsorsList() {
    return this.http.post<SponsorsResponse>(`${environment.APIweb}/sponsors/list`, {});
  }

  sponsorAdd(bodyData) {
    return this.http.post<Base>(`${environment.APIweb}/sponsors/add`, bodyData);
  }

  sponsorEdit(bodyData) {
    return this.http.post<Base>(`${environment.APIweb}/sponsors/mod`, bodyData);
  }

  sponsorDelete(sponsor_id) {
    return this.http.post<Base>(`${environment.APIweb}/sponsors/del`, { sponsor_id });
  }

  //********************** SPONSORSHIPS */
  sponsorshipsList() {
    return this.http.post<SponsorshipsResponse>(`${environment.APIweb}/sponsorships/list`, {});
  }

  sponsorshipsAdd(bodyData) {
    return this.http.post<Base>(`${environment.APIweb}/sponsorships/add`, bodyData);
  }

  sponsorshipsEdit(bodyData) {
    return this.http.post<Base>(`${environment.APIweb}/sponsorships/mod`, bodyData);
  }

  sponsorshipsDelete(sponsorship_id) {
    return this.http.post<Base>(`${environment.APIweb}/sponsorships/del`, { sponsorship_id });
  }

  //********************** SENSORS */
  sensorsGmoveList() {
    return this.http.post<SensorsResponse>(`${environment.APIweb}/sensors/gmove/list`, {});
  }

  //********************** BANNERS TV */
  bannersTvList() {
    return this.http.post<BannersTvResponse>(`${environment.APIweb}/banners_tv/list`, {});
  }

  bannersTvAdd(bodyData) {
    return this.http.post<Base>(`${environment.APIweb}/banners_tv/add`, bodyData);
  }

  bannersTvEdit(bodyData) {
    return this.http.post<Base>(`${environment.APIweb}/banners_tv/mod`, bodyData);
  }

  bannersTvDelete(banner_tv_id) {
    return this.http.post<Base>(`${environment.APIweb}/banners_tv/del`, { banner_tv_id });
  }

  //********************** ABSENCE */
  absencesList(user_id) {
    return this.http.post<AbsencesResponse>(`${environment.APIweb}/absences/list`, { user_id: user_id });
  }

  absencesJustifySet(user_id, event_id, justified_absence) {
    // "null" ancora da valutara, 0 non giustificato, 1 giustificato
    return this.http.post<Base>(`${environment.APIweb}/absences/justify/set`, { user_id, event_id, justified_absence });
  }

  absencesAdmonitionSet(user_id, event_id) {
    return this.http.post<Base>(`${environment.APIweb}/absences/admonition/set`, { user_id, event_id });
  }

  //********************** AMMONIZIONE TALENT */
  admonistionsList(user_id) {
    return this.http.post<AdmonitionsResponse>(`${environment.APIweb}/admonitions/list`, { user_id: user_id });
  }

  admonitionAdd(user_id, event_id, notes) {
    return this.http.post<Base>(`${environment.APIweb}/admonitions/add`, { user_id, event_id, notes });
  }

  admonitionEdit(admonition_id, notes) {
    return this.http.post<Base>(`${environment.APIweb}/admonitions/edit`, { admonition_id, notes });
  }

  //********************** MUSICAL GENRES */
  musicalGenresList() {
    return this.http.post<Base>(`${environment.APIweb}/musical_genres/list`, {});
  }

  musicalGenreAdd(name) {
    return this.http.post<Base>(`${environment.APIweb}/musical_genres/add`, { name });
  }

  musicalGenreEdit(genre_id, name) {
    return this.http.post<Base>(`${environment.APIweb}/musical_genres/edit`, { genre_id, name });
  }

  musicalGenreDelete(genre_id) {
    return this.http.post<Base>(`${environment.APIweb}/musical_genres/delete`, { genre_id });
  }

  //********************** CONCESSIONS */
  concessionsList(user_id) {
    return this.http.post<ConcessionsResponse>(`${environment.APIweb}/concessions/user/list`, { user_id: user_id });
  }

  concessionsApprove(concession_id) {
    return this.http.post<ConcessionsResponse>(`${environment.APIweb}/concessions/approve`, { concession_id });
  }

  concessionsRefuse(concession_id) {
    return this.http.post<ConcessionsResponse>(`${environment.APIweb}/concessions/refuse`, { concession_id });
  }

  concessionsActivate(concession_id) {
    return this.http.post<ConcessionsResponse>(`${environment.APIweb}/concessions/activate`, { concession_id });
  }

  generateConcessionDocx(concession_id) {
    return this.http.post<any>(`${environment.APIweb}/concessions/docx`, { concession_id }, { responseType: 'blob' as 'json' });
  }

  //********************** TALENTS */
  talentsList(pagination: Pagination) {
    return this.http.post<UsersResponse>(`${environment.APIweb}/talents/list`, {
      search: pagination.search, start: pagination.start, length: pagination.length, sort_field: pagination.sort_field, sort_type: pagination.sort_type
    });
  }

  talentAdd(bodyData) {
    return this.http.post<Base>(`${environment.APIweb}/talents/add`, bodyData);
  }

  //********************** ROSTER */

  rosterList(pagination: Pagination, cities: [], stars: []) {
    return this.http.post<UsersResponse>(`${environment.APIweb}/roster/list`, {
      search: pagination.search, start: pagination.start, length: pagination.length, sort_field: pagination.sort_field, sort_type: pagination.sort_type, cities: cities, stars: stars
    });
  }

  //********************** DASHBOARD */
  dashboardInfo() {
    return this.http.post<DashboardResponse>(`${environment.APIweb}/dashboard/info`, {});
  }

  //********************** EXPORTS */
  logsExport(start_date, end_date) {
    return this.http.post<any>(`${environment.APIweb}/logs/export`, { start_date, end_date }, { responseType: 'blob' as 'json' });
  }
  usersExport(pagination: Pagination) {
    return this.http.post<any>(`${environment.APIweb}/users/export`, { search: pagination.search, sort_field: pagination.sort_field, sort_type: pagination.sort_type }, { responseType: 'blob' as 'json' });
  }
  talentsExport(pagination: Pagination) {
    return this.http.post<any>(`${environment.APIweb}/talents/export`, { search: pagination.search, sort_field: pagination.sort_field, sort_type: pagination.sort_type }, { responseType: 'blob' as 'json' });
  }
  rosterExport(pagination: Pagination, cities, stars) {
    return this.http.post<any>(`${environment.APIweb}/roster/export`, { search: pagination.search, sort_field: pagination.sort_field, sort_type: pagination.sort_type, cities, stars }, { responseType: 'blob' as 'json' });
  }
  eventsExport(start_date, end_date, city_id, places, emission, pagination: Pagination) {
    return this.http.post<any>(`${environment.APIweb}/events/export`, {
      start_date, end_date, city_id, places, emission, search: pagination.search, sort_field: pagination.sort_field, sort_type: pagination.sort_type
    }, { responseType: 'blob' as 'json' });
  }
  // eventsPastExport(start_date, end_date, pagination: Pagination) {
  //   return this.http.post<any>(`${environment.APIweb}/events/past/export`, { start_date, end_date, search: pagination.search, sort_field: pagination.sort_field, sort_type: pagination.sort_type }, { responseType: 'blob' as 'json' });
  // }
  eventsTalentsExportCSV(event_id) {
    return this.http.post<any>(`${environment.APIweb}/events/talents/export`, { event_id }, { responseType: 'blob' as 'json' });
  }
  eventsTodayExportExcel() {
    return this.http.post<any>(`${environment.APIweb}/events/today/export`, {}, { responseType: 'blob' as 'json' });
  }
  placesExportExcel() {
    return this.http.post<any>(`${environment.APIweb}/places/export`, {}, { responseType: 'blob' as 'json' });
  }
  groupsExport(pagination: Pagination) {
    return this.http.post<any>(`${environment.APIweb}/groups/export`, { search: pagination.search, sort_field: pagination.sort_field, sort_type: pagination.sort_type }, { responseType: 'blob' as 'json' });
  }

  //********************** DOCUMENTS */
  documentsDetail(user_id, city_id) {
    return this.http.post<DocumentResponse>(`${environment.APIweb}/documents/detail`, { user_id, city_id });
  }

  documentEdit(bodyData) {
    return this.http.post<Base>(`${environment.APIweb}/documents/edit`, bodyData);
  }
  documentDelete(user_id, city_id) {
    return this.http.post<Base>(`${environment.APIweb}/documents/delete`, { user_id, city_id });
  }

  documentCitiesList(user_id) {
    return this.http.post<DocumentCitiesResponse>(`${environment.APIweb}/documents/cities/list`, { user_id });
  }

  //********************** DOCUMENTS OPERATIONS */
  documentOperationsList(user_id, city_id) {
    return this.http.post<DocumentOperationsResponse>(`${environment.APIweb}/documents/operations/list`, { user_id, city_id });
  }

  //********************** GRUPPI */
  groupsList(pagination: Pagination) {
    return this.http.post<GroupsResponse>(`${environment.APIweb}/groups/list`, {
      search: pagination.search, start: pagination.start, length: pagination.length,
      sort_field: pagination.sort_field, sort_type: pagination.sort_type
    });
  }

  groupEdit(bodyData) {
    return this.http.post<Base>(`${environment.APIweb}/groups/edit`, bodyData);
  }

  groupDetail(group_id) {
    return this.http.post<UserResponse>(`${environment.APIweb}/groups/detail`, { group_id });
  }

  groupMemberAdd(group_id, user_id) {
    return this.http.post<Base>(`${environment.APIweb}/groups/members/add`, { group_id, user_id });
  }

  groupMemberDelete(group_id, user_id) {
    return this.http.post<Base>(`${environment.APIweb}/groups/members/delete`, { group_id, user_id });
  }

  groupMembersAvailableList(group_id) {
    return this.http.post<GroupMembersResponse>(`${environment.APIweb}/groups/members/available/list`, { group_id });
  }

  //********************** LANGUAGES */
  languagesList() {
    return this.http.post<LanguagesResponse>(`${environment.APIweb}/languages/list`, {});
  }

  //********************** PAYMENTS */

  talentPaymentsList(user_id) {
    return this.http.post<TalentPaymentsResponse>(`${environment.APIweb}/payments/talents/list`, { user_id });
  }
  talentPaymentsAdd(user_id, date, amount, description) {
    return this.http.post<Base>(`${environment.APIweb}/payments/talents/add`, { user_id, date, amount, description });
  }
  talentPaymentsDelete(payment_id) {
    return this.http.post<Base>(`${environment.APIweb}/payments/talents/delete`, { payment_id });
  }
  talentPaymentCallsEdit(user_id, count) {
    return this.http.post<Base>(`${environment.APIweb}/payments/callforartists/edit`, { user_id, count });
  }

  //********************** PRESENZE/ASSENZE TALENTI */
  eventsTalentsPerformList(date) {
    return this.http.post<EventsTalentsPerformResponse>(`${environment.APIweb}/events/talents/perform/list`, { date });
  }

}
