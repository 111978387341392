<section class="content-header">
    <div class="container-fluid">
        <h1>Documenti</h1>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">

                <form [formGroup]="form">
                    <div class="card card-info">
                        <div class="card-header text-uppercase">
                            {{city?.city_name}}
                        </div>

                        <div class="card-body">
                            <div class="post">
                                <div *ngIf="city?.document == 1" class="row">
                                    <div class="form-group col-12 text-center">
                                        <span class="badge badge-warning text-uppercase">Informazioni</span>
                                    </div>

                                    <div class="form-group col-12">
                                        <label>Il tuo talento</label>
                                        <div>
                                            <ng-select formControlName="professions" [items]="professions" bindLabel="name" bindValue="profession_id" multiple="true"
                                                placeholder="Scegli uno o più ...." [ngClass]="{ 'is-invalid': submitted && f.professions.errors }">
                                            </ng-select>
                                            <div *ngIf="f.professions.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="document_video_url">Link video esibizione</label>
                                        <div class="input-group" [ngClass]="{ 'is-invalid': submitted && f.document_video_url.errors }">
                                            <input type="text" class="form-control" id="document_video_url" formControlName="document_video_url">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <a href="{{f.document_video_url.value}}" target="_blank"><i class="fab fa-youtube"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="f.document_video_url.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label>Emissioni</label>
                                        <select formControlName="document_emission" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.document_emission.errors }">
                                            <option [ngValue]="3">Nessuna</option>
                                            <option [ngValue]="4">Vocale</option>
                                            <option [ngValue]="0">Bassa</option>
                                            <option [ngValue]="1">Media</option>
                                            <option [ngValue]="2">Alta</option>
                                        </select>
                                        <div *ngIf="f.document_emission.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label>Emissioni secondarie</label>
                                        <select formControlName="document_emission_2" class="form-control">
                                            <option [ngValue]="null">Nessuna</option>
                                            <option [ngValue]="5">Acustica</option>
                                        </select>
                                    </div>

                                    <div *ngIf="f.document_emission_2.value == 5" class="form-group col-12 col-lg-6">
                                        <label for="document_emission_2_description">Descrizione emissioni secondarie</label>
                                        <input type="text" class="form-control" id="document_emission_2_description" formControlName="document_emission_2_description"
                                            [ngClass]="{ 'is-invalid': submitted && f.document_emission_2_description.errors }">
                                        <div *ngIf="f.document_emission_2_description.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                                    </div>

                                    <div class="form-group col-12">
                                        <label>Stato</label>
                                        <select formControlName="document_status" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.document_status.errors }">
                                            <option [ngValue]="null" disabled>Valida concessione</option>
                                            <option [ngValue]="0">In fase di revisione</option>
                                            <option [ngValue]="1">Approvato, regolamenti approvati (3 flag)</option>
                                            <option [ngValue]="2">Iscrizione non accettata</option>
                                        </select>
                                        <div *ngIf="f.document_status.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                                    </div>

                                    <div *ngIf="f.document_status.value == 2" class="form-group col-12">
                                        <label for="document_status_reason">Motivazione</label>
                                        <textarea class="form-control" id="document_status_reason" formControlName="document_status_reason"></textarea>
                                    </div>
                                </div>


                                <div>
                                    <div class="form-group col-12 text-center">
                                        <span class="badge badge-warning text-uppercase">Membri del gruppo</span>
                                    </div>

                                    <div class="table-responsive">
                                        <table id="table_members" datatable [dtOptions]="dtOptionsMembers" [dtTrigger]="dtTriggerMembers" class="table table-striped ">
                                            <thead>
                                                <tr>
                                                    <th style="width: 150px">Foto profilo</th>
                                                    <th>Nome d'arte</th>
                                                    <th>Referente</th>
                                                    <th>Tipologia utente</th>
                                                    <th style="width: 15%"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of members">
                                                    <td class="align-middle">
                                                        <img class="profile-user-img img-fluid img-circle elevation-1" style="width: 50px; height: 50px;"
                                                            src="{{item?.profile_url ? item.profile_url:'assets/img/profile_placeholder.png'}}" alt="User profile picture">
                                                    </td>
                                                    <td class="align-middle">{{item.username}}</td>
                                                    <td [ngSwitch]="item.is_referent">
                                                        <span *ngSwitchCase="1" class="badge badge-pill badge-success text-uppercase">Referente</span>
                                                    </td>
                                                    <td [ngSwitch]="item.document_user_type">
                                                        <span *ngSwitchCase="0" class="badge badge-pill badge-secondary text-uppercase">Solista e membro di un gruppo</span>
                                                        <span *ngSwitchCase="1" class="badge badge-pill badge-secondary text-uppercase">Solista</span>
                                                        <span *ngSwitchCase="2" class="badge badge-pill badge-secondary text-uppercase">Membro di un gruppo</span>
                                                    </td>
                                                    <td class="align-middle text-right">
                                                        <div [ngSwitch]="item.document_status">
                                                            <span *ngSwitchCase="null" class="badge bg-secondary text-uppercase">Città mancante</span>
                                                            <span *ngSwitchCase="0" class="badge bg-warning text-uppercase">Richiesta in fase di revisione</span>
                                                            <span *ngSwitchCase="1" class="badge bg-success text-uppercase">Richiesta approvata</span>
                                                            <span *ngSwitchCase="2" class="badge bg-danger text-uppercase">Richiesta rifiutata</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="(this.city?.document == 1 || this.document == null) && (this.auth.currentUserValue.role_id == 0 || this.auth.currentUserValue.role_id == 2)"
                            class="card-footer text-center">
                            <button type="submit" class="btn btn-info" (click)="save()"> <span *ngIf="saving" class="spinner-border spinner-border-sm mr-2"></span>
                                Salva</button>
                        </div>

                        <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>
                </form>


            </div>

        </div>
    </div>
</section>