import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RestService } from 'src/app/utils/services/rest.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/utils/services/authentication.service';
import { Genre, Place } from 'src/app/models/response/dashboard-response';
import { ModalExportLogComponent } from 'src/app/modals/modal-export-log/modal-export-log.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnDestroy, OnInit {
  isLoading: boolean = true;
  currentYear: number;
  
  users_count: number = 0;
  talents_count: number = 0;
  talents_with_event_count: number = 0;
  events_count: number = 0;
  events_annual_count: number = 0;
  places: [Place];
  genres: [Genre];

  municipality_users_count: number;
  municipality_talents_count: number;
  municipality_talents_with_event_count: number;
  municipality_events_count: number;
  municipality_events_annual_count: number;
  municipality_places: [Place];
  municipality_genres: [Genre];

  constructor(
    private rest: RestService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    public auth: AuthenticationService
  ) {
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit(): void {

    this.dashboardInfo();
  }

  ngOnDestroy(): void { }

  // Dashbord

  dashboardInfo() {
    this.isLoading = true;

    this.rest.dashboardInfo().subscribe(res => {
      this.isLoading = false;

      this.users_count = res.data.users_count;
      this.talents_count = res.data.talents_count;
      this.talents_with_event_count = res.data.talents_with_event_count;
      this.events_count = res.data.events_count;
      this.events_annual_count = res.data.events_annual_count;
      this.places = res.data.places;
      this.genres = res.data.genres;

      this.municipality_users_count = res.data.municipality_users_count;
      this.municipality_talents_count = res.data.municipality_talents_count;
      this.municipality_talents_with_event_count = res.data.municipality_talents_with_event_count;
      this.municipality_events_count = res.data.municipality_events_count;
      this.municipality_events_annual_count = res.data.municipality_events_annual_count;
      this.municipality_places = res.data.municipality_places;
      this.municipality_genres = res.data.municipality_genres;
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  exportLogs() {
    const modalRef = this.modalService.open(ModalExportLogComponent);
    modalRef.result.then((result) => {
      this.rest.logsExport(result.start_date, result.end_date).subscribe(res => {
        $('.export-button').prop('disabled', false);
        $('#export-spinner').css({ display: 'none' });
        this.rest.downloadFile(res, "Log", "xlsx");
      }, error => {
        $('.export-button').prop('disabled', false);
        $('#export-spinner').css({ display: 'none' });
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }, (reason) => { });
  }
}
