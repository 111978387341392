<div class="login-page">

  <div class="login-box">
    <div class="login-logo">
      <img src="assets/img/logo.png" alt="OfficineBuone Logo Large">
    </div>
    <!-- /.login-logo -->
    <div class="card">
      <div class="card-body login-card-body">
        <form [formGroup]="loginForm">
          <div class="input-group mb-3">
            <input formControlName="email" type="email" class="form-control" placeholder="Email"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
            <div *ngIf="f.email.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
            <div *ngIf="f.email.errors?.email" class="invalid-feedback">Indirizzo email non valido</div>
          </div>

          <div class="input-group mb-3">
            <input formControlName="password" type="password" class="form-control" placeholder="Password"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
            <div *ngIf="f.password.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
          </div>

          <div class="row justify-content-center">
            <div class="col-4">
              <button [disabled]="loading" class="btn btn-info btn-block" (click)="login()" type="submit">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-2"></span>
                Login
              </button>
            </div>
          </div>
          <div *ngIf="error" class="text-center alert alert-danger mt-3 mb-0">{{error}}</div>

        </form>
      </div>
      <!-- /.login-card-body -->
    </div>
  </div>
</div>