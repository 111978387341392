<section class="content-header">
    <div class="container-fluid">
        <h1>Documenti</h1>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-3">
                <div class="card card-widget widget-user-2">
                    <div class="widget-user-header bg-warning">
                        <h3 class="widget-user-username ml-0">{{user?.name}} {{user?.surname}}</h3>
                    </div>
                    <div class="card-body">
                        <strong>Codice fiscale</strong>
                        <p class="text-muted">{{user?.tax_code}}</p>
                        <hr>
                        <strong>Data di nascita</strong>
                        <p class="text-muted">{{ user?.birth_date | date:'dd/MM/yyyy' }}</p>
                        <hr>
                        <strong>Città di nascita</strong>
                        <p class="text-muted">{{user?.birth_municipality_name}} - {{user?.birth_nation_name}}</p>
                        <hr>
                        <strong>Indirizzo di residenza</strong>
                        <p class="text-muted">{{user?.residence_address}} - {{user?.residence_city}} -
                            {{user?.nation_name}}</p>
                        <hr>
                        <strong>Telefono</strong>
                        <p class="text-muted mb-0">{{user?.phone}}</p>
                    </div>
                </div>
            </div>

            <div class="col-9">
                <form [formGroup]="form">
                    <div class="card card-info">
                        <div class="card-header text-uppercase">
                            {{city?.city_name}}
                        </div>

                        <div class="card-body">
                            <div class="post">
                                <div class="row">
                                    <div class="form-group col-12 text-center">
                                        <span class="badge badge-warning text-uppercase">Informazioni</span>
                                    </div>

                                    <div class="form-group col-12">
                                        <label>Il tuo talento</label>
                                        <div>
                                            <ng-select formControlName="professions" [items]="professions"
                                                bindLabel="name" bindValue="profession_id" multiple="true"
                                                placeholder="Scegli uno o più ....">
                                            </ng-select>
                                        </div>
                                    </div>

                                    <div *ngIf="user?.type != 2" class="form-group col-12">
                                        <label>Tipologia utente</label>
                                        <select formControlName="document_user_type" class="form-control">
                                            <option [ngValue]="0">Solista e membro di un gruppo</option>
                                            <option [ngValue]="1">Solista</option>
                                            <option [ngValue]="2">Membro di un gruppo</option>
                                        </select>
                                    </div>

                                    <div *ngIf="user?.type != 2 && f.document_user_type.value != 2"
                                        class="form-group col-12 col-lg-6">
                                        <label for="document_video_url">Link video esibizione</label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="document_video_url"
                                                formControlName="document_video_url">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <a [ngClass]="{'disabled': !f.document_video_url.value}"
                                                        href="{{f.document_video_url.value}}" target="_blank"><i
                                                            class="fab fa-youtube"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="user?.type != 2 && f.document_user_type.value != 2"
                                        class="form-group col-12 col-lg-6">
                                        <label>Emissioni</label>
                                        <select formControlName="document_emission" class="form-control">
                                            <option [ngValue]="3">Nessuna</option>
                                            <option [ngValue]="4">Vocale</option>
                                            <option [ngValue]="0">Bassa</option>
                                            <option [ngValue]="1">Media</option>
                                            <option [ngValue]="2">Alta</option>
                                        </select>
                                    </div>

                                    <div *ngIf="user?.type != 2 && f.document_user_type.value != 2"
                                        class="form-group col-12 col-lg-6">
                                        <label>Emissioni secondarie</label>
                                        <select formControlName="document_emission_2" class="form-control">
                                            <option [ngValue]="null">Nessuna</option>
                                            <option [ngValue]="5">Acustica</option>
                                        </select>
                                    </div>

                                    <div *ngIf="user?.type != 2 && f.document_user_type.value != 2 && f.document_emission_2.value == 5"
                                        class="form-group col-12 col-lg-6">
                                        <label for="document_emission_2_description">Descrizione emissioni
                                            secondarie</label>
                                        <input type="text" class="form-control" id="document_emission_2_description"
                                            formControlName="document_emission_2_description"
                                            [ngClass]="{ 'is-invalid': submitted && f.document_emission_2_description.errors }">
                                        <div *ngIf="f.document_emission_2_description.errors?.required"
                                            class="invalid-feedback">Campo obbligatorio</div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-12 text-center">
                                        <span class="badge badge-warning text-uppercase">Documenti</span>
                                    </div>

                                    <div class="form-group col-12 col-lg-6 text-center mb-3">
                                        <div>
                                            <label for="document_front">Documento d'identità (fronte)</label>
                                        </div>

                                        <div style="width:250px; height:250px; margin: 0 auto;">
                                            <ngx-img-zoom id="document_front"
                                                [zoomImageSrc]="document?.document_front_url ? document.document_front_url : 'assets/img/profile_placeholder.png'"
                                                [previewImageSrc]="document?.document_front_url ? document.document_front_url : 'assets/img/profile_placeholder.png'"
                                                [imgStyle]="'width:250px; height:250px; object-fit:contain; border: 3px solid #adb5bd;margin: 0 auto; max-width: 100%; padding: 3px;'"
                                                [resultStyle]="'width:250px; height:250px; background-repeat: no-repeat; z-index: 2; position:absolute; top: 0;left: 100%;'"
                                                [enableZoom]="true" [containerStyle]=""></ngx-img-zoom>
                                        </div>

                                        <div>
                                            <div *ngIf="fileDocumentFront"><small>Dimensione: {{fileDocumentFront?.size
                                                    / 1024 / 1024 | number : '1.2-2'}} MB</small></div>
                                            <small>L'immagine non deve superare 2MB</small>
                                        </div>

                                        <div>
                                            <a class="btn btn-info mt-2"
                                                href="{{document?.document_front_url ? document.document_front_url : 'assets/img/profile_placeholder.png'}}"
                                                target="_blank">
                                                Scarica
                                            </a>
                                            <input #img_front hidden="true" type="file" onclick="this.value=null"
                                                (change)="handleFileInputDocumentFront($event.target.files)"
                                                accept=".jpg" />
                                            <button type="button" class="btn btn-info mt-2"
                                                (click)="img_front.click()">Scegli immagine</button>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6 text-center mb-3">
                                        <div>
                                            <label for="document_back">Documento d'identità (retro)</label>
                                        </div>

                                        <div style="width:250px; height:250px; margin: 0 auto;">
                                            <ngx-img-zoom id="document_back"
                                                [zoomImageSrc]="document?.document_back_url ? document.document_back_url : 'assets/img/profile_placeholder.png'"
                                                [previewImageSrc]="document?.document_back_url ? document.document_back_url : 'assets/img/profile_placeholder.png'"
                                                [imgStyle]="'width:250px; height:250px; object-fit:contain; border: 3px solid #adb5bd; margin: 0 auto; max-width: 100%; padding: 3px;'"
                                                [resultStyle]="'width:250px; height:250px; background-repeat: no-repeat; z-index: 2; position:absolute; top: 0;right: 100%;'"
                                                [enableZoom]="true" [containerStyle]=""></ngx-img-zoom>
                                        </div>

                                        <div>
                                            <div *ngIf="fileDocumentBack"><small>Dimensione: {{fileDocumentBack?.size
                                                    / 1024 / 1024 | number : '1.2-2'}} MB</small></div>
                                            <small>L'immagine non deve superare 2MB</small>
                                        </div>

                                        <a class="btn btn-info mt-2"
                                            href="{{document?.document_back_url ? document.document_back_url : 'assets/img/profile_placeholder.png'}}"
                                            target="_blank">
                                            Scarica
                                        </a>
                                        <input #img_back hidden="true" type="file" onclick="this.value=null"
                                            (change)="handleFileInputDocumentBack($event.target.files)" accept=".jpg" />
                                        <button type="button" class="btn btn-info mt-2"
                                            (click)="img_back.click()">Scegli immagine</button>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label>Tipologia documento</label>
                                        <select formControlName="document_type" class="form-control">
                                            <option [ngValue]="null" disabled>Tipologia documento</option>
                                            <option [ngValue]="0">Carta di identità</option>
                                            <option [ngValue]="1">Patente</option>
                                            <option [ngValue]="2">Passaporto</option>
                                        </select>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="document_number">Numero documento</label>
                                        <input type="text" class="form-control" id="document_number"
                                            formControlName="document_number">
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="document_start_date">Data di rilascio documento</label>
                                        <input [owlDateTime]="dt_document_start_date"
                                            [owlDateTimeTrigger]="dt_document_start_date" type="text"
                                            class="form-control" id="document_start_date"
                                            formControlName="document_start_date" readonly="readonly">
                                        <owl-date-time #dt_document_start_date
                                            [pickerType]="'calendar'"></owl-date-time>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="document_end_date">Data di scadenza documento</label>
                                        <input [owlDateTime]="dt_document_end_date"
                                            [owlDateTimeTrigger]="dt_document_end_date" type="text" class="form-control"
                                            id="document_end_date" formControlName="document_end_date"
                                            readonly="readonly">
                                        <owl-date-time #dt_document_end_date [pickerType]="'calendar'"></owl-date-time>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-12">
                                        <label>Extracomunitario</label>
                                        <select formControlName="document_permit_type" class="form-control">
                                            <option [ngValue]="null" disabled>Extracomunitario</option>
                                            <option [ngValue]="0">Non sono extracomunitario</option>
                                            <option [ngValue]="1">Permesso di soggiorno</option>
                                            <option [ngValue]="2">Richiesta di permesso di soggiorno</option>
                                            <option [ngValue]="3">Visto</option>
                                        </select>
                                    </div>

                                    <div *ngIf="this.f.document_permit_type.value"
                                        class="form-group col-12 text-center mb-3">
                                        <div>
                                            <label for="document_permit_url">Permesso di soggiorno / Visto</label>
                                        </div>

                                        <div style="width:250px; height:250px; margin: 0 auto;">
                                            <ngx-img-zoom id="document_permit"
                                                [zoomImageSrc]="document?.document_permit_url ? document.document_permit_url : 'assets/img/profile_placeholder.png'"
                                                [previewImageSrc]="document?.document_permit_url ? document.document_permit_url : 'assets/img/profile_placeholder.png'"
                                                [imgStyle]="'width:250px; height:250px; object-fit:contain; border: 3px solid #adb5bd;margin: 0 auto; max-width: 100%; padding: 3px;'"
                                                [resultStyle]="'width:250px; height:250px; background-repeat: no-repeat; z-index: 2; position:absolute; top: 0;right: 100%;'"
                                                [enableZoom]="true" [containerStyle]=""></ngx-img-zoom>
                                        </div>

                                        <div>
                                            <div *ngIf="fileDocumentPermit"><small>Dimensione:
                                                    {{fileDocumentPermit?.size
                                                    / 1024 / 1024 | number : '1.2-2'}} MB</small></div>
                                            <small>L'immagine non deve superare 2MB</small>
                                        </div>

                                        <a class="btn btn-info mt-2"
                                            href="{{document?.document_permit_url ? document.document_permit_url : 'assets/img/profile_placeholder.png'}}"
                                            target="_blank">
                                            Scarica
                                        </a>
                                        <input #img_permit hidden="true" type="file" onclick="this.value=null"
                                            (change)="handleFileInputDocumentPermit($event.target.files)"
                                            accept=".jpg" />
                                        <button type="button" class="btn btn-info mt-2"
                                            (click)="img_permit.click()">Scegli immagine</button>
                                    </div>

                                    <div *ngIf="this.f.document_permit_type.value" class="form-group col-12 col-lg-6">
                                        <label for="document_permit_number">Numero documento</label>
                                        <input type="text" class="form-control" id="document_permit_number"
                                            formControlName="document_permit_number"
                                            [ngClass]="{ 'is-invalid': submitted && f.document_permit_number.errors }">
                                        <div *ngIf="f.document_permit_number.errors?.required" class="invalid-feedback">
                                            Campo obbligatorio</div>
                                    </div>

                                    <div *ngIf="this.f.document_permit_type.value" class="form-group col-12 col-lg-6">
                                        <label for="document_permit_nationality">Cittadinanza</label>
                                        <input type="text" class="form-control" id="document_permit_nationality"
                                            formControlName="document_permit_nationality"
                                            [ngClass]="{ 'is-invalid': submitted && f.document_permit_nationality.errors }">
                                        <div *ngIf="f.document_permit_nationality.errors?.required"
                                            class="invalid-feedback">Campo obbligatorio</div>
                                    </div>

                                    <div *ngIf="this.f.document_permit_type.value" class="form-group col-12 col-lg-6">
                                        <label for="document_permit_start_date">Data di rilascio documento</label>
                                        <input [owlDateTime]="dt_document_permit_start_date"
                                            [owlDateTimeTrigger]="dt_document_permit_start_date" type="text"
                                            class="form-control" id="document_permit_start_date"
                                            formControlName="document_permit_start_date" readonly="readonly">
                                        <owl-date-time #dt_document_permit_start_date
                                            [pickerType]="'calendar'"></owl-date-time>
                                    </div>

                                    <div *ngIf="this.f.document_permit_type.value" class="form-group col-12 col-lg-6">
                                        <label for="document_permit_end_date">Data di scadenza documento</label>
                                        <input [owlDateTime]="dt_document_permit_end_date"
                                            [owlDateTimeTrigger]="dt_document_permit_end_date" type="text"
                                            class="form-control" id="document_permit_end_date"
                                            formControlName="document_permit_end_date" readonly="readonly">
                                        <owl-date-time #dt_document_permit_end_date
                                            [pickerType]="'calendar'"></owl-date-time>
                                    </div>

                                    <div class="form-group col-12">
                                        <label>Stato</label>
                                        <select formControlName="document_status" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.document_status.errors }">
                                            <option [ngValue]="null" disabled>Valida documento</option>
                                            <option [ngValue]="0">In fase di revisione</option>
                                            <option [ngValue]="1">Approvato, regolamenti approvati (3 flag)</option>
                                            <option [ngValue]="2">Iscrizione non accettata</option>
                                        </select>
                                    </div>

                                    <div *ngIf="f.document_status.value == 2" class="form-group col-12">
                                        <label for="document_status_reason">Motivazione</label>
                                        <textarea class="form-control" id="document_status_reason"
                                            formControlName="document_status_reason"></textarea>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div *ngIf="this.auth.currentUserValue.role_id == 0 || this.auth.currentUserValue.role_id == 2"
                            class="card-footer text-center">
                            <button type="submit" class="btn btn-info" (click)="save()"> <span *ngIf="saving"
                                    class="spinner-border spinner-border-sm mr-2"></span>
                                Salva</button>
                        </div>

                        <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="row m-0">
            <div class="col-12">
                <div class="card card-info mt-3 collapsed-card">
                    <div class="card-header" data-card-widget="collapse">
                        <span class="card-title text-uppercase">
                            Storico operazioni
                        </span>
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i
                                    class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>

                    <div class="card-body table-responsive">
                        <table id="table_operations" datatable [dtOptions]="dtOptionsOperations"
                            [dtTrigger]="dtTriggerOperations" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Data</th>
                                    <th>Stato</th>
                                    <th>Motivazione</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of operations">
                                    <td>{{item.creation_date * 1000 | date :'dd/MM/yyyy HH:mm'}}</td>
                                    <td>
                                        <ng-container [ngSwitch]="item.status">
                                            <span *ngSwitchCase="0">In fase di revisione</span>
                                            <span *ngSwitchCase="1">Approvato, regolamenti approvati (3 flag)</span>
                                            <span *ngSwitchCase="2">Iscrizione non accettata</span>
                                            <span *ngSwitchDefault>Validazione documento</span>
                                        </ng-container>
                                    </td>
                                    <td>{{item.reason}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoadingOperations" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>