<section class="content-header">
    <div class="container-fluid">
        <h1>Scheda Utente</h1>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row m-0">
            <div *ngIf="id" class="col-3">
                <div class="card card-widget widget-user">
                    <div class="widget-user-header bg-warning">
                        <h3 class="widget-user-username">{{user?.name}} {{user?.surname}}</h3>
                        <h5 class="widget-user-desc mb-0">{{user?.username}}</h5>
                        <small class="widget-user-desc">{{user?.email}}</small>
                    </div>
                    <div class="widget-user-image">
                        <img class="img-circle elevation-2"
                            src="{{user?.profile_url ? user.profile_url:'assets/img/profile_placeholder.png'}}"
                            alt="User profile picture">
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-sm-6 border-right">
                                <div class="description-block">
                                    <h5 class="description-header">{{user?.creation_date | date :'dd/MM/yyyy HH:mm'}}
                                    </h5>
                                    <span class="description-text">Data registrazione</span>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="description-block">
                                    <h5 class="description-header">#{{user?.identifier}}</h5>
                                    <span class="description-text">Cod. identificativo</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <form *ngIf="this.auth.isAdmin()" [formGroup]="form_access">
                    <div class="card card-secondary">
                        <div class="card-header text-uppercase">
                            Dati di accesso
                        </div>

                        <div class="card-body">
                            <div class="post">
                                <div class="row">
                                    <div class="form-group col-12">
                                        <label for="email">Email</label>
                                        <input type="text" class="form-control" id="email" formControlName="email"
                                            [ngClass]="{ 'is-invalid': submitted_access && fa.email.errors }">
                                        <div *ngIf="fa.email.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-footer text-center">
                            <button type="submit" class="btn btn-info" (click)="saveAccessData()"> <span
                                    *ngIf="loading_access" class="spinner-border spinner-border-sm mr-2"></span>
                                Salva</button>
                        </div>
                    </div>
                </form>

                <form *ngIf="this.auth.isAdmin() || this.auth.isMunicipality()" [formGroup]="form_additional_info">
                    <div class="card card-secondary">
                        <div class="card-header text-uppercase">
                            Informazioni aggiuntive
                        </div>

                        <div class="card-body">
                            <div class="post">
                                <div class="row">
                                    <div class="form-group col-12">
                                        <label for="notes">Note</label>
                                        <textarea class="form-control" id="notes" formControlName="notes"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-footer text-center">
                            <button type="submit" class="btn btn-info" (click)="saveAdditionalInfo()">Salva</button>
                        </div>
                    </div>
                </form>
            </div>

            <div [class]="id ? 'col-9' : 'col-12'">
                <form [formGroup]="form">
                    <div class="card card-info">
                        <div class="card-header text-uppercase">
                            Profilo
                        </div>

                        <div class="card-body">
                            <div class="post">
                                <div class="row">
                                    <div class="form-group col-12 text-center">
                                        <ui-switch formControlName="enabled"
                                            [disabled]="this.auth.isManager()"></ui-switch>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="name">Nome</label>
                                        <input type="text" class="form-control" id="name" formControlName="name"
                                            [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                        <div *ngIf="f.name.errors?.required" class="invalid-feedback">Campo obbligatorio
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="surname">Cognome</label>
                                        <input type="text" class="form-control" id="surname" formControlName="surname"
                                            [ngClass]="{ 'is-invalid': submitted && f.surname.errors }">
                                        <div *ngIf="f.surname.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                    <div [hidden]="id" class="form-group col-12 col-lg-6">
                                        <label for="email">Email</label>
                                        <input type="text" class="form-control" id="email" formControlName="email"
                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                        <div *ngIf="f.email.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="phone">Telefono</label>
                                        <input type="text" class="form-control" id="phone" formControlName="phone"
                                            [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                                        <div *ngIf="f.phone.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label>Genere</label>
                                        <select formControlName="gender" class="form-control">
                                            <option [ngValue]="0">Maschio</option>
                                            <option [ngValue]="1">Femmina</option>
                                        </select>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label>Nazione</label>
                                        <select formControlName="nation_id" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.nation_id.errors }">
                                            <option [ngValue]="null" disabled>Scegli una nazionalità</option>
                                            <option *ngFor="let d of nations; let i = index;" [ngValue]="d.nation_id">
                                                {{d.name}}
                                            </option>
                                        </select>
                                        <div *ngIf="f.nation_id.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="birth_date">Data di nascita</label>
                                        <input [owlDateTime]="dt_birth_date" [owlDateTimeTrigger]="dt_birth_date"
                                            type="text" class="form-control" id="birth_date"
                                            formControlName="birth_date" readonly="readonly"
                                            [ngClass]="{ 'is-invalid': submitted && f.birth_date.errors }">
                                        <owl-date-time #dt_birth_date [pickerType]="'calendar'"></owl-date-time>
                                        <div *ngIf="f.birth_date.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label>Nazione di nascita</label>
                                        <select formControlName="birth_nation_id" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.nation_id.errors }">
                                            <option [ngValue]="null" disabled>Scegli una nazionalità</option>
                                            <option *ngFor="let d of nations; let i = index;" [ngValue]="d.nation_id">
                                                {{d.name}}
                                            </option>
                                        </select>
                                        <div *ngIf="f.birth_nation_id.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                    <div *ngIf="f.birth_nation_id.value == 'IT'" class="form-group col-12 col-lg-6">
                                        <label>Comune di nascita</label>
                                        <ng-select formControlName="birth_municipality_id" [items]="municipalities"
                                            bindLabel="name" bindValue="municipality_id" [virtualScroll]=true
                                            [clearable]="false"
                                            [ngClass]="{ 'is-invalid': submitted && f.birth_municipality_id.errors }">
                                        </ng-select>

                                        <div *ngIf="f.birth_municipality_id.errors?.required" class="invalid-feedback">
                                            Campo obbligatorio
                                        </div>
                                    </div>

                                    <div *ngIf="f.birth_nation_id.value != 'IT'" class="form-group col-12 col-lg-6">
                                        <label for="birth_municipality_name">Comune di nascita</label>
                                        <input type="text" class="form-control" id="birth_municipality_name"
                                            formControlName="birth_municipality_name"
                                            [ngClass]="{ 'is-invalid': submitted && f.birth_municipality_name.errors }">
                                        <div *ngIf="f.birth_municipality_name.errors?.required"
                                            class="invalid-feedback">Campo obbligatorio</div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="tax_code">Codice fiscale</label>
                                        <input type="text" class="form-control" id="tax_code" formControlName="tax_code"
                                            [ngClass]="{ 'is-invalid': submitted && f.tax_code.errors }">
                                        <div *ngIf="f.tax_code.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="residence_city">Città di residenza</label>
                                        <input type="text" class="form-control" id="residence_city"
                                            formControlName="residence_city"
                                            [ngClass]="{ 'is-invalid': submitted && f.residence_city.errors }">
                                        <div *ngIf="f.residence_city.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="residence_address">Indirizzo di residenza</label>
                                        <input type="text" class="form-control" id="residence_city"
                                            formControlName="residence_address"
                                            [ngClass]="{ 'is-invalid': submitted && f.residence_address.errors }">
                                        <div *ngIf="f.residence_address.errors?.required" class="invalid-feedback">Campo
                                            obbligatorio</div>
                                    </div>

                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-12 col-lg-6">
                                    <label for="username">Nome d'arte</label>
                                    <input type="text" class="form-control" id="username" formControlName="username"
                                        [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                                    <div *ngIf="f.username.errors?.required" class="invalid-feedback">Campo obbligatorio
                                    </div>
                                </div>

                                <div class="form-group col-12 col-lg-6">
                                    <label>Città di preferenza</label>
                                    <select formControlName="city_id" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }">
                                        <option [ngValue]="null" disabled>Scegli una Città</option>
                                        <option *ngFor="let d of cities; let i = index;" [ngValue]="d.city_id">
                                            {{d.city_name}}
                                        </option>
                                    </select>
                                    <div *ngIf="f.city_id.errors?.required" class="invalid-feedback">Campo obbligatorio
                                    </div>
                                </div>

                                <div class="form-group col-12 col-lg-6">
                                    <label>Generi musicali</label>
                                    <ng-select formControlName="musical_genres" [searchable]="false" [multiple]="true"
                                        [items]="musical_genres" bindLabel="name" bindValue="genre_id"
                                        placeholder="Scegli uno o più ....">
                                    </ng-select>
                                </div>

                                <div class="form-group col-12 col-lg-6">
                                    <label for="score">Punti</label>
                                    <input type="number" class="form-control" id="score" formControlName="score"
                                        [ngClass]="{ 'is-invalid': submitted && f.score.errors }">
                                    <div *ngIf="f.score.errors?.required" class="invalid-feedback">Campo obbligatorio
                                    </div>
                                </div>

                                <div *ngIf="this.auth.isAdmin()" class="form-group col-12 col-lg-6">
                                    <label>Stelle</label>
                                    <select formControlName="stars" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.stars.errors }">
                                        <option *ngFor="let d of stars; let i = index;" [ngValue]="d.id">
                                            {{d.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="f.stars.errors?.required" class="invalid-feedback">Campo obbligatorio
                                    </div>
                                </div>

                                <div class="form-group col-12 col-lg-6">
                                    <label>Tipologia</label>
                                    <select formControlName="type" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.type.errors }">
                                        <option [ngValue]="null" disabled>Scegli una Tipologia</option>
                                        <option [ngValue]="1">Espressione</option>
                                        <option [ngValue]="2">Mestiere</option>
                                    </select>
                                    <div *ngIf="f.type.errors?.required" class="invalid-feedback">Campo obbligatorio
                                    </div>
                                </div>

                                <div class="form-group col-12 col-lg-6">
                                    <label>Premium</label>
                                    <select formControlName="premium" class="form-control">
                                        <option [ngValue]="0">No</option>
                                        <option [ngValue]="1">Si</option>
                                    </select>
                                </div>

                                <div class="form-group col-12 col-lg-6">
                                    <label>Visualizza banner in home page</label>
                                    <select formControlName="home_banner_visible" class="form-control">
                                        <option [ngValue]="0">No</option>
                                        <option [ngValue]="1">Si</option>
                                    </select>
                                </div>

                                <div class="form-group col-12 col-lg-6">
                                    <label>Profilo pubblico</label>
                                    <select formControlName="public_profile" class="form-control">
                                        <option [ngValue]="0">No</option>
                                        <option [ngValue]="1">Si</option>
                                    </select>
                                </div>

                                <div class="form-group col-12 col-lg-6">
                                    <label>Email Verificata</label>
                                    <select formControlName="email_verified" class="form-control">
                                        <option [ngValue]="0">No</option>
                                        <option [ngValue]="1">Si</option>
                                    </select>
                                </div>

                                <div class="form-group col-12 col-lg-6">
                                    <label>Lingua comunicazioni notifiche push e email</label>
                                    <select formControlName="language" class="form-control">
                                        <option [ngValue]="0">Italiano</option>
                                        <option [ngValue]="1">Inglese</option>
                                        <option [ngValue]="2">Portoghese</option>
                                    </select>
                                </div>

                                <div class="form-group col-12 col-lg-6">
                                    <label>Disponibile per eventi cittadini o privati (per essere contattato)</label>
                                    <select formControlName="is_available_private_event" class="form-control">
                                        <option [ngValue]="1">Si</option>
                                        <option [ngValue]="0">No</option>
                                    </select>
                                </div>

                                <div class="form-group col-12 col-lg-6">
                                    <label>Tipologia di performance</label>
                                    <ng-select formControlName="songs_types" [clearable]="false" [searchable]="false"
                                        [multiple]="true">
                                        <ng-option [value]="0">Cover</ng-option>
                                        <ng-option [value]="1">No Cover (brani propri)</ng-option>
                                        <ng-option [value]="2">Strumentale</ng-option>
                                    </ng-select>
                                </div>

                                <div class="form-group col-12">
                                    <label for="bio">Biografia <small>(opzionale)</small></label>
                                    <textarea class="form-control" id="bio" formControlName="bio"></textarea>
                                </div>
                            </div>

                            <div>
                                <div class="text-center">
                                    <span class="badge badge-warning text-uppercase">Profili social</span>
                                </div>

                                <div class="row mt-2">
                                    <div class="form-group col-12 col-lg-6">
                                        <label for="spotify_url">Link Spotify <small>(opzionale)</small></label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="spotify_url"
                                                formControlName="spotify_url">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <a [ngClass]="{'disabled': !f.spotify_url.value}"
                                                        href="{{f.spotify_url.value}}" target="_blank"><i
                                                            class="fab fa-spotify"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="soundcloud_url">Link Soundcloud <small>(opzionale)</small></label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="soundcloud_url"
                                                formControlName="soundcloud_url">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <a [ngClass]="{'disabled': !f.soundcloud_url.value}"
                                                        href="{{f.soundcloud_url.value}}" target="_blank"><i
                                                            class="fab fa-soundcloud"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="instagram_url">Link Instagram <small>(opzionale)</small></label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="instagram_url"
                                                formControlName="instagram_url">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <a [ngClass]="{'disabled': !f.instagram_url.value}"
                                                        href="{{f.instagram_url.value}}" target="_blank"><i
                                                            class="fab fa-instagram"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="x_url">Link X <small>(opzionale)</small></label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="x_url" formControlName="x_url">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <a [ngClass]="{'disabled': !f.x_url.value}" href="{{f.x_url.value}}"
                                                        target="_blank"><i class="fab fa-twitter"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="tiktok_url">Link TikTok <small>(opzionale)</small></label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="tiktok_url"
                                                formControlName="tiktok_url">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <a [ngClass]="{'disabled': !f.tiktok_url.value}"
                                                        href="{{f.tiktok_url.value}}" target="_blank"><i
                                                            class="fab fa-tiktok"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="youtube_url">Link Youtube <small>(opzionale)</small></label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="youtube_url"
                                                formControlName="youtube_url">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <a [ngClass]="{'disabled': !f.youtube_url.value}"
                                                        href="{{f.youtube_url.value}}" target="_blank"><i
                                                            class="fab fa-youtube"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="facebook_url">Link Facebook <small>(opzionale)</small></label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="facebook_url"
                                                formControlName="facebook_url">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <a [ngClass]="{'disabled': !f.facebook_url.value}"
                                                        href="{{f.facebook_url.value}}" target="_blank"><i
                                                            class="fab fa-facebook"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="link_url">Altro link <small>(opzionale)</small></label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="link_url"
                                                formControlName="link_url">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <a [ngClass]="{'disabled': !f.link_url.value}"
                                                        href="{{f.link_url.value}}" target="_blank"><i
                                                            class="fa fa-link"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-lg-6">
                                        <label for="donation_url">Link Donazione <small>(opzionale)</small></label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="donation_url"
                                                formControlName="donation_url">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <a [ngClass]="{'disabled': !f.donation_url.value}"
                                                        href="{{f.donation_url.value}}" target="_blank"><i
                                                            class="fa fa-heart"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div *ngIf="this.auth.isAdmin() || this.auth.isMunicipality()" class="card-footer text-center">
                            <button type="submit" class="btn btn-info" (click)="save()"> <span *ngIf="loading"
                                    class="spinner-border spinner-border-sm mr-2"></span>
                                Salva</button>

                            <button *ngIf="id" class="btn btn-danger btn-info" [swal]="deleteSwal"
                                [disabled]="!this.auth.isAdmin()"><i class="fas fa-trash mr-2"></i>Elimina</button>
                            <swal #deleteSwal title="Elimina" text="Vuoi eliminare questo talento ?" icon="warning"
                                [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                (confirm)="deleteTalent()"></swal>
                        </div>

                        <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>
                </form>
            </div>

            <div class="col-12">
                <div *ngIf="id" class="card card-info collapsed-card">
                    <div class="card-header" data-card-widget="collapse">
                        <span class="card-title text-uppercase">
                            Città in cui l'utente può esibirsi
                        </span>
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i
                                    class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>

                    <div class="card-body table-responsive">
                        <button class="btn btn-info mb-3" (click)="addDocumentCity()"><i
                                class="fas fa-plus mr-1"></i>Aggiungi</button>

                        <table id="table_documents" datatable [dtOptions]="dtOptionsDocuments"
                            [dtTrigger]="dtTriggerDocuments" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th style="width: 60%">Città</th>
                                    <th style="width: 15%">Concessione</th>
                                    <th style="width: 25%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of documents">
                                    <td>{{item.city_name}}</td>
                                    <td [ngSwitch]="item.document_status">
                                        <span *ngSwitchCase="0" class="badge badge-pill badge-warning text-uppercase">In
                                            fase di revisione</span>
                                        <span *ngSwitchCase="1"
                                            class="badge badge-pill badge-success text-uppercase">Approvata</span>
                                        <span *ngSwitchCase="2"
                                            class="badge badge-pill badge-danger text-uppercase">Rifiutata</span>
                                    </td>
                                    <td class="text-right">
                                        <button *ngIf="item.document == 1" class="btn btn-info btn-sm"
                                            [routerLink]="['/talents/detail', id, 'city', item.city_id]"><i
                                                class="fas fa-eye mr-2"></i>Gestione documenti</button>

                                        <button class="btn btn-danger btn-sm" [swal]="deleteDocumentCitySwal"><i
                                                class="fas fa-trash mr-2"></i>Elimina</button>
                                        <swal #deleteDocumentCitySwal title="Elimina"
                                            text="Vuoi eliminare questa città ?" icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                            (confirm)="deleteDocumentCity(item.city_id)"></swal>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoadingDocuments" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>

                <div *ngIf="id" class="card card-info mt-3 collapsed-card">
                    <div class="card-header" data-card-widget="collapse">
                        <span class="card-title text-uppercase">
                            Ammonizioni
                        </span>
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i
                                    class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>

                    <div class="card-body table-responsive">
                        <button class="btn btn-info mb-3" (click)="addAdmonition()"><i
                                class="fas fa-plus mr-1"></i>Aggiungi</button>

                        <table id="table_admonitions" datatable [dtOptions]="dtOptionsAdmonitions"
                            [dtTrigger]="dtTriggerAdmonitions" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Data ammonizione</th>
                                    <th>Data evento</th>
                                    <th>Evento</th>
                                    <th>Note</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of admonitions">
                                    <td>{{item.creation_date * 1000 | date :'dd/MM/yyyy HH:mm'}}</td>
                                    <td>{{(item.event_date != null) ? (item.event_date * 1000 | date :'dd/MM/yyyy
                                        HH:mm') : ""}}</td>
                                    <td>{{item.title}}</td>
                                    <td>{{item.admonition_notes}}</td>
                                    <td class="text-right">
                                        <button *ngIf="item.event_id != null" class="btn btn-info btn-sm"
                                            [routerLink]="['/events/detail', item.event_id]"><i
                                                class="fas fa-eye mr-1"></i> Visualizza</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoadingAdmonitions" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>

                <div *ngIf="id" class="card card-info mt-3 collapsed-card">
                    <div class="card-header" data-card-widget="collapse">
                        <span class="card-title text-uppercase">
                            Assenze
                        </span>
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i
                                    class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>

                    <div class="card-body table-responsive">
                        <table id="table_absences" datatable [dtOptions]="dtOptionsAbsences"
                            [dtTrigger]="dtTriggerAbsences" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Data evento</th>
                                    <th>Evento</th>
                                    <th>Stato</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of absences">
                                    <td>{{item.event_date * 1000 | date :'dd/MM/yyyy HH:mm'}}</td>
                                    <td>{{item.title}}</td>
                                    <td>
                                        <span *ngIf="item.perform != 1 && item.justified_absence == 0"
                                            class="badge badge-pill badge-danger text-uppercase">Assente non
                                            giustificato</span>

                                        <span *ngIf="item.perform != 1 && item.justified_absence == 1"
                                            class="badge badge-pill badge-success text-uppercase">Assente
                                            giustificato</span>

                                        <span *ngIf="item.perform_admonition == 1"
                                            class="badge badge-pill badge-warning text-uppercase">Ammonito</span>
                                    </td>
                                    <td class="text-right">
                                        <button *ngIf="item.perform !=1 && item.justified_absence == null"
                                            class="btn btn-success btn-sm" (click)="absenceJustify(item.event_id, 1)"><i
                                                class="fas fa-thumbs-up mr-1"></i>Giustifica</button>

                                        <button *ngIf="item.perform !=1 && item.justified_absence == null"
                                            class="btn btn-danger btn-sm" (click)="absenceJustify(item.event_id, 0)"><i
                                                class="fas fa-thumbs-down mr-1"></i>Non giustificare</button>

                                        <button
                                            *ngIf="item.perform !=1 && item.perform_admonition != 1 && item.justified_absence !=1"
                                            class="btn btn-warning btn-sm" [swal]="admonitionSwal"><i
                                                class="fas fa-exclamation mr-1"></i>Ammonisci</button>
                                        <swal #admonitionSwal title="Ammonisci"
                                            text="Con l'ammonizione andrai ad impostare anche l'assenza NON giustificata"
                                            icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Ok, ammonisci!',  cancelButtonText: 'No' }"
                                            (confirm)="absenceAdmonition(item.event_id)"></swal>

                                        <button class="btn btn-info btn-sm"
                                            [routerLink]="['/events/detail', item.event_id]"><i
                                                class="fas fa-eye mr-1"></i> Visualizza</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoadingAbsences" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>

                <div *ngIf="id" class="card card-info mt-3 collapsed-card">
                    <div class="card-header" data-card-widget="collapse">
                        <span class="card-title text-uppercase">
                            Concessioni
                        </span>
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i
                                    class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>

                    <div class="card-body table-responsive">
                        <table id="table_concessions" datatable [dtOptions]="dtOptionsConcessions"
                            [dtTrigger]="dtTriggerConcessions" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Data richiesta</th>
                                    <th>Identificativo</th>
                                    <th>Città</th>
                                    <th>Eventi</th>
                                    <th>Status</th>
                                    <th style="width: 15%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of concessions">
                                    <td>{{item.creation_date * 1000 | date :'dd/MM/yyyy HH:mm'}}</td>
                                    <td>{{item.identifier}}</td>
                                    <td>{{item.city_name}}</td>
                                    <td [innerHTML]="item.events"></td>
                                    <td>
                                        <span *ngIf="item.status == 0"
                                            class="badge badge-pill badge-warning text-uppercase">In fase di
                                            revisione</span>
                                        <span *ngIf="item.status == 1"
                                            class="badge badge-pill badge-success text-uppercase">Approvata</span>
                                        <span *ngIf="item.status == 2"
                                            class="badge badge-pill badge-danger text-uppercase">Rifiutata</span>
                                    </td>
                                    <td>
                                        <button *ngIf="item.status == 0"
                                            (click)="concessionsApprove(item.concession_id)"
                                            class="btn btn-success btn-sm"><i
                                                class="fas fa-thumbs-up mr-2"></i>Accetta</button>
                                        <button *ngIf="item.status == 0" (click)="concessionRefuse(item.concession_id)"
                                            class="btn btn-danger btn-sm"><i
                                                class="fas fa-thumbs-down mr-2"></i>Rifiuta</button>
                                        <button *ngIf="item.status == 1"
                                            (click)="generateConcession(item.concession_id)"
                                            class="btn btn-info btn-sm"><i class="fas fa-file mr-2"></i>Genera
                                            DOCX</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoadingConcessions" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>

                <div *ngIf="id && this.auth.currentUserValue.role_id == 0" class="card card-info mt-3 collapsed-card">
                    <div class="card-header" data-card-widget="collapse">
                        <span class="card-title text-uppercase">
                            Pagamenti
                        </span>
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i
                                    class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>

                    <div class="card-body table-responsive">
                        <button class="btn btn-info mb-3" (click)="addPayment()"><i
                                class="fas fa-plus mr-1"></i>Aggiungi</button>

                        <table id="table_payments" datatable [dtOptions]="dtOptionsPayments"
                            [dtTrigger]="dtTriggerPayments" class="table table-striped ">
                            <thead>
                                <tr>
                                    <th style="width: 15%">Data</th>
                                    <th style="width: 15%">Importo</th>
                                    <th style="width: 60%">Descrizione</th>
                                    <th style="width: 10%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of payments">
                                    <td>{{item.date * 1000 | date :'dd/MM/yyyy HH:mm'}}</td>
                                    <td>{{item.amount / 100 | currency:'€':true:'1.2-2'}}</td>
                                    <td>{{item.description}}</td>
                                    <td class="text-right">
                                        <button class="btn btn-danger btn-sm" [swal]="deletePaymentSwal"><i
                                                class="fas fa-trash mr-2"></i>Elimina</button>
                                        <swal #deletePaymentSwal title="Elimina"
                                            text="Vuoi eliminare questo pagamento ?" icon="warning"
                                            [swalOptions]="{ showCancelButton: true,  confirmButtonText: 'Si, elimina!',  cancelButtonText: 'No' }"
                                            (confirm)="deletePayment(item.payment_id)"></swal>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="isLoadingPayments" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>

                <div *ngIf="id && this.auth.currentUserValue.role_id == 0" class="card card-info mt-3 collapsed-card">
                    <div class="card-header" data-card-widget="collapse">
                        <span class="card-title text-uppercase">
                            Info Pagamenti
                        </span>
                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i
                                    class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>

                    <div class="card-body table-responsive">
                        <form [formGroup]="form_payment_2">

                            <div class="mb-3">
                                <label>Numero call for artist in cui si è stati selezionati</label>
                                <input formControlName="calls_count" type="number" class="form-control"
                                    placeholder="Inserisci numero"
                                    [ngClass]="{ 'is-invalid': submitted && fp2.calls_count.errors }" />
                                <div *ngIf="fp2.calls_count.errors?.required" class="invalid-feedback">Campo
                                    obbligatorio</div>
                            </div>

                            <div class="mb-3">
                                <label>Numero eventi</label>
                                <input formControlName="events_count" type="text" class="form-control"
                                    placeholder="Numero eventi" readonly="readonly" />
                            </div>

                            <div class="mb-3">
                                <label>Importo totale</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">€</span>
                                    </div>
                                    <input formControlName="total_amount" type='number' step='0.01' class="form-control"
                                        placeholder="Importo totale" readonly="readonly" />
                                </div>
                            </div>

                        </form>
                    </div>

                    <div class="card-footer text-center">
                        <button type="submit" class="btn btn-info" (click)="savePaymentDetail()">Salva</button>
                    </div>

                    <div *ngIf="isLoading" class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Modal add/edit -->
<div class="modal fade" id="modal-default" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div>
                    <label>Assegna Città</label>
                    <div>
                        <ng-select [(ngModel)]="selectedDocumentCity" [items]="documentCities" bindLabel="city_name"
                            bindValue="city_id" [clearable]="false" placeholder="Scegli una città"
                            notFoundText="Nessuna città disponibile">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>
                <button type="submit" class="btn btn-info" data-dismiss="modal" [disabled]="!selectedDocumentCity"
                    (click)="openDocumentCity()">Aggiungi</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal payment -->
<div class="modal fade" id="modal-payment" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <form [formGroup]="form_payment">
                <div class="modal-body">

                    <div class="mb-3">
                        <label for="date">Data</label>
                        <input [owlDateTime]="dt_date" [owlDateTimeTrigger]="dt_date" type="text" class="form-control"
                            id="date" formControlName="date" readonly="readonly"
                            [ngClass]="{ 'is-invalid': submitted && fp.date.errors }">
                        <owl-date-time #dt_date></owl-date-time>
                        <div *ngIf="fp.date.errors?.required" class="invalid-feedback">Campo
                            obbligatorio</div>
                    </div>

                    <div class="mb-3">
                        <label>Importo</label>
                        <input formControlName="amount" type="text" class="form-control"
                            placeholder="Inserisci l'importo"
                            [ngClass]="{ 'is-invalid': submitted && fp.amount.errors }" />
                        <div *ngIf="fp.amount.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                    </div>

                    <div class="mb-3">
                        <label>Descrizione</label>
                        <input formControlName="description" type="text" class="form-control"
                            placeholder="Inserisci la descrizione"
                            [ngClass]="{ 'is-invalid': submitted && fp.description.errors }" />
                        <div *ngIf="fp.description.errors?.required" class="invalid-feedback">Campo obbligatorio</div>
                    </div>

                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>
                    <button type="submit" class="btn btn-info" data-dismiss="modal"
                        (click)="savePayment()">Aggiungi</button>
                </div>
            </form>
        </div>
    </div>
</div>