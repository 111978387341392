import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ModalPlaceDatesComponent } from 'src/app/modals/modal-place-dates/modal-place-dates.component';
import { City, CityArea, CitySlot } from 'src/app/models/city';
import { Peripheral } from 'src/app/models/peripheral';
import { Place, PlaceDate } from 'src/app/models/place';
import { Sensor } from 'src/app/models/sensor';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { AuthenticationService } from 'src/app/utils/services/authentication.service';
import { RestService } from 'src/app/utils/services/rest.service';
declare var $: any;

@Component({
  selector: 'app-place-detail',
  templateUrl: './place-detail.component.html',
  styleUrls: ['./place-detail.component.css']
})
export class PlaceDetailComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  id: number;
  form: FormGroup;
  submitted = false;
  peripherals: Peripheral[] = [];
  cities: City[] = [];
  areas: CityArea[] = [];
  types = [{ id: 0, title: "Standard" }, { id: 1, title: "Sclak" }, { id: 2, title: "Stradarte" }, { id: 3, title: "Palco libero" }];
  place: Place;
  isLoading: boolean = false;
  isUpdate: boolean;
  sensorsGmove: Sensor[] = [];
  fileCover: File = null;
  deleteCover: Boolean = false;

  checkedItems: string[] = [];
  isChecked = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private rest: RestService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    public auth: AuthenticationService
  ) {
    this.id = this.route.snapshot.params['id'];
  }

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [
        { type: "date-euro", targets: 1 },
        { orderable: false, targets: [0, 3] }],
      order: [1, "asc"],
      language: {
        url: "./assets/datatable_ITA.json"
      }
    };

    $('#professions').select2({
      allowClear: true
    });

    $('#places_connections').select2({
      allowClear: true
    });

    this.form = new FormGroup({
      city: new FormControl(null, Validators.required),
      area: new FormControl(null),
      place_name: new FormControl(null, Validators.required),
      address: new FormControl(null, Validators.required),
      type: new FormControl(null, Validators.required),
      peripheral: new FormControl(null),
      description: new FormControl(null),
      description_en: new FormControl(null),
      description_pt: new FormControl(null),
      description_es: new FormControl(null),
      gmove_locationid: new FormControl(null),
      wiseair_url: new FormControl(null),
      lat: new FormControl(null, Validators.required),
      lon: new FormControl(null, Validators.required),
      timer_tv_10: new FormControl(null),
      timer_tv_55: new FormControl(null),
      cover_url: new FormControl(null),
      tint_color: new FormControl(null, CustomValidators.exadecimalColor()),
      days_between_user_events: new FormControl(0, [Validators.required, Validators.min(0)]),
      emission: new FormControl(0),
      profession: new FormControl(0),
      talent: new FormControl(0),
      require_approval: new FormControl(0),
      road_coefficient: new FormControl(0),
      professions: new FormControl(null),
      enabled: new FormControl(true),
      places_connections: new FormControl(null),
      accept_email_extra: new FormControl(null),
      talent_month_events_limit: new FormControl(999, [Validators.required, Validators.min(0)]),
      talent_day_events_limit: new FormControl(0, [Validators.required, Validators.min(0)]),
      pdf_url: new FormControl(null),
      geoportal_url: new FormControl(null),
      locker_code: new FormControl(null),
      serial_number: new FormControl(null)
    });

    this.peripheralsList();
    this.sensorsGmoveList();
    this.citiesList();
    this.professionsList();
    this.placesList();

    if (this.id) {
      this.placeDetail();
    } else {
      $('#accept_email_extra').summernote('reset');
    }

  }

  get f() {
    return this.form.controls;
  }

  peripheralsList() {
    this.rest.peripheralsList().subscribe(res => {
      this.peripherals = res.data.peripherals;
    }, error => {
      this.toastr.error('Si è verificato un errore nel caricamento delle periferiche Sclak, riprova!');
    });
  }

  sensorsGmoveList() {
    this.rest.sensorsGmoveList().subscribe(res => {
      this.sensorsGmove = res.data.sensors;
    }, error => {
      this.toastr.error('Si è verificato un errore nel caricamento dei sensori G-Move, riprova!');
    });
  }

  // Cities 

  citiesList() {
    this.rest.citiesList().subscribe(res => {
      this.cities = res.data.cities;
    });
  }


  // Professions 

  professionsList() {
    this.rest.professionsAllList().subscribe(res => {
      for (let profession of res.data.professions) {
        var data = { id: profession.profession_id, text: profession.name };
        var newOption = new Option(data.text, data.id.toString(), false, false);
        $('#professions').append(newOption).trigger('change');
      }
      if (this.place) {
        $('#professions').val(this.place.professions).trigger("change");
      }
    });
  }

  // Places 

  placesList() {
    this.rest.placesList().subscribe(res => {
      for (let place of res.data.places) {
        var data = { id: place.place_id, text: place.place_name };
        var newOption = new Option(data.text, data.id.toString(), false, false);
        $('#places_connections').append(newOption).trigger('change');
      }
      if (this.place) {
        $('#places_connections').val(this.place.places_connections).trigger("change");
      }
    });
  }


  // Place 

  placeDetail() {
    this.rest.placeDetail(this.id).subscribe(res => {
      this.place = res.data.place;

      this.f.place_name.setValue(this.place.place_name);
      this.f.city.setValue(this.place.city_id);
      this.f.area.setValue(this.place.area_id);
      this.f.address.setValue(this.place.address);
      this.f.type.setValue(this.place.type);
      this.f.peripheral.setValue(this.place.peripheral_id);
      this.f.description.setValue(this.place.description);
      this.f.description_en.setValue(this.place.description_en);
      this.f.description_pt.setValue(this.place.description_pt);
      this.f.description_es.setValue(this.place.description_es);
      this.f.gmove_locationid.setValue(this.place.gmove_locationid);
      this.f.wiseair_url.setValue(this.place.wiseair_url);
      this.f.lat.setValue(this.place.lat);
      this.f.lon.setValue(this.place.lon);
      this.f.timer_tv_10.setValue(this.place.timer_tv_10);
      this.f.timer_tv_55.setValue(this.place.timer_tv_55);
      this.f.cover_url.setValue(this.place.cover_url);
      this.f.tint_color.setValue(this.place.tint_color);
      this.f.days_between_user_events.setValue(this.place.days_between_user_events);
      this.f.emission.setValue(this.place.emission);
      this.f.profession.setValue(this.place.profession);
      this.f.talent.setValue(this.place.talent);
      this.f.require_approval.setValue(this.place.require_approval);
      this.f.road_coefficient.setValue(this.place.road_coefficient);
      this.f.professions.setValue(this.place.professions);
      this.f.enabled.setValue(this.place.enabled == 1);
      this.f.places_connections.setValue(this.place.places_connections);
      this.f.talent_month_events_limit.setValue(this.place.talent_month_events_limit);
      this.f.talent_day_events_limit.setValue(this.place.talent_day_events_limit);
      this.f.pdf_url.setValue(this.place.pdf_url);
      this.f.geoportal_url.setValue(this.place.geoportal_url);
      this.f.locker_code.setValue(this.place.locker_code);
      this.f.serial_number.setValue(this.place.serial_number);

      $('#professions').val(this.place.professions).trigger("change");
      $('#places_connections').val(this.place.places_connections).trigger("change");

      $('#accept_email_extra').summernote({ height: 250, minHeight: 250, });
      $('#accept_email_extra').summernote('code', this.place.accept_email_extra);

      if (this.isUpdate) {
        this.rerenderDataTable();
      } else {
        this.isUpdate = true;
        this.dtTrigger.next();
      }

      this.cityDetail();

      // update validators
      this.typeChange();
    }, error => {
      this.toastr.error('Si è verificato un errore nel caricamento delle periferiche Sclak, riprova!');
    });
  }

  cityChange() {
    this.f.area.reset();
    this.cityDetail();
  }

  cityDetail() {
    this.rest.cityDetail(this.f.city.value).subscribe(res => {
      this.areas = res.data.areas;
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  typeChange() {
    if (this.f.type.value == 3) {
      this.f.address.setValidators(null);
      this.f.lat.setValidators(null);
      this.f.lon.setValidators(null);

      this.f.address.reset();
      this.f.lat.reset();
      this.f.lon.reset();
      this.f.timer_tv_10.reset();
      this.f.timer_tv_55.reset();
      this.f.gmove_locationid.reset();
      this.f.wiseair_url.reset();
    } else {
      this.f.address.setValidators(Validators.required);
      this.f.address.updateValueAndValidity();

      this.f.lat.setValidators(Validators.required);
      this.f.lat.updateValueAndValidity();

      this.f.lon.setValidators(Validators.required);
      this.f.lon.updateValueAndValidity();

      this.f.pdf_url.setValidators(null);
      this.f.geoportal_url.setValidators(null);
    }
  }

  handleFileInputCover(files: FileList) {
    if (files && files.length > 0) {
      this.fileCover = files[0];

      var reader = new FileReader();
      reader.onload = (event) => {
        this.f.cover_url.setValue(event.target.result);
      };

      reader.readAsDataURL(this.fileCover);
    }
  }

  removeCover() {
    this.fileCover = null;
    this.f.cover_url.setValue(null);
    this.deleteCover = true;
  }

  getFormatDate(date: string) {
    return moment(date, "YYYY-MM-DD").locale("it").format("dddd DD-MM-YYYY");
  }

  getSlots(slots: CitySlot[]) {
    var times = [];
    slots.forEach(i => {
      var _startTime = moment(i.start_time, "HH:mm:ss").format("HH:mm");
      var _endTime = moment(i.end_time, "HH:mm:ss").format("HH:mm");
      var _whole_day = ((i.whole_day == 1) ? " (G)" : "");
      var startTime = (i.status == 1) ? "<strong>" + _startTime + "</strong>" : _startTime;
      var endTime = (i.status == 1) ? "<strong>" + _endTime + "</strong>" : _endTime;
      var whole_day = (i.status == 1) ? "<strong>" + _whole_day + "</strong>" : _whole_day;
      times.push(startTime + '-' + endTime + whole_day);
    });
    return times;
  }

  savePlace() {
    this.submitted = true;

    if (this.areas.length > 0) {
      if (!this.f.area.value) this.f.area.setErrors({ 'required': true })
    } else {
      this.f.area.setErrors(null)
    }

    if (this.f.type.value == 2) { // palco stradarte
      if (!this.f.road_coefficient.value) this.f.road_coefficient.setErrors({ 'required': true })
    } else {
      this.f.road_coefficient.setErrors(null)
    }

    if (this.f.type.value == 1) {
      if (!this.f.peripheral.value) this.f.peripheral.setErrors({ 'required': true })
    } else {
      this.f.peripheral.setErrors(null)
    }

    // palco libero
    if (this.f.type.value == 3) {
      // almeno 1 dei 2 deve essere compilato
      if (!this.f.pdf_url.value && !this.f.geoportal_url.value) {
        this.f.pdf_url.setErrors({ 'required': true });
        this.f.geoportal_url.setErrors({ 'required': true });
      } else {
        this.f.pdf_url.setErrors(null);
        this.f.geoportal_url.setErrors(null);
      }
    }

    if (this.form.invalid) {
      return;
    }

    if (this.fileCover?.size / 1024 / 1024 > 2) {
      this.toastr.error('Immagine troppo grande!');
      return;
    }

    const formData = new FormData();
    formData.append('place_name', this.f.place_name.value);

    formData.append('type', this.f.type.value.toString());

    formData.append('city_id', this.f.city.value.toString());
    if (this.f.area.value) formData.append('area_id', this.f.area.value);

    if (this.f.address.value) formData.append('address', this.f.address.value);
    if (this.f.lat.value) formData.append('lat', this.f.lat.value.toString());
    if (this.f.lon.value) formData.append('lon', this.f.lon.value.toString());
    if (this.f.road_coefficient.value) formData.append('road_coefficient', this.f.road_coefficient.value);

    // per palco di tipo sclack
    if (this.f.type.value == 1) {
      formData.append('peripheral_id', this.f.peripheral.value.toString());
      formData.append('locker_code', this.f.locker_code.value);
      formData.append('serial_number', this.f.serial_number.value);
    }

    // palco libero
    if (this.f.type.value == 3) {
      if (this.f.pdf_url.value) formData.append('pdf_url', this.f.pdf_url.value);
      if (this.f.geoportal_url.value) formData.append('geoportal_url', this.f.geoportal_url.value);
    }

    if (this.f.description.value) formData.append('description', this.f.description.value);
    if (this.f.description_en.value) formData.append('description_en', this.f.description_en.value);
    if (this.f.description_pt.value) formData.append('description_pt', this.f.description_pt.value);
    if (this.f.description_es.value) formData.append('description_es', this.f.description_es.value);

    if (this.f.gmove_locationid.value) formData.append('gmove_locationid', this.f.gmove_locationid.value.toString());
    if (this.f.wiseair_url.value) formData.append('wiseair_url', this.f.wiseair_url.value.toString());
    if (this.f.timer_tv_10.value) formData.append('timer_tv_10', this.f.timer_tv_10.value.toString());
    if (this.f.timer_tv_55.value) formData.append('timer_tv_55', this.f.timer_tv_55.value.toString());
    if (this.f.tint_color.value) formData.append('tint_color', this.f.tint_color.value.toString());
    if (this.f.days_between_user_events.value) formData.append('days_between_user_events', this.f.days_between_user_events.value.toString());

    formData.append('emission', this.f.emission.value);
    formData.append('profession', this.f.profession.value);
    formData.append('talent', this.f.talent.value);
    formData.append('require_approval', this.f.require_approval.value);
    formData.append('talent_month_events_limit', this.f.talent_month_events_limit.value.toString());
    formData.append('talent_day_events_limit', this.f.talent_day_events_limit.value.toString());
    
    if (this.fileCover) formData.append("file", this.fileCover);
    formData.append("delete_cover", this.deleteCover ? '1' : '0');

    // per palco stradarte passare la lista dei mestiere vietati
    if (this.f.type.value == 2) {
      let professions_selected = $("#professions").select2('data').map(function (a) { return a.id }).join();
      formData.append('professions', professions_selected);
    }

    let places_connections_selected = $("#places_connections").select2('data').map(function (a) { return a.id }).join();
    formData.append('places_connections', places_connections_selected);

    let accept_email_extra = $('#accept_email_extra').summernote('code');
    formData.append('accept_email_extra', accept_email_extra);

    formData.append('enabled', this.f.enabled.value ? "1" : "0");

    this.isLoading = true;

    if (this.id) {
      formData.append('place_id', this.id.toString());

      this.rest.placeEdit(formData).subscribe(res => {
        this.toastr.success('Palco aggiornato con successo!');
        this.isLoading = false;
      }, error => {
        this.toastr.error('Si è verificato un errore, riprova!');
        this.isLoading = false;
      });
    } else {
      this.rest.placeAdd(formData).subscribe(res => {
        this.isLoading = false;
        this.toastr.success('Palco creato con successo!');
        this.router.navigate(['/places']);
      }, error => {
        this.isLoading = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }
  }

  openModalAddDate() {
    const modalRef = this.modalService.open(ModalPlaceDatesComponent);
    modalRef.componentInstance.cityID = this.place.city_id;
    modalRef.componentInstance.placeID = this.id;
    modalRef.componentInstance.editMode = false;
    modalRef.componentInstance.removeMode = false;
    modalRef.result.then((result) => {
      this.placeDetail();
    }, (reason) => { });
  }

  openModalDeleteDate() {
    const modalRef = this.modalService.open(ModalPlaceDatesComponent);
    modalRef.componentInstance.cityID = this.place.city_id;
    modalRef.componentInstance.placeID = this.id;
    modalRef.componentInstance.editMode = false;
    modalRef.componentInstance.removeMode = true;
    modalRef.result.then((result) => {
      this.placeDetail();
    }, (reason) => { });
  }

  deleteDate(item: PlaceDate) {
    var dates: string[] = [item.date];
    this.rest.placeDateDelete(this.id, dates).subscribe(res => {
      this.checkedItems = [];
      this.toastr.success('Data eliminata con successo!');
      this.placeDetail();
      // this.place.dates = this.place.dates.filter(i => i.date != item.date);
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  deleteSelectedDates() {
    var dates: string[] = this.checkedItems;
    this.rest.placeDateDelete(this.id, dates).subscribe(res => {
      this.checkedItems = [];
      this.toastr.success('Date eliminate con successo!');
      this.placeDetail();
      // this.place.dates = this.place.dates.filter(i => i.date != item.date);
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  editDate(item: PlaceDate) {
    const modalRef = this.modalService.open(ModalPlaceDatesComponent);
    modalRef.componentInstance.cityID = this.place.city_id;
    modalRef.componentInstance.placeID = this.id;
    modalRef.componentInstance.date = item;
    modalRef.componentInstance.editMode = true;
    modalRef.result.then((result) => {
      // vuol dire che ho eliminato l'ultmio slot quindi va fatto refresh
      if (result == 100) {
        this.placeDetail();
      }

    }, (reason) => { });
  }

  // ************ selezione multipla di date

  itemSelected(e: any) {
    const index = this.checkedItems.findIndex(c => c == e.target.value)

    if (index > -1) {
      this.checkedItems.splice(index, 1);
    } else {
      this.checkedItems.push(e.target.value);
    }
  }

  checkuncheckall() {
    this.isChecked = !this.isChecked;

    if (this.isChecked) {
      this.checkedItems = this.place.dates.map((i) => i.date);
    } else {
      this.checkedItems = [];
    }
  }

  // ************

  rerenderDataTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    $('#accept_email_extra').summernote('destroy');
    this.dtTrigger.unsubscribe();
  }
}
