import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { City, CityArea } from 'src/app/models/city';
import { Event } from 'src/app/models/event';
import { Pagination } from 'src/app/models/pagination';
import { Sponsor } from 'src/app/models/sponsor';
import { RestService } from 'src/app/utils/services/rest.service';
declare var $: any;

@Component({
  selector: 'app-modal-sponsor',
  templateUrl: './modal-sponsor.component.html',
  styleUrls: ['./modal-sponsor.component.css']
})
export class ModalSponsorComponent implements OnInit {

  @Input() sponsor: Sponsor;

  types = [{ id: 0, title: "Link" }, { id: 1, title: "Evento" }, { id: 2, title: "Città" }, { id: 3, title: "Area" }];
  submitted = false;
  form: FormGroup;
  fileCover: File = null;
  deleteCover: Boolean = false;
  loading = false;
  events: Event[];
  cities: City[] = [];
  areas: CityArea[] = [];

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private rest: RestService, private toastr: ToastrService) { }

  ngOnInit(): void {
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

    this.form = this.formBuilder.group({
      cover_url: [this.sponsor?.cover_url],
      name: [this.sponsor?.name, Validators.required],
      languages: [this.sponsor?.languages, Validators.required],
      external_link: [this.sponsor?.external_link, Validators.pattern(urlRegex)],
      type_id: [this.sponsor?.type_id ?? 0, Validators.required],
      event_id: [this.sponsor?.event_id],
      city_id: [this.sponsor?.city_id],
      area_id: [this.sponsor?.area_id]
    });

    $('#languages').select2({
      allowClear: true
    });

    if (this.sponsor?.city_id) {
      this.cityDetail();
    }

    this.languagesList();
    this.citiesList();
    this.eventsList();
  }

  get f() {
    return this.form.controls;
  }

  handleFileInputCover(files: FileList) {
    if (files && files.length > 0) {
      this.fileCover = files[0];

      var reader = new FileReader();
      reader.onload = (event) => {
        this.f.cover_url.setValue(event.target.result);
      };

      reader.readAsDataURL(this.fileCover);
    }
  }

  removeCover() {
    this.fileCover = null;
    this.f.cover_url.setValue(null);
    this.deleteCover = true;
  }

  // Languages 

  languagesList() {
    this.rest.languagesList().subscribe(res => {      
      for (let language of res.data.languages) {
        var data = { id: language.language, text: language.name };
        var newOption = new Option(data.text, data.id.toString(), false, false);
        $('#languages').append(newOption).trigger('change');
      }
      if (this.sponsor) {
        let values = this.sponsor.languages.map(x => x.language);
        $('#languages').val(values).trigger("change");
      }
    });
  }

  /// Events 

  eventsList() {
    let pagination = new Pagination;
    pagination.search = "";
    pagination.start = 0;
    pagination.length = 10000;
    pagination.sort_field = "e.event_date";
    pagination.sort_type = "asc";

    this.rest.eventsList(pagination, []).subscribe(res => {
      this.events = res.data.events;
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /// Cities 

  citiesList() {
    this.rest.citiesList().subscribe(res => {
      this.cities = res.data.cities;
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /// City 

  cityChange() {
    this.f.area_id.reset();
    this.cityDetail();
  }

  cityDetail() {
    this.rest.cityDetail(this.f.city_id.value).subscribe(res => {
      this.areas = res.data.areas;
    }, error => {
      this.toastr.error('Si è verificato un errore, riprova!');
    });
  }

  /// Type

  typeChange() {
    this.f.external_link.reset();
    this.f.event_id.reset();
    this.f.city_id.reset();
    this.f.area_id.reset();
  }

  /// Save

  save() {
    let languages = $("#languages").select2('data').map(function (a) { return a.id }).join();
    this.f.languages.setValue(languages);

    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    if (this.f.type_id.value == 0 && !this.f.external_link.value) {
      this.toastr.error('Devi inserire un link!');
      return;
    }

    if (this.f.type_id.value == 1 && !this.f.event_id.value) {
      this.toastr.error('Devi inserire un evento!');
      return;
    }

    if (this.f.type_id.value == 2 && !this.f.city_id.value) {
      this.toastr.error('Devi inserire una città!');
      return;
    }

    if (this.f.type_id.value == 3 && !this.f.city_id.value && !this.f.area_id.value) {
      this.toastr.error('Devi inserire una città e un area!');
      return;
    }

    if (this.fileCover?.size / 1024 / 1024 > 1) {
      this.toastr.error('Immagine troppo grande!');
      return;
    }

    this.loading = true;

    const formData = new FormData();
    formData.append('name', this.f.name.value);
    formData.append('type_id', this.f.type_id.value);

    formData.append('languages', languages);

    if (this.f.type_id.value == 0) {
      if (this.f.external_link.value) formData.append('external_link', this.f.external_link.value);
    }

    if (this.f.type_id.value == 1) {
      if (this.f.event_id.value) formData.append('event_id', this.f.event_id.value);
    }

    if (this.f.type_id.value == 2) {
      if (this.f.city_id.value) formData.append('city_id', this.f.city_id.value);
    }

    if (this.f.type_id.value == 3) {
      if (this.f.city_id.value) formData.append('city_id', this.f.city_id.value);
      if (this.f.area_id.value) formData.append('area_id', this.f.area_id.value);
    }

    if (this.fileCover) formData.append("file", this.fileCover);
    formData.append("delete_cover", this.deleteCover ? '1' : '0');

    if (this.sponsor?.sponsor_id) {
      formData.append('sponsor_id', this.sponsor?.sponsor_id.toString());

      this.rest.sponsorEdit(formData).subscribe((result) => {
        this.loading = false;
        this.activeModal.close();
      }, error => {
        this.loading = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    } else {
      this.rest.sponsorAdd(formData).subscribe((result) => {
        this.loading = false;
        this.activeModal.close();
      }, error => {
        this.loading = false;
        this.toastr.error('Si è verificato un errore, riprova!');
      });
    }
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
